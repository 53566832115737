import { RedoOutlined, ReloadOutlined } from "@ant-design/icons";
import { Badge, Button, Flex, Popconfirm, Space, Table, Typography } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ID } from "../../components/id/id";
import Link from "../../components/link/Link";
import PrestationLabel from "../../components/prestations/PrestationLabel";
import TaskStatusLabel from "../../components/tasks/TaskStatusLabel";
import TaskTypeLabel from "../../components/tasks/TaskTypeLabel";
import { useAuth } from "../../hooks/auth";
import { taskTypes, useTasks } from "../../hooks/tasks";
import { formatDate } from "../../lib/format";
import { getTask, removeTask, updateTask } from "../../services/api/tasks";
import { loaderWrap } from "../../services/loader";


export default function OrderTasksView() {
  const { orderId } = useParams();
  const { can } = useAuth();

  const [tasks, refreshTasks] = useTasks({ order_id: orderId, with_deleted: true });
  const [selectedTasks, setSelectedTasks] = useState([]);


  const onDelete = async () => {
    await loaderWrap(Promise.all(selectedTasks.map(id => removeTask(id))))
    setSelectedTasks([])
    refreshTasks()
  }

  const onRestore = async (id) => {
    const t = await getTask(id)
    await updateTask({
      ...t,
      deleted: false,
    })
    refreshTasks()
  }
  return <div>
    <Flex className="box" justify="end" gap="small" >
      {(can('task.delete')) && selectedTasks.length > 0 &&
        <Popconfirm title={`Vous êtes sur le point de supprimer ${selectedTasks.length} tâche(s). Continuer ?`}
          okButtonProps={{ danger: true }}
          okText="Oui, supprimer" onConfirm={() => onDelete()}>
          <Button danger>Supprimer</Button>
        </Popconfirm>
      }
      <Button onClick={() => refreshTasks()} icon={<ReloadOutlined />} />
    </Flex>
    <Table
      dataSource={tasks}
      expandable={{ defaultExpandAllRows: true }}
      rowKey={(record) => record.id}
      pagination={
        {
          position: ['bottomCenter'],
        }
      }
      rowSelection={{
        type: 'checkbox',
        onChange: (selectedRowKeys) => {
          setSelectedTasks(selectedRowKeys)
          // console.log(`selectedRowKeys: ${selectedRowKeys}`);
        }
      }}
      // rowSelection={{
      //   type: 'checkbox',
      //   getCheckboxProps: (record) => ({
      //     disabled: !!record.ref,
      //   }),
      //   onChange: (selectedRowKeys) => {
      //     setSelectedTasks(selectedRowKeys)
      //     // console.log(`selectedRowKeys: ${selectedRowKeys}`);
      //   }
      // }}
      // onRow={(record, rowIndex) => {
      //   return {
      //     onClick: () => open(record.id), // click row
      //   };
      // }}
      columns={[
        {
          title: 'ID',
          dataIndex: 'id',
          width: 100,
          key: 'id',
          render: (text, record) => <ID value={text} />,
        },
        {
          title: 'Nom',
          dataIndex: 'name',
          key: 'name',
          sorter: (a, b) => String(a.name).localeCompare(b.name),
          render: (text, task) => <Space direction="vertical">
            <Link to={`/tasks/${task.id}`}>{text}</Link>
            <Typography.Text type="secondary" >{task.description}</Typography.Text>
          </Space>,
        },
        {
          title: 'Prestation',
          dataIndex: 'prestation_id',
          key: 'prestation_id',
          render: (id, record) => <PrestationLabel id={id} withCode />,
        },
        {
          title: 'Numéro de ligne',
          dataIndex: 'order_item_number',
          key: 'order_item_number',
          sorter: (a, b) => String(a.order_item_number).localeCompare(b.order_item_number),
          defaultSortOrder: 'ascend',
          render: (text, record) => text,
        },
        // {
        //   title: 'ID de ligne',
        //   dataIndex: 'order_item_id',
        //   key: 'order_item_id',
        //   sorter: (a, b) => String(a.order_item_id).localeCompare(b.order_item_id),
        //   defaultSortOrder: 'ascend',
        //   render: (text, record) => text,
        // },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          render: (text, record) => <TaskTypeLabel value={record.type} />,
          filters: taskTypes.map(({ label, value }) => ({ text: label, value })),
          onFilter: (value, record) => record.type === value,
        },
        {
          title: 'Date de début',
          dataIndex: 'min_date',
          key: 'min_date',
          sorter: (a, b) => String(a.min_date).localeCompare(b.min_date),
          render: (text, record) => formatDate(text),
        },
        {
          title: 'Date de fin',
          dataIndex: 'max_date',
          key: 'max_date',
          sorter: (a, b) => String(a.max_date).localeCompare(b.max_date),
          render: (text, record) => formatDate(text),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => {
            let progress = 0;
            if (record.status === 'inplanning') {
              progress = Math.round(record.planned_percent)
            } else if (record.status === 'planned') {
              progress = Math.round(record.complete_percent)
            }
            return <Space.Compact >
              <TaskStatusLabel value={record.status} deleted={record.deleted} progress={progress != null && record.status === 'inplanning'
                ? progress : undefined} />
              {(record.deleted && record.order_item_id && can('task.restore')) &&
                <Popconfirm title={`Vous êtes sur le point de restaurer cette tâche. Continuer ?`}
                  okButtonProps={{ danger: true }}
                  okText="Oui, restaurer" onConfirm={() => onRestore(record.id)}>
                  <Button variant="link" size="small" icon={<RedoOutlined />} title="Restaurer" />
                </Popconfirm>
              }
            </Space.Compact>
          },


        },
        {
          title: 'Crénaux',
          dataIndex: 'metrics',
          key: 'slots',
          render: (m, task) => {
            return m?.slots_count ?
              <Link to={`/tasks/${task.id}/planning`}><Badge count={m?.slots_count} color='blue' /></Link> : null
          },
        },

        // {
        //   title: 'Compétences',
        //   dataIndex: 'expected_level',
        //   key: 'expected_level',
        //   sorter: (a, b) => a.expected_level - b.expected_level,
        //   render: (text, record) => {
        //     return <Rate value={Math.ceil(record.expected_level * 3 / 5)} count={3} disabled />
        //   },
        // }
      ]}
    />
  </div>

}