import { Button, Form, Input, InputNumber, message } from "antd";
import { useEffect } from "react";
import { ControlBar } from "../../components/controlbar/ControlBar";
import { getSetting, setSetting } from "../../services/api/settings";


function Setting({ name, label, help, input }) {
    return (
        <Form.Item key={name} label={label} name={name} help={help}>
            {input || <Input />}
        </Form.Item>
    );
}

const settings = [
    {
        label: "Logistique",
        key: "logistics",
        settings: [
            {
                name: "shipment.verifying.threshold_amount",
                label: "Seuil de vérification",
                help: "Montant à partir duquel une vérification est nécessaire",
                input: <InputNumber suffix="€" style={{ minWidth: "8em" }} />
            }
        ]
    }
];


export function Settings() {

    const [form] = Form.useForm();


    const onFinish = (values) => {
        Object.entries(values).forEach(([key, value]) => {
            setSetting({
                key,
                value,
                global: true
            });
        });
        message.success('Paramètres enregistrés');
    }

    useEffect(() => {
        (async function () {
            const values = {};
            for (const group of settings) {
                for (const setting of group.settings) {
                    const s = await getSetting(setting.name);
                    values[setting.name] = s?.value || undefined;
                }
            }
            form.setFieldsValue(values);
        })()
    }, [form]);


    return (<div>
        <Form form={form} layout="vertical" onFinish={onFinish}>

            {
                settings.map((group, i) => (
                    <div key={group.key || i} className="box">
                        <h2 className="box-title">{group.label}</h2>
                        {
                            group.settings.map(setting => (
                                <Setting
                                    key={setting.name}
                                    name={setting.name}
                                    label={setting.label}
                                    help={setting.help}
                                    input={setting.input} />
                            ))
                        }
                    </div>))
            }

            <ControlBar>
                <div>

                </div>
                <div>
                    <Button type="primary" size='large' htmlType="submit">
                        Enregistrer</Button>
                </div>
            </ControlBar>
        </Form>
    </div>);
}