import { Tag, Typography } from "antd"
import { useMemo } from "react"
import { slotStatuses } from "../../hooks/slots"

import './variables.scss'

export default function SlotStatusLabel({ status, deleted }) {

  const item = useMemo(() => {
    return slotStatuses.find((s) => s.value === status) || { label: 'Inconnu', color: 'grey' }
  }, [status])
  let label = item.label
  if (deleted) {
    label = <Typography.Text delete type="danger">{label}</Typography.Text>
  }

  return <Tag color={item.color}>{label}</Tag>
}