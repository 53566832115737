
import { CheckCircleOutlined, FileDoneOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Flex, message, Result, Skeleton } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useTask } from '../../hooks/tasks';


function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}


export default function TaskItem() {
  const { taskId } = useParams()
  const { can } = useAuth();
  const { complete, task, saveTask } = useTask(taskId);

  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };

  const [validateLoading, setValidateLoading] = React.useState(false);
  const [validateInvoiceLoading, setValidateInvoiceLoading] = React.useState(false);
  const onValidate = () => {
    setValidateLoading(true);
    saveTask({ ...task, is_order_valid: true })
      .then(() => {
        message.success("Tâche validée avec succès");
      })
      .finally(() => {
        setValidateLoading(false);
      });
  }

  const onValidateInvoice = () => {
    setValidateInvoiceLoading(true);
    saveTask({ ...task, is_invoiced: true })
      .then(() => {
        message.success("La commande a bien été marquée comme facturée");
      })
      .finally(() => {
        setValidateInvoiceLoading(false);
      });
  }

  if (!complete) return <Skeleton active style={{ padding: "1rem" }} />
  if (!task) return <Result
    status="404"
    title="Tâche non trouvée"
    subTitle="Cette tâche n'existe pas dans Chronos"
  />


  return (<>
    {taskId && taskId !== '*' ?
      <Flex style={{ marginBottom: 20 }}>
        <ul className="nav nav-pills subnav mb-4" style={{ flex: 1 }}>
          <li className="nav-item">
            <NavLink to="details" className={navLinkActive("nav-link", "active")}>Détail</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="planning" className={navLinkActive("nav-link", "active")}>Planning</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="events" className={navLinkActive("nav-link", "active")}>Historique</NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink to="documents" className={navLinkActive("nav-link", "active")}>Documents</NavLink>
          </li> */}
        </ul>
        <div style={{ flex: 1 }}></div>
        <Flex gap="small" style={{ margin: "0 1rem" }}>
          {can('task.create') && <Dropdown menu={{
            items: [
              {
                key: 'subtask',
                label: <div onClick={() => open("../*/details?parent=" + taskId)}>Sous-Tâche</div>
              },

              {
                key: 'sav',
                label: <div onClick={() => open("../*/details?type=after-sales&parent=" + taskId)}>SAV</div>
              },

            ]
          }}>
            <Button type="primary" icon={<PlusOutlined />}>Créer</Button>
          </Dropdown>}
          {(can('task.order.update') && !task.is_order_valid) && <Button
            variant="solid" color="green" icon={<CheckCircleOutlined />}
            loading={validateLoading}
            onClick={() => onValidate()}
          >Valider</Button>}
          {(can('task.invoice.update') && !task.is_invoiced) && <Button
            variant="solid" color="purple" icon={<FileDoneOutlined />}
            loading={validateInvoiceLoading}
            onClick={() => onValidateInvoice()}
          >Facturé</Button>}

        </Flex>
      </Flex>
      : null}
    <Outlet />
  </>);
}
