import { Table } from "antd";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import DeliveryStatus from "../../components/deliveries/DeliveryStatus";
import { useDeliveries } from "../../hooks/deliveries";
import { usePrestations } from "../../hooks/prestations";
import { useResources } from "../../hooks/resources";
import { formatDateTime } from "../../lib/format";
import { indexBy } from "../../lib/utils";


export default function OrderDeliveriesView() {
  const { orderId } = useParams();

  const [deliveries, reloadDeliveries] = useDeliveries({ orderId: orderId });

  const [resources] = useResources();
  const [prestations] = usePrestations();
  const resourcesIdx = useMemo(() => indexBy(resources, 'id'), [resources]);
  const prestationsIdx = useMemo(() => indexBy(prestations, 'id'), [prestations]);

  return <Table
    // bordered
    dataSource={deliveries}
    rowKey="id"
    size='middle'
    pagination={
      {
        position: ['bottomCenter'],
      }
    }
    // onRow={(record, rowIndex) => {
    //   return {
    //     onClick: () => openSetResource(record.id), // click row
    //   };
    // }}
    columns={[


      {
        title: 'Date de livraison',
        dataIndex: 'delivery_date',
        key: 'delivery_date',
        width: 120,
        render: (date) => formatDateTime(date),
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend',
        sorter: (a, b) => new Date(a.delivery_date) - new Date(b.delivery_date),
      },
      {
        title: 'Expédié le',
        dataIndex: 'shipping_date',
        key: 'shipping_date',
        width: 120,
        render: (date) => formatDateTime(date)
      },
      {
        title: 'Livré par',
        dataIndex: 'resource_id',
        key: 'resource_id',
        width: 120,
        render: (resource_id, rec) => {
          return <>
            <div>{resourcesIdx[resource_id]?.name}</div>
            <div style={{
              fontSize: '0.8em',
              fontStyle: 'italic',
            }}>{prestationsIdx[rec.prestation_id]?.name}</div>
          </>
        }
      },
      {
        title: 'Numéro de suivi',
        dataIndex: 'tracking_number',
        key: 'tracking_number',
      },
      {
        title: 'Livré le',
        dataIndex: 'delivery_date',
        key: 'delivery_date',
        width: 120,
        render: (date) => formatDateTime(date)
      },
      // {
      //   title: 'Début de préparation',
      //   dataIndex: 'started_at',
      //   key: 'started_at',
      //   render: (date) => formatDateTime(date)
      // },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => <DeliveryStatus value={status} />
      },

    ]}
  />

}