import { Popover, Progress, Table, Tag } from "antd";
import { useParams } from "react-router-dom";
import PickingPriority from "../../components/picking/PickingPriority";
import PickingStatus from "../../components/picking/PickingStatus";
import ShipmentMissingProducts from "../../components/shipments/ShipmentMissingProducts";
import { usePickingOrders } from "../../hooks/pickingorders";
import { formatDateTime } from "../../lib/format";


export default function OrderPickingsView() {
  const { orderId } = useParams();

  const [pickings, refreshPickings] = usePickingOrders({ orderId });

  return <Table
    key="table-pickings"
    // bordered
    dataSource={pickings}
    rowKey="id"
    size='middle'
    pagination={
      {
        position: ['bottomCenter'],
      }
    }
    // onRow={(record, rowIndex) => {
    //   return {
    //     onClick: () => openSetResource(record.id), // click row
    //   };
    // }}
    columns={[
      {
        title: 'Priorité',
        dataIndex: 'priority',
        key: 'priority',
        width: 80,
        render: (priority) => <PickingPriority value={priority} />,
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.priority - b.priority,
        defaultSortOrder: 'descend',
      },
      {
        title: 'Début de préparation',
        dataIndex: 'started_at',
        key: 'started_at',
        render: (date) => formatDateTime(date)
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => <PickingStatus value={status} />
      },
      {
        title: 'État',
        dataIndex: 'completion_rate',
        width: 100,
        key: 'completion_rate',
        render: (completion_rate, item) => {
          if (completion_rate === 1) {
            return <Tag color="green">Complète</Tag>
          }
          // return <Progress
          //   percent={Math.floor(100 * completion_rate)}
          //   strokeColor={completion_rate < 1 ? 'orange' : null}
          //   status="normal" />
          return <Popover content={<ShipmentMissingProducts items={item.items} order={item.order} />}>
            <Progress
              percent={Math.floor(100 * completion_rate)}
              strokeColor={completion_rate < 1 ? 'orange' : null}
              status="normal" />
          </Popover>
        }
      },
      // {
      //   title: 'Statut',
      //   dataIndex: 'status',
      //   key: 'status',
      //   render: (status) => <Tag color={status === 'draft' ? 'orange' : 'green'}>{status}</Tag>
      // },
      {
        title: 'Nb réfs',
        key: 'items_count',
        dataIndex: 'remaining_items_count',
        // render: (record) => <a href={`/orders/${record.id}`}>Voir</a>
      },
    ]}
  />

}