import Icon from "@mdi/react";
import Styles from './Search.module.scss';
import { asIconAndColor } from "./util";

export function SearchOptionRenderer({ data }) {
    const { params, label } = data;
    const { icon, optionColor } = asIconAndColor(params);
    return <span style={{ color: optionColor }}>
        {icon && <Icon path={icon} size={0.6} color={optionColor} style={{ marginRight: 3, marginTop: -2 }} />}
        {params?.title && <b className={Styles.optionTitle}>{params.title}</b>}
        {label}
    </span>
}