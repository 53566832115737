import { useCallback, useEffect, useState } from "react";


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useEventListener(event, listener, useCapture) {
  useEffect(() => {
    if (listener) {
      listener()
      window.addEventListener(event, listener, useCapture)

      return () => window.removeEventListener(event, listener, useCapture)
    }

    return () => { }
  }, [event, listener, useCapture])
}


export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const { height, width } = windowDimensions
  return { height, width }
}


export function useElementDimensions(ref) {
  const [dimensions, setDimensions] = useState(null)

  const currentRef = ref.current
  const refresh = useCallback(() => {
    if (!currentRef) {
      return
    }
    const domRect = currentRef?.getBoundingClientRect()

    if (domRect) {
      setDimensions(domRect)
    }
  }, [currentRef])

  useEventListener('resize', refresh);
  useEventListener('scroll', refresh, true);

  return { dimensions, refresh }
}
