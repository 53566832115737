import { mdiMagnifyPlusOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { Button } from "antd";


import Styles from "./SearchButton.module.scss";

export default function SearchButton({ type, value, handler, children }) {
  if (children) {
    return <div className={Styles.container}>
      {children}
      <Button className={Styles.button} size="small"
        shape="circle" type="dashed"
        icon={<Icon path={mdiMagnifyPlusOutline} size={0.6} />}
        onClick={() => handler(type, value)} ></Button>
    </div>
  }
}
