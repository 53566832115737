import { useParams } from "react-router-dom";
import ChangeLog from "../../components/changelog/ChangeLog";
import { useOrder } from "../../hooks/orders";


export default function OrderEvents() {
    const { orderId } = useParams();
    const { changes } = useOrder(orderId, { withLogs: true });
    return <ChangeLog changes={changes} />
}
