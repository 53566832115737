import { CheckCircleTwoTone } from "@ant-design/icons"
import { Badge, Descriptions, Flex, Rate, Space } from "antd"
import { useEffect, useState } from "react"
import { usePrestation } from "../../hooks/prestations"
import { useProject } from "../../hooks/projects"
import { useResource } from "../../hooks/resources"
import { useTask } from "../../hooks/tasks"
import { formatDateTime, formatDuration } from "../../lib/format"
import { getSlotRaw } from "../../services/api/slots"
import Document from "../documents/Document"
import ProjectCode from "../projects/ProjectCode"
import SlotStatusLabel from "./SlotStatusLabel"
import './variables.scss'

export default function SlotView({ slot }) {
  slot = { ...slot }

  const timeDiff = slot.real_duration - slot.duration
  const timeDiffLabel = (timeDiff > 0 ? '+' : '-') + formatDuration(timeDiff * 60)

  const [{ documents, signature }, setState] = useState({})

  const { task } = useTask(slot.task_id)
  const { project } = useProject(task?.project_id)
  const { resource } = useResource(slot.resource_id)
  const { prestation } = usePrestation(task?.prestation_id)

  useEffect(() => {
    getSlotRaw(slot.id).then(({ documents, signature }) => {
      // Fix SVG signature
      signature = (signature || '').replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg" version="1.1"')
      setState({ documents, signature })
    })
  }, [slot.id])

  if (!slot) return null

  return (
    <div>
      <Flex justify="space-between" align="center">
        <SlotStatusLabel status={slot?.status} />
      </Flex>
      {task?.description && <div className="form-text" style={{ maxHeight: 200, marginBottom: 10, overflow: 'auto' }}>
        {task.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
      </div>}
      <Descriptions column={1} size={"small"} bordered>
        {project?.id && <Descriptions.Item label="Affaire">{project?.name}</Descriptions.Item>}
        {project?.code && <Descriptions.Item label="Code affaire"><ProjectCode value={project?.code} /></Descriptions.Item>}
        {task?.id && <Descriptions.Item label="Tâche">{task?.name}</Descriptions.Item>}
        {prestation?.id && <Descriptions.Item label="Prestation"><Space>
          <span>{prestation?.name}</span>
          <Rate disabled value={Math.ceil(task?.expected_level * 3 / 5)} style={{ fontSize: '0.8em' }} count={3} /></Space></Descriptions.Item>}
        <Descriptions.Item label="Ressource">{resource?.name}</Descriptions.Item>
        <Descriptions.Item label="Date">{formatDateTime(slot.start)} - {formatDateTime(slot.end)}</Descriptions.Item>
        <Descriptions.Item label="Durée prévu">{formatDuration(slot.duration * 60)}</Descriptions.Item>
        {slot.status === 'done' ? <Descriptions.Item label="Durée réelle">
          <Flex align="center" gap="small">
            <span>{formatDuration(slot.real_duration * 60)}</span>
            {Math.abs(timeDiff) > 0
              ? <Badge count={timeDiffLabel} style={{ backgroundColor: timeDiff > 0 ? '#f56c6c' : '#87d068', marginLeft: 10 }} />
              : <CheckCircleTwoTone twoToneColor="#52c41a" />
            }
          </Flex>
        </Descriptions.Item> : null}

        {slot.close_details ? <>
          <Descriptions.Item label="Note">
            {slot.close_details?.note}
          </Descriptions.Item>
          <Descriptions.Item label="Documents">
            <Flex gap="small">
              {documents?.map((a, i) => <div key={i}>
                <Document doc={a} preview />
              </div>)}
            </Flex>
          </Descriptions.Item>
          <Descriptions.Item label="Signature">
            <img src={"data:image/svg+xml;utf8," + (signature)} alt="signature" style={{
              width: '100%',
              maxWidth: 200,
              height: 'auto',
              padding: 10,
              backgroundColor: 'rgba(0,0,0,0.05)',
              borderRadius: 5,
            }} />
          </Descriptions.Item>
        </> : null}
      </Descriptions>
    </div>
  )

}