
import { Button, Flex, Form, Input, Space, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AgencyInput } from '../../components/agencies/AgencyInput';
import ChangeLogDrawer from '../../components/changelog/ChangeLogDrawer';
import { ControlBar } from '../../components/controlbar/ControlBar';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import ProjectMessagesView from '../../components/projects/ProjectMessagesView';
import ProjectStatusLabel from '../../components/projects/ProjectStatusLabel';
import { useAuth } from '../../hooks/auth';
import { useProject } from '../../hooks/projects';

// import { SubjectTimeline } from '../../components/dataviz/SubjectTimeline';
// import { ControlBar } from '../../components/controlbar/ControlBar';

export default function ProjectForm() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { can } = useAuth();

  const {
    project, saveProject, deleteProject, changes, isNewProject
  } = useProject(projectId || '', { withLogs: true });


  useEffect(() => {
    if (project) {
      form.setFieldsValue({
        ...project,
        expected_start_date: project.expected_start_date ? new dayjs(project.expected_start_date) : undefined,
        expected_end_date: project.expected_end_date ? new dayjs(project.expected_end_date) : undefined,
      });
    }
  }, [form, project]);


  if (!project) {
    return <div>Not found</div>;
  }



  const onFinish = (values) => {
    (async () => {
      try {
        const item = await saveProject({
          ...project,
          ...values,
          expected_start_date: values.expected_start_date ? values.expected_start_date.toISOString() : undefined,
          expected_end_date: values.expected_end_date ? values.expected_end_date.toISOString() : undefined,
        });
        message.success('Enregistré');
        if (item) {
          navigate('/projects');
        }
      } catch (err) {
        message.error(`Erreur: ${err}`);
      }
    })();
  };

  const onDelete = () => {
    if (projectId) {
      (async () => {
        await deleteProject();
        message.success('Supprimé');
        navigate('/projects');
      })();
    }
  };


  return (<>
    <Flex gap="large">
      <Form form={form}
        style={{ flex: 1 }}
        labelCol={{ span: 6 }}
        wrapperCol={{ flex: 1 }}
        labelWrap
        onFinish={onFinish}
        disabled={!can('project.update')}
      >

        <fieldset className="box">
          <legend>Informations</legend>
          <Form.Item name="name" label="Nom" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {project.status ?
            <Form.Item name="status" label="Statut">
              <ProjectStatusLabel status={project.status} />
            </Form.Item>
            : null}
          <Form.Item name="code" label="Code" rules={[{ required: true }]} >
            <Input disabled={!isNewProject} />
          </Form.Item>
          <Form.Item name="agency_id" label="Agence principale" rules={[{ required: true }]}>
            <AgencyInput />
          </Form.Item>
          <Form.Item name="customer_code" label="Code client">
            <Input />
          </Form.Item>
          {/* <Form.Item name="commercial_code" label="Code commercial">
            <Input />
          </Form.Item> */}
          <Form.Item name="phone" label="Téléphone">
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Adresse">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Notes">
            <TextArea />
          </Form.Item>

        </fieldset>

        {/* <fieldset className="box">
          <legend>Damien Zone</legend>
          <Form.Item name="margin_rate" label="Marge">
            <InputNumber suffix="%" min={0} />
          </Form.Item>
        </fieldset>
        <fieldset className="box">
          <legend>Dates</legend>
          <Form.Item name="expected_start_date" label="Date de démarrage souhaité">
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
          <Form.Item name="expected_end_date" label="Date de fin souhaité">
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
        </fieldset> */}

        {/* 
        <ControlBar>
          <DeleteButton onDelete={onDelete} />
          <Space>
            <ChangeLogDrawer changes={changes} />
            <button type="submit" className="btn btn-primary" >Enregistrer</button>
          </Space>
        </ControlBar> */}

      </Form>
      {project.id ?
        <fieldset className='' style={{ maxWidth: 300 }}>
          <ProjectMessagesView projectId={project.id} />
        </fieldset> : null}
    </Flex>

    <ControlBar>
      {can('project.delete') && <DeleteButton onDelete={onDelete} />}
      <div></div>
      <Space>
        <ChangeLogDrawer changes={changes} />
        {can('project.update') && <Button type="primary" size='large'
          onClick={() => form.submit()}>Enregistrer</Button>}
      </Space>
    </ControlBar>
  </>);
}
