import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/shipments');
const { invalidateCache, withCache } = cache();

function mapFromShipmentModel(input) {
  return { ...input };;
}

function mapToShipmentModel(input) {
  return { ...input };;
}


export async function listShipments({ warehouseId, orderId, onlyActive } = {}) {
  const params = {
    warehouse_id: warehouseId || '',
    active_only: onlyActive || '',
    order_id: orderId || '',
  };
  const items = await withCache(async () => {
    const { items } = await api.get('', {}, params);
    return items;
  }, params)
  return items ? items.map(mapToShipmentModel) : [];
}

export async function getShipment(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Shipment not found');
    }
    return item;
  }, id)

  return mapToShipmentModel(item);;
}

export async function createShipment(shipment) {
  const { item } = await api.post('', { item: mapFromShipmentModel(shipment) });
  if (item) {
    invalidateCache()
    return mapToShipmentModel(item)
  }
  return undefined;
}

export async function updateShipment(shipment) {
  const res = await api.put('', { item: mapFromShipmentModel(shipment) });
  invalidateCache()
  return res ? shipment : undefined;
}

// export async function updateShipmentOrders(id, orders) {
//   const res = await api.put(id + "/orders", { orders });
//   invalidateCache(id)
//   return
// }



export async function removeShipment(id) {
  const res = await api.delete(id);
  invalidateCache()
  return !!res;
}



export function invalidateShipment(id) {
  invalidateCache(id);
}