
import { CameraTwoTone, ClearOutlined, CloseOutlined } from "@ant-design/icons";
import Signature from '@uiw/react-signature';
import { Button, Flex, Form, Input, InputNumber, message, Result, Steps, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { mdiChevronLeft, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { useParams } from "react-router-dom";
import { createUpload, setCalendarData } from "../../services/api/calendar";
import { loaderWrap } from "../../services/loader";
import Styles from './Event.module.scss';


async function uploadFile(
  file,
  presignedUploadUrl,
  onProgress,
) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        const pct = e.loaded / e.total;
        onProgress(pct * 100);
      }
    });
    xhr.upload.addEventListener("error", (e) => {
      reject(new Error("Upload failed: " + e.toString()));
    });
    xhr.upload.addEventListener("abort", (e) => {
      reject(new Error("Upload aborted: " + e.toString()));
    });
    xhr.addEventListener("load", (e) => {
      if (xhr.status === 200) {
        resolve();
      } else {
        reject(new Error("Upload failed " + xhr.status));
      }
    });
    xhr.open("PUT", presignedUploadUrl, true);
    // console.log(file)
    try {
      xhr.send(file);
    } catch (e) {
      reject(new Error("Upload failed: " + e.toString()));
    }
  });
}

function EventConfirmAddPhotos({ photos, onChange }) {
  const { accessKey, slotId, token } = useParams();

  const [uploadIDs, setUploadIDs] = useState({});

  const onUploadChange = ({ fileList }) => {
    const photos = fileList.map(f => {
      return {
        ...f,
        id: uploadIDs[f.uid],
      }
    })
    onChange(photos)
  }




  const customUploadRequest = async ({ file, onProgress, onSuccess, onError }) => {

    const { id, url } = await createUpload(accessKey, slotId, token, file.name, file.type)
    setUploadIDs({ ...uploadIDs, [file.uid]: id })
    try {
      await uploadFile(file, url, (pct) => {
        onProgress({ percent: pct }, file)
      })
      // message.info({
      //   key: 'upload_progress',
      //   content: `Importation en cours`,
      // })
      // setPhotos([...photos, { url: await getFileUrl(id) }])
      onSuccess()
    } catch (e) {
      message.error(`Erreur: ${e}`)
      onError(e)
    }
    return url;
  }
  return <div>
    <Upload
      multiple
      customRequest={customUploadRequest}
      listType="picture-card"
      fileList={photos}
      onChange={onUploadChange}
      accept="image/png,image/x-png,image/jpeg"
      capture="environment"
      itemRender={(originNode, file, currFileList) => {

        if (file.status !== 'done') {
          return originNode;
        }
        // return originNode;
        return <div className="ant-upload-list-item ant-upload-list-item-done">
          <a className="ant-upload-list-item-thumbnail">
            <img src={file.thumbUrl} alt={file.name} className="ant-upload-list-item-image" />
          </a>
          <Button type="primary" shape="circle" danger size="small"
            style={{
              position: 'absolute',
              top: -10,
              right: -10,
            }}
            icon={<CloseOutlined />}
            onClick={() => {
              const files = currFileList.filter(f => f.uid !== file.uid)
              onChange(files)
            }}
          />
        </div>
        // return <div>

        //   {originNode}
        //   {file.status === 'done' && <Button type="link" size="small" onClick={() => {
        //     getFileUrl(file.id).then(url => {
        //       window.open(url, '_blank');
        //     })
        //   }}>Ouvrir</Button>}
        // </div>
      }}
    >
      <CameraTwoTone size={64} />
    </Upload>
    {/* <input type="file" accept="image/*, video/*" capture></input> */}
    {/* <Button size="large" shape="round" type="primary" onClick={() => setImage(camera.current.takePhoto())} icon={<CameraTwoTone />}></Button>
    <Button shape="round" onClick={() => camera.current.switchCamera()} icon={<SyncOutlined />}></Button> */}
    {/* {image && <img src={image} alt='Taken photo' />} */}
  </div>
}

function EventConfirmAddDetails({ duration, note, onChange }) {
  const timeRef = useRef(null);


  useEffect(() => {
    const el = timeRef.current.nativeElement.querySelector('input');
    el.setAttribute('type', 'number');
    el.setAttribute('pattern', '[0-9]*');
    // timeRef.current.focus();
  }, [])

  const handleChange = (field, value) => {
    onChange({ note, duration, [field]: value });
  }


  return <Form>
    <Form.Item label="Temps passé en heures">
      <InputNumber ref={timeRef} min={0} defaultValue={duration}
        onChange={(value) => handleChange('duration', value)}
        size="large" />
    </Form.Item>
    <Form.Item label="Notes">
      <Input.TextArea placeholder="Notes" size="large" autoSize
        style={{ minHeight: '10rem' }}
        defaultValue={note}
        onChange={(e) => handleChange('note', e.target.value)} />
    </Form.Item>
  </Form>
}

function EventConfirmAddSignature({ signer, signature, onChange }) {
  const $svg = useRef(null);

  const [points, setPoints] = useState([])
  const handleClear = () => {
    $svg.current?.clear();
    setPoints([])
  }
  const handlePoints = (data) => {
    if (data.length > 0) {
      const pts = [...points, [...data]];
      setPoints(pts);
      onChange && onChange(pts, asSVG());
    }
  }



  const asSVG = () => {
    const svgelm = $svg.current?.svg?.cloneNode(true);
    const clientWidth = $svg.current?.svg?.clientWidth;
    const clientHeight = $svg.current?.svg?.clientHeight;
    svgelm.removeAttribute('style');
    svgelm.setAttribute('width', `${clientWidth}px`);
    svgelm.setAttribute('height', `${clientHeight}px`);
    svgelm.setAttribute('viewbox', `${clientWidth} ${clientHeight}`);
    return svgelm.outerHTML
  };

  return <div>
    {/* <Input placeholder="Nom" size="large" value={signer} onChange={(v) => onChange} /> */}
    <div className={Styles['confirm-signature-block']}>
      <Signature defaultPoints={signature} onPointer={handlePoints} ref={$svg} />
    </div>
    <Flex justify="end">
      <Button onClick={handleClear} icon={<ClearOutlined />} shape="round">Effacer</Button>
    </Flex>
  </div>
}

function EventConfirmComplete({
  status,
  onClose }) {

  const { accessKey, slotId, token } = useParams();



  switch (status) {
    case 'loading':
      return <Result
        status="info"
        title="Enregistrement en cours"
        subTitle="Veuillez patienter quelques instants..." />
    case 'success':
      return <Result
        status="success"
        title="La tache est terminée"
        extra={[
          <Button type="primary" size="large" key="console" onClick={onClose}>
            Fermer
          </Button>,
        ]}
      />
    case 'error':
      return <Result
        status="error"
        title="Erreur lors de l'enregistrement"
        subTitle="Veuillez réessayer plus tard"
        extra={[
          <Button type="primary" size="large" key="console" onClick={onClose}>
            Fermer
          </Button>,
        ]}
      />
    default:
      return null;
  }
}

export default function EventConfirm({ slot, onComplete, onCancel }) {
  const { accessKey, slotId, token } = useParams();

  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const [photos, setPhotos] = useState([]);
  const [details, setDetails] = useState({ duration: slot.duration, note: '' });
  const [signature, setSignature] = useState([]);
  const [signatureSVG, setSignatureSVG] = useState('');
  const [signer, setSigner] = useState('');
  const [status, setStatus] = useState();

  const handleComplete = () => {
    onComplete({
      photos,
      details,
      signature: signatureSVG,
    })
  }


  const handleSave = () => {
    // if (status) return;
    setStatus('loading');
    console.log('confirm', {
      note: details.note,
      duration: details.duration,
      photos: photos.map(p => p.id),
      signature: signatureSVG,
    }
    )

    loaderWrap(setCalendarData(accessKey, slotId, token, {
      confirm: {
        note: details.note,
        duration: details.duration,
        photos: photos.map(p => p.id),
        signature: signatureSVG,
      }
    })).then(() => {
      setStatus('success');
    }).catch(() => {
      setStatus('error');
    })

  }

  useEffect(() => {
    if (current === 3) {
      handleSave();
    }
  }, [current])

  const steps = [
    {
      title: 'Notes',
      content: <EventConfirmAddDetails note={details.note} duration={details.duration} onChange={setDetails} />,
      // icon: <AlignLeftOutlined />
    },
    {
      title: 'Photos',
      content: <EventConfirmAddPhotos photos={photos} onChange={setPhotos} />,
      // icon: <CameraOutlined />
    },
    {
      title: 'Signature',
      content: <EventConfirmAddSignature signer={signer} signature={signature} onChange={(data, svg, signer) => {
        setSignature(data);
        setSignatureSVG(svg);
        setSigner(signer);
      }} />,
      // icon: <SignatureOutlined />
    },
    {
      title: 'Terminé',
      content: <EventConfirmComplete
        status={status}
        onClose={handleComplete} />,
      // icon: <CheckOutlined />
    },
  ]



  return <div>
    <Steps current={current}
      direction="horizontal"
      // type="inline"
      size="small"
      responsive={false}
      labelPlacement="vertical"
      items={steps}
    />
    <div style={{
      marginTop: 20,
    }}>{steps[current].content}</div>

    {current < steps.length - 1 ?
      <Flex justify="space-between" className={Styles['drawer-control-bar']}>
        {current > 0
          ? <Button type="text" size="large"
            onClick={prev} icon={<Icon path={mdiChevronLeft} color="#fff" size={1.3} />} />
          : <Button type="text" size="large"
            onClick={onCancel} icon={<Icon path={mdiClose} color="#fff" size={1.3} />} />}
        <Button type="primary" size="large"
          onClick={next} disabled={current === steps.length - 1}>Suivant</Button>
      </Flex>
      : null}

  </div>

}

