import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/tasks');

const { invalidateCache, withCache } = cache();

function mapFromTaskModel(input) {
  return { ...input };;
}

function mapToTaskModel(input) {
  return { ...input };;
}

export async function listTasks({ agency_id, project_id, order_id } = {}) {
  const items = await withCache(async () => {
    const { items } = await api.get(null, {}, {
      agency_id: agency_id || '',
      project_id: project_id || '',
      order_id: order_id || '',
    });
    return items;
  }, { list: true, agency_id, order_id, project_id });
  return items ? items.map(mapToTaskModel) : [];
}

export async function getTask(id, { withLogs = false } = {}) {
  const item = await withCache(async () => {
    if (id === 'unavailable') {
      return { item: { id: 'unavailable', name: 'Indisponible' } };
    }
    const res = await api.get(id, {}, { withLogs });
    if (!res.item) {
      throw new Error('Task not found');
    }
    return res;
  }, id + (withLogs ? '+logs' : ''))
  const task = mapToTaskModel(item.item);
  return { item: task, changes: item.changes };
}

export async function createTask(task) {
  const { item } = await api.post('', { item: mapFromTaskModel(task) });
  if (item) {
    invalidateCache(item.id)
    return mapToTaskModel(item)
  }
  return undefined;
}

export async function updateTask(task) {
  const res = await api.put('', { item: mapFromTaskModel(task) });
  invalidateCache(task.id)
  return res ? task : undefined;
}

export async function updateTaskStatus(taskId, status) {
  const res = await api.put(`${taskId}/status`, { status });
  invalidateCache(taskId)
  return res ? true : undefined;
}

export async function removeTask(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}


// export async function createImportTask() {
//   const { id, url } = await api.post('/import');
//   return { id, url }
// }



// export async function startImportTask(importId) {
//   const { imported } = await api.post('/import/' + importId);
//   invalidateCache()
//   return imported
// }

export function invalidateTaskCache(taskId) {
  invalidateCache(taskId)
}


export async function listTaskComments(taskId) {
  const { items } = await api.get(`${taskId}/comments`);
  return items;
}

export async function addTaskComment(taskId, comment) {
  const { success } = await api.post(`${taskId}/comments`, { content: comment });
  return success;
}