import clsx from "clsx";
import { Outlet } from "react-router-dom";

function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}

export default function Orders() {

  // const { pickingPool } = usePickingPool(pickingPoolId || '');

  return (
    <>
      <Outlet />
    </>
  )
}