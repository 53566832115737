import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/projects');
const { invalidateCache, withCache } = cache();

function mapFromProjectModel(input) {
  return { ...input };;
}

function mapToProjectModel(input) {
  return { ...input };
}

export async function listProjects({ all = false } = {}) {
  const items = await withCache(async () => {
    const { items } = await api.get("", {}, {
      all: all ? "true" : "false"
    });
    return items;
  }, { all });
  return items ? items.map(mapToProjectModel) : [];
}

export async function getProject(id, { withLogs = false } = {}) {
  const { item, changes } = await withCache(async () => {
    const res = await api.get(id, {}, { withLogs });
    if (!res.item) {
      throw new Error('Project not found');
    }
    return res;
  }, id + (withLogs ? '+logs' : ''))

  const project = mapToProjectModel(item);
  return { item: project, changes };
}

export async function getProjectDocuments(id) {
  const { items } = await withCache(async () => {
    const res = await api.get(id + '/documents');
    return res;
  }, id)

  return items
}

export async function createProject(project) {
  const { item } = await api.post('', { item: mapFromProjectModel(project) });
  if (item) {
    invalidateCache(item.id)
    return mapToProjectModel(item)
  }
  return undefined;
}

export async function updateProject(project) {
  const res = await api.put('', { item: mapFromProjectModel(project) });
  invalidateCache(project.id)
  return res ? project : undefined;
}

export async function removeProject(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}

export function invalidateProjectCache(projectId) {
  invalidateCache(projectId)
}

export async function listProjectComments(projectId) {
  const { items } = await api.get(`${projectId}/comments`);
  return items;
}

export async function addProjectComment(projectId, comment) {
  const { success } = await api.post(`${projectId}/comments`, { content: comment });
  return success;
}