import { Select, Space } from "antd"
import { useMemo } from "react"
import { taskTypes } from "../../hooks/tasks"


export default function TaskTypeInput({ value, placeholder, onChange }) {

  const options = useMemo(() => {
    return taskTypes.filter((s) => !s.hidden)
  }, [])

  return <Select

    placeholder={placeholder || 'Type de tâche'}

    fieldNames={{
      label: "label",
      value: "value",
    }}
    optionRender={(option) => (
      <Space>
        {/* <span role="img" aria-label={option.data.label}>
          {option.data.emoji}
        </span> */}
        <span style={{ color: option.data.color }}>
          {option.data.label}
        </span>
      </Space>
    )}
    // tagRender={(props) => {
    //   const { label, closable, onClose } = props;
    //   return (
    //     <Space>
    //       <span>{label}</span>
    //       {closable && <span onClick={onClose}>X</span>}
    //     </Space>
    //   );
    // }}
    value={value}
    onChange={onChange}
    options={options}
  />
}