import {
  mdiAnimationOutline,
  mdiDrawing,
  mdiPackageVariantClosed, mdiTruckDeliveryOutline
} from '@mdi/js';
import Icon from '@mdi/react';
import { useMemo } from 'react';

import Styles from './id.module.scss';


function icon(value) {
  switch (value) {
    case 'task':
      return mdiDrawing;
    case 'delivery':
      return mdiTruckDeliveryOutline;
    case 'shipment':
      return mdiPackageVariantClosed;
    case 'order':
      return mdiAnimationOutline;
    default:
      return null;
  }
}

export function ID({ value, variant, onClick }) {
  const [type, id] = (value || '').split('-');


  const { label, labelShort } = useMemo(() => {
    return {
      label: id,
      labelShort: id?.slice(0, 5),
    };
  }, [id]);

  if (!value) {
    return '∅'
  }

  const i = icon(type)

  switch (variant) {
    case 'text':
      return <span onClick={onClick} title={label}
        className={onClick ? Styles.clickable : null}>{labelShort}</span>;
    case 'tag':
    default:
      return (
        <span title={label} onClick={onClick}
          className={Styles.id + (onClick ? ' ' + Styles.clickable : '')}>
          {i && <Icon path={i} size={0.5} style={{ marginRight: '0.2em', marginTop: '-0.2em' }} />}
          <span>{labelShort}</span>
        </span>
      );
  }
}
