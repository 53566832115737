import { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { indexBy } from '../lib/utils';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import {
  createSlot, getNonWorkingDays, getSlot, invalidateSlotCache, listSlots, removeSlot, updateSlot,
} from '../services/api/slots';
import { cache } from '../services/cache';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useSelectedAgencyID } from './agencies';
import { useForm } from './form';
import { useInvalidationService } from './invalidation';
import { useResourcePrestations } from './resource-prestations';

const INVALIDATION_KEY = Symbol('slots');

const { invalidateCache, withCache } = cache();




export const slotStatuses = [
  {
    label: "Planifié",
    value: 'planned',
    color: 'var(--slot-status-planned-color)',
    movable: true,
  },
  {
    label: "Confirmé",
    value: 'confirmed',
    color: 'var(--slot-status-confirmed-color)',
    movable: false,
  },
  {
    label: "Annulé",
    value: 'canceled',
    color: 'var(--slot-status-canceled-color)',
    movable: false,
  },
  {
    label: "Indisponible",
    value: 'unavailable',
    color: 'var(--slot-status-unavailable-color)',
    movable: false,
    hidden: true,
  },
  {
    label: "Terminé",
    value: 'done',
    color: 'var(--slot-status-done-color)',
    movable: true,
  }
];

export const slotStatusesIndex = indexBy(slotStatuses, "value");


export const slotCancelReasons = [
  {
    label: "Météo",
    value: 'weather',
  },
  {
    label: "Client",
    value: 'client',
  },
  {
    label: "Raison administrative",
    value: 'administrative',
  },
  {
    label: "Pas de personnel",
    value: 'staff',
  },
  {
    label: "Pas de matériel (commande non reçue, etc.)",
    value: 'material',
  },
  {
    label: "Problème d'équipement (voiture, outils, etc.)",
    value: 'equipment',
  },
  {
    label: "Problème d'accès (chantier, etc.)",
    value: 'access',
  },
  {
    label: "Autre",
    value: 'other',
  }
];

const HOUR_IN_DAY = 7;

export function roundDays(durationInDays, step = 0.5) {
  const inv = 1 / step
  return Math.round(durationInDays * inv) / inv
}

export function durationInDaysFromHours(hours, round = 0.5) {
  const d = Math.ceil(10 * hours / HOUR_IN_DAY) / 10
  if (round) {
    return roundDays(d, round)
  }
}





function getDefaults() {
  return {
    task_id: '',
    resource_id: '',
    start: '',
    end: '',
    status: 'planned',
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getSlot(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useSlots({ task_id, resource_id, project_id, order_id, dates } = {}) {
  const [slots, setSlots] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [agencyId] = useSelectedAgencyID();
  const [lastParams, setLastParams] = useState(null);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       // const terms = await withCache(async () => loaderWrap(listSlots()));
  //       const items = await loaderWrap(listSlots({
  //         task_id, resource_id, project_id, agency_id: agencyId, dates,
  //       }));
  //       setSlots(items);
  //     } catch (e) {
  //       notifyError(e);
  //     }
  //   })();
  // }, [counter, task_id, resource_id, project_id, agencyId]);

  const refresh = useCallback(() => {
    invalidateSlotCache();
    loadSlots(lastParams);
  }, [invalidate]);

  const setUserSlot = useCallback((items) => {
    setSlots(items);
  }, [setSlots]);

  const loadSlots = useCallback(async (params) => {
    try {
      setLastParams(params);
      if (!params) {
        throw new Error('No params');
      }
      const { task_id, resource_id, project_id, order_id, dates } = params;

      // const terms = await withCache(async () => loaderWrap(listSlots()));
      const items = await loaderWrap(listSlots({
        task_id, resource_id, project_id, agency_id: agencyId, order_id, dates,
      }));

      items.sort((a, b) => { // sort by start date
        return a.start - b.start;
      });

      setSlots(items);
    } catch (e) {
      notifyError(e);
    }
  }, [setSlots, agencyId]);


  // useEffect(() => {
  //   if (dates && dates instanceof Array && dates.length > 0) {
  //     loadSlots({ task_id, resource_id, project_id, dates });
  //   }
  // }, [dates, task_id, resource_id, project_id, agencyId]);

  return [slots, loadSlots, setUserSlot, refresh];
}

export function useSlot(idOrSlot) {
  const [slot, setSlot] = useState({
    ...getDefaults(),
    ...(typeof idOrSlot === 'object' ? idOrSlot : {})
  });

  const id = typeof idOrSlot === 'string' ? idOrSlot : idOrSlot?.id;

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      if (idOrSlot && typeof idOrSlot === 'object') {
        // setSlot({
        //   ...getDefaults(),
        //   ...idOrSlot,
        // });
        return;
      }
      setSlot(await getItem(id));
    })();
  }, [idOrSlot, counter]);

  const saveSlot = useCallback(async (item) => {
    if (!item) {
      throw new Error('No slot');
    }

    // item = {
    //   ...item,
    //   start: new Date(item.start).toISOString(),
    //   end: new Date(item.end).toISOString(),
    // }

    let out;
    if (item.id) {
      out = await loaderWrap(updateSlot(item));
    } else {
      out = await loaderWrap(createSlot(item));
    }
    setSlot(out);
    invalidate(out.id);
    return out;
  }, [id, invalidate]);

  const deleteSlot = useCallback(async () => {
    await loaderWrap(removeSlot(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    slot,
    reloadSlot: () => invalidate(id),
    isNewSlot: !slot?.id,
    setSlot,
    saveSlot,
    deleteSlot,
  };
}

export function useSlotsCollection() {

  const [slots, loadSlots, setSlots, refresh] = useSlots();
  const [lastSlotChange, setLastSlotChange] = useState(null);

  let tempSlots = slots;
  const setTempSlots = (items) => {
    tempSlots = items;
    setSlots(items);
  }


  return {
    slots,
    loadSlots,
    refresh,

    addSlot: async (item) => {
      const slot = await loaderWrap(createSlot(item));
      setTempSlots([...tempSlots, slot]);
      setLastSlotChange({ action: 'add', slot });
    },
    updateSlot: async (item) => {
      const slot = await loaderWrap(updateSlot(item));
      setTempSlots(tempSlots.map((s) => (s.id === slot.id ? slot : s)));
      setLastSlotChange({ action: 'update', slot });
    },
    deleteSlot: async (id) => {
      const slot = slots.find((s) => s.id === id);
      await loaderWrap(removeSlot(id));
      setTempSlots(tempSlots.filter((s) => s.id !== id));
      setLastSlotChange({ action: 'delete', slot });
    },

    lastSlotChange,
  };

}

export function slotValidator(values, name = undefined) {
  const rules = {
    start: [isRequired],
    end: [isRequired],
    task_id: [isRequired],
    resource_id: [isRequired],
    status: [isRequired],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

export function useSlotForm(idOrSlot, onChange = undefined) {
  const { slot, saveSlot, deleteSlot } = useSlot(idOrSlot);

  const {
    register,
    handleSubmit,
    errors, isValid,
    setValues,
    values,
  } = useForm({
    values: {
      ...getDefaults(),
    },
    validator: slotValidator,
    onChange,
    // reValidateMode: "onChange"
  });

  useEffect(() => {
    if (slot) setValues(slot);
  }, [slot]);

  return {
    slot,
    register,
    errors,
    isValid,
    values,
    setValues,
    // rules,

    handleSubmit,
    saveSlot,
    deleteSlot,
  };
}




export function useSlotsConflictDetector(slots) {
  const [conflicts, setConflicts] = useState([]);

  useEffect(() => {
    const now = new Date();
    if (!slots) return;
    const conflicts = [];
    const dailyCharge = {};
    for (let i = 0; i < slots.length; i++) {
      const slot1 = slots[i];
      if (slot1.status === 'canceled') continue;
      if (slot1.end < now) continue;
      for (let j = 0; j < slots.length; j++) {
        const slot2 = slots[j];
        if (slot2.status === 'canceled') continue;
        if (slot2.end < now) continue;
        if (slot1.id === slot2.id) continue;
        if (slot1.resource_id === slot2.resource_id) {
          // Compare by time slot
          if (slot1.type === 'time' && slot2.type === 'time' && slot1.start <= slot2.end && slot2.start <= slot1.end) {
            if (!conflicts.find(({ slots: [s1, s2] }) => s1.id === slot2.id && s2.id === slot1.id)) {
              conflicts.push({
                id: `conflict-${slot1.id}-${slot2.id}`,
                type: 'conflict',
                level: 'error',
                slots: [slot1, slot2],
                message: `Conflict between ${slot1.id} and ${slot2.id}`,
                detail: {
                  slots: [slot1, slot2]
                }
              });
            }
          }
        }
      }
      if (slot1.status !== 'unavailable') {
        const dateKey = slot1.start.toISOString().slice(0, 10);
        let duration = slot1.duration;
        if (slot1.start.toISOString().slice(0, 10) !== slot1.end.toISOString().slice(0, 10)) {
          duration /= dayjs(slot1.end).diff(dayjs(slot1.start), 'day') + 1;
        }
        dailyCharge[dateKey] = dailyCharge[dateKey] || {};
        dailyCharge[dateKey][slot1.resource_id] = (dailyCharge[dateKey][slot1.resource_id] || 0) + duration;
        if (dailyCharge[dateKey][slot1.resource_id] > 8
          && (slot1.status === 'planned')) {

          conflicts.push({
            id: `overload-${slot1.id}`,
            type: 'overload',
            level: 'error',
            message: `Resource ${slot1.resource_id} is overloaded`,
            slots: [slot1],
            detail: {
              resource_id: slot1.resource_id,
              slot_id: slot1,
              task_id: slot1.task_id,
            }
          });
        }
      }
    }

    // console.log("conflicts", conflicts)
    setConflicts(conflicts);
  }, [slots]);

  return conflicts;
}

export function useSlotsBadResourceDetector({ slots, tasks, resources, projects }) {
  const [respres] = useResourcePrestations();
  return useMemo(() => {
    if (!slots || !tasks || !resources || !projects) return [];
    const tasksById = indexBy(tasks, "id");
    const resourcesById = indexBy(resources, "id");
    const projectsById = indexBy(projects, "id");
    const now = new Date();
    // console.log(respres)
    const errors = [];
    for (const slot of slots) {
      if (slot.status === 'canceled') continue;
      if (slot.status === 'done') continue;
      if (slot.status === 'confirmed') continue;
      if (slot.end < now) continue;
      const task = tasksById[slot.task_id];
      const resource = resourcesById[slot.resource_id];
      if (!task || !resource) continue;
      const agencyId = projectsById[task.project_id]?.agency_id
      const rp = respres.find((r) => r.resource_id === resource.id && r.prestation_id === task.prestation_id);
      // console.log(resource.id, task.prestation_id, rp)
      const { isValid, isOutOfAgency, isNotFound, isUnderLevel } = getResourcePrestationScore({
        level: rp?.level,
        agencyId: resource?.agency_id,
      }, {
        level: task.expected_level,
        agencyId: agencyId,
      });

      if (!isValid) {
        errors.push({
          id: `bad-resource-${slot.id}`,
          type: 'bad-resource',
          level: 'warning',
          message: `Resource ${resource.name} is not valid for task ${task.name}`,
          slots: [slot],
          detail: {
            resource,
            task,
            isNotFound,
            isOutOfAgency,
            isUnderLevel
          }
        });
      }
    }

    return errors;
  }, [slots, tasks, resources, projects, respres]);
}

export function useSlotsValidator(slots, { tasks = [], resources = [], projects = [] } = {}, events = []) {
  const visibleSlots = useMemo(() => {
    if (!slots || !events) return [];
    const idx = indexBy(events, "id")
    const out = slots.filter((s) => !!idx[s.id])
    return out
  }, [slots, events])
  const conflicts = useSlotsConflictDetector(visibleSlots);
  const badPres = useSlotsBadResourceDetector({ slots: visibleSlots, tasks, resources, projects });

  return useMemo(() => {
    const errors = []

    errors.push(...conflicts)
    errors.push(...badPres)

    return errors;
  }, [conflicts, badPres]);

}


export function getResourcePrestationScore(given, expected) {
  // console.log(given, expected)
  const { level: givenLevel, agencyId: givenAgencyId } = given
  const { agencyId: expectedAgencyId, level: expectedLevel } = expected
  let score = 0;

  const isUnderLevel = givenLevel < expectedLevel
  const isNotFound = givenLevel === undefined
  const isOutOfAgency = expectedAgencyId && givenAgencyId !== expectedAgencyId
  const isValid = !isUnderLevel && !isOutOfAgency && !isNotFound


  if (!isOutOfAgency) {
    score += 10
  }

  score += givenLevel || 0

  return {
    isUnderLevel,
    isNotFound,
    isOutOfAgency,
    isValid,
    score
  }
  // return useMemo(() => {
  //   const idx = indexBy(resPrestations, "resource_id")
  //   const items = resources
  //     // .filter((r) => {
  //     //   return !busyResources.includes(r.id) && idx[r.id]
  //     // })
  //     .map((r) => {

  //     })
  //   return items || []
  // }, [resources, resPrestations])
}





export async function autoPlan({ resourceId, type, from, duration, unit }) {
  if (!resourceId || !from || !duration || !unit) {
    return []
  }
  const dateFrom = dayjs(from)

  let nonWorkingDays = await getNonWorkingDays(dateFrom.year())
  nonWorkingDays = {
    ...nonWorkingDays,
    ...await getNonWorkingDays(dateFrom.add(1, 'year').year())
  }

  const durationInHours = unit === 'h' ? duration : duration * HOUR_IN_DAY
  const durationInDay = durationInDaysFromHours(durationInHours, 0.5)



  const slots = await listSlots({
    resource_id: resourceId,
    from: dateFrom.toISOString() // From is not supported yet
  })





  const isWorkingDay = (d) => {
    if (!d) return false
    if (d.isoWeekday() > 5) return false
    return !nonWorkingDays[d.format('YYYY-MM-DD')]
  }

  const availableDurationAt = (d) => {
    if (!isWorkingDay(d)) return 0
    const daySlots = slots.filter((s) => {
      return s.start >= d.startOf('day') && s.end <= d.endOf('day')
    })
    const dayDuration = daySlots.reduce((acc, s) => {
      if (s.type === 'task') {
        return acc + Math.min(HOUR_IN_DAY, s.duration)
      }
      return acc + Math.min(HOUR_IN_DAY, dayjs(s.end).diff(dayjs(s.start), 'hour'))
    }, 0)
    return Math.max(0, HOUR_IN_DAY - dayDuration)
  }

  const suggestedSlots = []
  let remainDuration = +durationInHours
  let rStart = null
  let rEnd = null
  let rDur = 0
  const commit = () => {
    if (rStart && rEnd) {
      suggestedSlots.push({ start: rStart, end: rEnd, duration: rDur })
      rStart = null
      rEnd = null
      rDur = 0
    }
  }
  let d = from.clone()
  let i = 0;
  if (type === 'task') {
    for (; remainDuration > 0; d = d.add(1, 'day')) {
      const free = availableDurationAt(d)
      const fullDay = free === HOUR_IN_DAY
      if (!fullDay) {
        commit()
      }
      if (free >= remainDuration) {
        if (!rStart) {
          rStart = d
        }
        rEnd = d
        rDur += remainDuration
        remainDuration = 0
      } else if (free > 0 && free <= remainDuration) {
        if (!rStart) {
          rStart = d
        }
        rEnd = d
        rDur += free
        remainDuration -= free
      } else {
        commit()
      }

      if (i++ > 100) {
        console.warn("autoPlan: Infinite loop")
        break
      }
    }
    commit()
  } else {
    // We look for an available sloy in a day
  }


  return suggestedSlots
} 