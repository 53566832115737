
import { useParams } from "react-router-dom";
import PlanningOverview from "../../components/planning/PlanningOverview";







export default function OrderPlanning() {
  const { orderId } = useParams();
  return <PlanningOverview orderId={orderId} />
}