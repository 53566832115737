
import { useWarehouse } from "../../hooks/warehouses";
import Link from "../link/Link";


export default function WarehouseLabel({ id, clickable = false }) {
  const { warehouse: wh } = useWarehouse(id);
  if (id === 'unavailable') {
    return <span>-</span>
  }
  const label = `${wh?.code} - ${wh?.name}`;
  if (!clickable) {
    return label
  }
  return <Link to={"/warehouses/" + id}>{label}</Link>;
}  