import { useParams } from "react-router-dom";
import PlanningOverview from "../../components/planning/PlanningOverview";




export default function TaskPlanning() {
  const { taskId } = useParams();

  return <PlanningOverview taskId={taskId} />
}