import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, List, Modal, Select } from "antd";
import { useMemo, useState } from "react";
import { useAdvancedSearcher } from "../../hooks/search";
import { indexBy } from "../../lib/utils";
import { SearchOptionRenderer } from "./SearchOptionRenderer";
import { SearchTagRenderer } from "./SearchTagRenderer";



export function SearchCustomFiltersModal({
    customFilters, onCustomFiltersChange,
    open, onClose,
    options, allowRevert = false
}) {

    const [activeFilter, setActiveFilter] = useState(null);
    const [label, setLabel] = useState("");
    const [form] = Form.useForm();

    const idx = useMemo(() => indexBy(options, "value"), [options]);

    const saveFilter = ({ label, description, values }) => {

        // Check if already exists and update
        for (const filter of customFilters) {
            if (filter.label === label) {
                filter.values = values;
                filter.description = description;
                onCustomFiltersChange([...customFilters]);
                return;
            }
        }

        onCustomFiltersChange([...customFilters, {
            id: 'custom-' + Date.now(),
            label: label,
            description,
            values
        }]);
        setLabel("");

    }

    const removeFilter = (id) => {
        onCustomFiltersChange(customFilters.filter((f) => f.id !== id));
    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        saveFilter(values);
        setActiveFilter(null);
    }

    const editFilter = (filter) => {
        filter = {
            label: "",
            description: "",
            values: [],
            ...filter
        }
        form.setFieldsValue(filter);
        setActiveFilter(filter);
    }

    const optionsWithoutCustom = useMemo(() => {
        // Remove custom filters
        return options?.filter(({ value }) => !value?.startsWith("custom-"));
    }, [options]);

    const onInvert = (value) => {
        if (!allowRevert) {
            return;
        }
        const val = value.startsWith('!') ? value.slice(1) : value;

        const { params } = idx[val] || { params: {} };
        if (!params.invertable || params?.name === 'customFilters' || value.endsWith('*')) {
            return; // Do nothing for custom filters
        }

        const newVal = value.startsWith('!') ? value.slice(1) : '!' + value;

        const newValues = form.getFieldValue('values').map(v => v === value ? newVal : v);
        form.setFieldValue('values', newValues);
    }

    const { list: filteredOptions, search, setSearch } = useAdvancedSearcher(optionsWithoutCustom, { values: [] });

    // // Disable options for now
    // const options = useMemo(() => {
    //   return [] // filters.map((filter) => ({ value: filter.label }));
    // }, [filters]);

    return <Modal title="Mes filtres personnalisé"
        onCancel={onClose}
        open={open}
        onClose={onClose}
        onSubmit={onClose}
        footer={activeFilter ?
            [
                <Button key="back" onClick={() => setActiveFilter(null)} >Retour</Button>,
                <Button key="save" type="primary" htmlType="submit" onClick={() => form.submit()}>Sauvegarder</Button>
            ]
            :
            [
                <Button key="new" onClick={() => editFilter({})} icon={<PlusCircleOutlined />} >
                    Créer un filtre
                </Button>,
                <Button key="back" onClick={onClose} type="primary">
                    Fermer
                </Button>
            ]}
        width="70%" style={{ maxWidth: 800 }}>
        {activeFilter
            ? (<Flex vertical style={{ flex: 1 }}>
                <Form layout="vertical" style={{ flex: 1 }} form={form} onFinish={onFinish}>

                    <Form.Item label="Nom" name="label" rules={[{ required: true, message: 'Le nom est requis' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Description" name="description" >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item label="Filtre" name="values" rules={[{ required: true, message: 'Le filtre est requis' }]}>
                        <Select
                            // style={{ width: '100%' }}
                            mode="tags"
                            optionRender={({ data }) => <SearchOptionRenderer data={data} />}
                            // tagRender={({ label, value, closable, onClose }) => SearchTagRenderer({ label, params: idx[value]?.params || 'text', value, closable, onClose })}
                            tagRender={({ label, value, closable, onClose }) => {
                                const inverted = value?.startsWith('!');
                                const v = inverted ? value.slice(1) : value;
                                const { params, label: displayLabel } = idx[v] || { label, params: {} };
                                return <SearchTagRenderer
                                    label={displayLabel}
                                    params={params}
                                    closable={closable}
                                    inverted={inverted}
                                    onClose={onClose}
                                    onInvert={() => onInvert(value)}
                                />
                            }}
                            placeholder="Rechercher..."
                            options={filteredOptions}
                            allowClear
                            filterOption={false}
                            searchValue={search}
                            allowRevert={allowRevert}
                            onSearch={(search) => setSearch(search)}
                            // filterSort={filterSort}
                            onDropdownVisibleChange={(open) => {
                                if (!open) {
                                    setSearch('');
                                }
                            }}

                        // onChange={onChangeHandler}
                        // onSearch={onSearchChange}
                        />
                    </Form.Item>
                </Form>

            </Flex>)
            : (<Flex style={{ flex: 1 }}>
                <List
                    style={{ flex: 1 }}
                    itemLayout="horizontal"
                    dataSource={customFilters}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button key="delete" onClick={() => removeFilter(item.id)} danger size="small">Supprimer</Button>,
                                <Button key="edit" onClick={() => editFilter(item)} size="small">Modifier</Button>,
                            ]}
                        >
                            <List.Item.Meta
                                title={item.label}
                                description={
                                    <div>
                                        {item.description}
                                        <div>
                                            {item.values?.map((v) => {
                                                const val = v.startsWith('!') ? v.slice(1) : v;
                                                return <SearchTagRenderer
                                                    key={v}
                                                    inverted={v.startsWith('!')}
                                                    label={idx[val]?.label || v}
                                                    params={idx[val]?.params}

                                                />
                                            })}
                                        </div>
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Flex>)
        }
    </Modal>
}