import { Select } from "antd";
import { useMemo } from "react";
import { useAgencies, useSelectedAgencyID } from "../../hooks/agencies";


export default function AgencySwitcher() {
  const [agencies] = useAgencies();
  const [agencyID, setAgencyId] = useSelectedAgencyID();


  const agencyOptions = useMemo(() => {
    const out = [
      { value: "", label: "Toutes les agences" },
    ];
    if (!agencies) {
      return out;
    }
    return [...out, ...agencies.map((o) => ({ value: o.id, label: o.name }))]
  }, [agencies]);

  const onChange = (value) => {
    setAgencyId(value);
    setTimeout(() => window.location.reload(), 100)
  }


  return (
    <Select
      variant="borderless"
      value={agencyID || ''}
      options={agencyOptions}
      onChange={onChange}
      style={{ width: 200 }}
    />
  );
}