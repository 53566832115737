import { FileExcelOutlined, FileImageOutlined, FileOutlined, FilePdfOutlined, FilePptOutlined, FileTextOutlined, FileWordOutlined, FileZipOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { formatDateTime } from "../../lib/format";

const docTypes = [
  {
    label: "Fiche technique",
    value: "technical-sheet"
  },
  {
    label: "Notice technique",
    value: "technical-notice"
  },
  {
    label: "Preuves de réalisation",
    value: "slot-proof"
  },
  {
    label: "Plan",
    value: "plan"
  },
  {
    label: "Photo",
    value: "photo"
  },
  {
    label: "Autre",
    value: "other"
  }
]

const mimesToIcons = {
  "image/jpeg": FileImageOutlined,
  "application/pdf": FilePdfOutlined,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": FileExcelOutlined,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": FileWordOutlined,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": FilePptOutlined,
  "application/zip": FileZipOutlined,
  "text/plain": FileTextOutlined,
  "application/vnd.ms-excel": FileExcelOutlined,
  "application/msword": FileWordOutlined,
  "application/vnd.ms-powerpoint": FilePptOutlined,
  "application/vnd.ms-outlook": FilePdfOutlined,
  "application/vnd.ms-access": FilePdfOutlined,
  "application/vnd.ms-project": FilePdfOutlined,
  "application/vnd.ms-publisher": FilePdfOutlined,
  "application/vnd.ms-xpsdocument": FilePdfOutlined,
  "application/vnd.oasis.opendocument.text": FileWordOutlined,
  "application/vnd.oasis.opendocument.spreadsheet": FileExcelOutlined,
  "application/vnd.oasis.opendocument.presentation": FilePptOutlined,
}

function DocumentIcon({ mime }) {
  const Icon = mimesToIcons[mime] || FileOutlined
  return <Icon style={{ fontSize: "2em", opacity: 0.7 }} />;
}

function Document({ doc }) {
  return <Flex gap={"small"} align="center">
    <DocumentIcon mime={doc.mime} />
    <Flex vertical>
      <a href={doc.url} target="_blank" rel="noreferrer">{doc.name}</a>
      <em>{formatDateTime(doc.date)}</em>
    </Flex>
  </Flex>

}

export default function EventDocuments({ documents }) {
  return <div>
    {docTypes?.map(({ label, value }) => {
      const docs = documents?.filter(doc => doc.type === value);
      if (!docs || docs?.length === 0) return null;
      return <div key={value}>
        <h4 style={{
          fontSize: "1.2em",
          color: "#222",
        }}>{label}</h4>
        {docs.map(doc => {
          return <div key={doc.id}>
            <Document doc={doc} />
          </div>
        })}
      </div>
    })}
  </div>
}