import clsx from 'clsx';
import { useLoadingState } from '../../hooks/loader';
import { Loader } from '../loader/Loader';
import LoaderBar from '../loader/LoaderBar';
import Style from './AppLoader.module.scss';

export function AppLoader() {
  const loading = useLoadingState();
  return <div className={clsx(Style.loaderBar, loading && Style['loader-visible'])}>
    <LoaderBar />
  </div>
  return (
    <div className={clsx(Style.loader, loading && Style['loader-visible'])}>
      <Loader />
    </div>
  );
}
