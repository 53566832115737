import { SearchOutlined } from '@ant-design/icons';
import { Flex, Input, Table } from 'antd';
import React, { useCallback } from 'react';
import { useItems } from '../../hooks/items';
import { useSearcher } from '../../hooks/search';

export default function ItemsList() {
  const [items] = useItems();
  const { list, search, setSearch } = useSearcher(
    items,
    useCallback((o, search, sanitizer) => sanitizer(`${o.name} ${o.code}`).includes(search), []),
  );

  return (<>
    <div className="box">
      <Flex gap="small" align='center' >
        <Input size="large" placeholder="Rechercher un produit..."
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Flex>
    </div>
    <div className="box-paddingless">
      <Table
        // bordered
        dataSource={list}
        rowKey={(record) => record.id}
        pagination={
          {
            position: ['bottomCenter'],
          }
        }
        columns={[
          {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 200,
            sorter: (a, b) => String(a.code).localeCompare(b.code),
          },
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => String(a.name).localeCompare(b.name),
          },
          {
            title: 'St Thibery',
            width: 120,
            dataIndex: ['stocks', '001'],
            key: 'st_thibery',
            sorter: (a, b) => a.stocks['001'] > b.stocks['001'] ? 1 : -1,
            render: (value) => {
              if (value < 0) {
                return <div style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>{value}</div>;
              }
              if (value === 0) {
                return <div style={{ textAlign: 'center', color: 'red' }}>{value}</div>;
              }
              if (value > 0) {
                return <div style={{ textAlign: 'center', color: 'green' }}>{value}</div>;
              }

            },
          }
        ]}
      />

    </div>
  </>);
}
