import { SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Table, Tag } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePickingPools } from '../../hooks/pickingpools';
import { useSearcher } from '../../hooks/search';

export default function PickingPoolsList() {
  const [pickingPools] = usePickingPools();
  const { list, search, setSearch } = useSearcher(
    pickingPools,
    useCallback((o, search) => o.name.toLowerCase().includes(search.toLowerCase()), []),
  );
  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };

  return (<>
    <div className="box">
      <Flex gap="small" align='center' >
        <Input size="large" placeholder="Rechercher un pool..."
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => open("*")}>Créer un pool</Button>
      </Flex>
    </div>
    <div className="box-paddingless">
      <Table
        // bordered
        dataSource={list}
        rowKey={(record) => record.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => open(record.id), // click row
          };
        }}
        columns={[
          // {
          //   title: 'Code',
          //   dataIndex: 'code',
          //   key: 'code',
          //   width: 100,
          //   sorter: (a, b) => String(a.code).localeCompare(b.code),
          // },
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => String(a.name).localeCompare(b.name),
          },
          {
            title: 'Type',
            dataIndex: 'is_main',
            key: 'is_main',
            render: (is_main) => is_main ? <Tag color='blue'>Dépôt</Tag> : <Tag>Pool</Tag>,
          },
        ]}
      />

    </div>
  </>);
}
