
import { Select } from "antd";
import { useMemo } from "react";
import { useWarehouses } from "../../hooks/warehouses";

export function WarehouseInput({ value, name, placeholder, valueFilter, onChange }) {
  const [items] = useWarehouses()


  const filteredItems = useMemo(() => {
    if (valueFilter) {
      return items.filter(valueFilter)
    }
    return items || []
  }, [items, valueFilter])

  return <Select
    showSearch
    placeholder={placeholder || 'Sélectionner un entrepôt'}
    optionFilterProp="children"
    filterOption={(input, option) => `${option?.code ?? ''} ${option?.name ?? ''}`.toLowerCase().includes(input.toLowerCase())}
    filterSort={(optionA, optionB) =>
      (optionA?.code ?? '').toLowerCase().localeCompare((optionB?.code ?? '').toLowerCase())
    }
    fieldNames={{
      label: "name",
      value: "id",
    }}
    value={value}
    onChange={onChange}
    options={filteredItems}
  />
}