import { Flex, Progress, Space, Table, Tag, Tooltip, Typography } from "antd";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import DeliveryStatus from "../../components/deliveries/DeliveryStatus";
import { ID } from "../../components/id/id";
import ShipmentMissingProducts from "../../components/shipments/ShipmentMissingProducts";
import ShipmentStatus from "../../components/shipments/ShipmentStatus";
import WarehouseLabel from "../../components/warehouses/WarehouseLabel";
import { useDeliveries } from "../../hooks/deliveries";
import { useOrder } from "../../hooks/orders";
import { usePrestations } from "../../hooks/prestations";
import { useResources } from "../../hooks/resources";
import { useShipments } from "../../hooks/shipments";
import { formatDate, formatDateTime } from "../../lib/format";
import { indexBy } from "../../lib/utils";


export default function OrderShipmentsView() {
  let { orderId } = useParams();

  const { order, reloadOrder } = useOrder(orderId);


  const [shipments, reloadShipments] = useShipments({ orderId, onlyActive: false });
  const [deliveries, reloadDeliveries] = useDeliveries({ orderId, onlyActive: false });

  const [resources] = useResources();
  const [prestations] = usePrestations();
  const resourcesIdx = useMemo(() => indexBy(resources, 'id'), [resources]);
  const prestationsIdx = useMemo(() => indexBy(prestations, 'id'), [prestations]);



  const list = useMemo(() => {
    return shipments.map(s => ({
      ...s,
      third_party_code: s.order?.third_party_code || s.order?.customer_code,
      deliveries: deliveries.filter(d => d.shipment_id === s.id),
      inPicking: s.picking_orders?.filter(po => po.status !== 'finished').length > 0,
    }))
  }, [shipments, deliveries]);




  const reload = useCallback(() => {
    reloadShipments();
    reloadDeliveries();

  }, [reloadShipments, reloadDeliveries]);



  return <Table
    // bordered
    dataSource={list}
    rowKey="id"
    size='middle'
    pagination={
      {
        position: ['bottomCenter'],
      }
    }
    expandable={{
      expandedRowRender: (record) =>
        <Flex className="box" vertical>
          <ShipmentMissingProducts items={record.items} order={order} title={<Typography.Title level={5}>Liste des produits manquants</Typography.Title>} />
        </Flex>,
      defaultExpandedRowKeys: ['0'],
      rowExpandable: record => record.completion_rate < 1,
      // showExpandColumn: false,
      expandRowByClick: true
    }}
    // onRow={(record, rowIndex) => {
    //   return {
    //     onClick: () => openSetResource(record.id), // click row
    //   };
    // }}
    columns={[
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        render: (id, { status }) => status && <ID value={id} /> // <Link to={`/picking/${id}`}>{id.replace(/^pickingorder-.{4}/, '')}</Link>
        // sorter: (a, b) => String(a.code).localeCompare(b.code),
      },
      {
        title: 'Dépôt',
        dataIndex: 'warehouse_id',
        key: 'warehouse_id',
        width: 170,
        render: (warehouse_id) => <WarehouseLabel id={warehouse_id} />
      },
      {
        title: 'Date',
        dataIndex: ['metadata', 'created_at'],
        key: 'created_at',
        width: 160,
        render: (created_at) => formatDateTime(created_at),
      },

      {
        title: 'État',
        dataIndex: 'completion_rate',
        width: 100,
        key: 'completion_rate',
        render: (completion_rate, item) => {
          if (completion_rate === 1) {
            return <Tag color="green">Complète</Tag>
          }
          return <Progress
            percent={Math.floor(100 * completion_rate)}
            strokeColor={completion_rate < 1 ? 'orange' : null}
            status="normal" />
        }
      },
      {
        title: 'Date de livraison',
        dataIndex: 'deliveries',
        key: 'deliveries',
        width: 160,
        render: (deliveries, ship) => {
          return <Space direction="vertical">
            {deliveries?.map(d => <Tooltip
              title={<div>
                <div>Livraison prévue le <b>{formatDate(d.delivery_date)}</b></div>
                <div><b>{prestationsIdx[d.prestation_id]?.name}</b> par <b>{resourcesIdx[d.resource_id]?.name}</b></div>

                <Flex justify="space-between">
                  <DeliveryStatus value={d.status} />

                </Flex>

              </div>}

              key={d.id}
            >
              {formatDateTime(d.delivery_date)}
            </Tooltip>)}
          </Space>
        }
      },


      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => <ShipmentStatus value={status} />
      },



    ]}
  />

}