
import clsx from 'clsx';
import React from 'react';
import { Outlet } from 'react-router-dom';


function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}


export default function Resources() {
  return (<>
    {/* <ul className="nav nav-pills subnav mb-4">
      <li className="nav-item">
        <NavLink to="emitter" className={navLinkActive("nav-link", "active")}>Ressources</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="channel" className={navLinkActive("nav-link", "active")}>Groupes</NavLink>
      </li>
    </ul> */}
    <Outlet />
  </>);
}
