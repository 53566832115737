import { CloseOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, DatePicker, Divider, Flex, Form, Input, Rate, Segmented, Select, Space, Switch, Tooltip, Typography } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { autoPlan, durationInDaysFromHours, roundDays, useSlots } from "../../hooks/slots"
import { taskUnits } from "../../hooks/tasks"
import { formatCurrency } from "../../lib/format"
import { getPrestation } from "../../services/api/prestations"
import { listResourcePrestationsForResource } from "../../services/api/resources-prestations"
import { listSlots } from "../../services/api/slots"
import { ResourceForPrestationInput } from "../resources/ResourceForPrestationInput"
import SlotStatusLabel from "../slots/SlotStatusLabel"
import TaskMessagesView from "../tasks/TaskMessagesView"

import TaskUnitLabel from "../tasks/TaskUnitLabel"
import Style from "./PlanningTaskSlotForm.module.scss"

export const COST_BY_DAY = 945

export default function PlanningTaskSlotForm({ form, slot, task, hideTaskInfo }) {


  const [forceDisplayDateRange, setForceDisplayDateRange] = useState(false)
  const [ownSlots, loadOwnSlots] = useSlots();
  const [busyResources, setBusyResources] = useState([])
  const [prestationInfo, setPrestationInfo] = useState({})
  const [suggestedDuration, setSuggestedDuration] = useState(0)


  useEffect(() => {
    (async () => {
      if (!slot) return
      const slots = await listSlots({ dates: [slot.start, slot.end] })

      const start = new dayjs(slot.start)
      const end = new dayjs(slot.end)
      const res = Array.from(new Set(slots?.filter(s => {
        if (s.id === slot.id) {
          return false; // it's the current slot, ignore it
        }
        if (start.isSameOrAfter(s.end)) {
          return false; // it's before the slot, ignore it
        }
        if (end.isSameOrBefore(s.start)) {
          return false; // it's after the slot, ignore it
        }
        return true;
      })
        .map(s => s.resource_id)))
      setBusyResources(res)
    })()


  }, [slot])



  // const isUnit = task?.unit === 'u'


  const type = Form.useWatch("type", { form })
  const auto = Form.useWatch("auto", { form })
  const resourceId = Form.useWatch("resource_id", { form })
  const toPlanDuration = Form.useWatch("toplan_duration", { form })
  const toPlanUnit = Form.useWatch("toplan_unit", { form })
  const suggestedSlots = Form.useWatch("suggested_slots", { form, preserve: true })


  const isInterval = !slot.start.isSame(slot.end, 'day')
  const isTask = type === "task"
  const isAuto = auto && type === "task"

  useEffect(() => {
    if (!task) return
    loadOwnSlots({ task_id: task.id })
  }, [task])

  useEffect(() => {
    if (!ownSlots || resourceId) {
      return
    }
    const sorted = ownSlots.sort((a, b) => a.start < b.start)
    if (sorted.length === 0) {
      return
    }
    const last = sorted[0]
    form.setFieldsValue({ resource_id: last.resource_id })
  }, [busyResources, resourceId, ownSlots, form])


  // Init form

  useEffect(() => {
    if (!slot || !task || !task.metrics) return

    let duration = Math.max(0, task.metrics.duration - task.metrics.planned_duration);

    setForceDisplayDateRange(durationInDaysFromHours(duration) > 1)
    let unit = task.unit || 'h'
    if (unit === 'u') {
      unit = 'd'
    }

    if (unit === 'd') {
      duration = durationInDaysFromHours(duration)
    }



    if (unit === 'd' && duration <= 1 && task.metrics?.cost > (COST_BY_DAY * 1.4)) {
      const d = roundDays(task.metrics?.cost / COST_BY_DAY)
      setSuggestedDuration(roundDays(d))
    }

    form.setFieldsValue({
      estimated_duration: duration,
      auto: true,
      toplan_duration: duration,
      toplan_unit: unit,
      unit: task.unit,
    })

  }, [form, slot, task])




  useEffect(() => {
    if (!task || !resourceId) {
      return
    }
    (async () => {
      const basePresta = await getPrestation(task.prestation_id)

      const prestas = await listResourcePrestationsForResource(resourceId)
      const presta = prestas.find(p => p.prestation_id === task.prestation_id)


      setPrestationInfo({
        performance: presta?.performance || 100,
        price: presta?.price || basePresta?.price || null,
        purchase_price: presta?.purchase_price || basePresta?.purchase_price || null,
      })

    })()
  }, [resourceId, task])

  const toPlanDurationPerformed = roundDays(toPlanDuration * 1 / ((prestationInfo?.performance || 100) / 100));

  // Plan until the end of duration only on the working days
  useEffect(() => {
    if (!resourceId || !slot?.start || !isAuto || !toPlanDurationPerformed) return

    // const toPlanDurationPerformed = toPlanDuration * (prestationInfo?.performance || 100 / 100)

    (async () => {
      const slots = await autoPlan({
        from: slot.start,
        type,
        resourceId: resourceId,
        unit: toPlanUnit,
        duration: toPlanDurationPerformed,
      })
      form.setFieldsValue({
        suggested_slots: slots,
      })
    })()
  }, [form, toPlanDurationPerformed, toPlanUnit, slot, type, resourceId, isAuto, prestationInfo, task])


  if (!task) {
    return null
  }



  if (!task) {
    return <Typography.Text type="danger">Tâche inconnue</Typography.Text>
  }

  const isNew = !slot.id
  const displayAsDateRange = forceDisplayDateRange || isInterval || !isAuto
  const displayHours = type === 'time'

  const { cost, duration: duration_hours, planned_cost, planned_duration,
    slots_count, slots_planned_count, slots_confirmed_count, slots_complete_count } = task.metrics || {};

  return <Flex justify="stretch">
    {task && !hideTaskInfo ?
      <>
        <div style={{ minWidth: 300, maxWidth: 300, borderRight: "1px solid #eee", paddingRight: 15 }}>
          <Flex justify="space-between" align="center">
            <Rate disabled value={Math.ceil(task?.expected_level * 3 / 5)} style={{ fontSize: '0.8em' }} count={3} />
            <SlotStatusLabel status={slot?.status} />
          </Flex>
          {cost > 0 ?
            <div>
              Vendu :&nbsp;
              <b>{formatCurrency(cost)}</b>
              {/* <b>{expectedDuration} {unit}</b> / <b>{formatNumber(expectedAmount)} €</b> */}
            </div>
            : null}
          {task?.description && <div className="form-text" style={{ maxHeight: 200, overflow: 'auto' }}>
            {task.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
          </div>}
          <Divider />
          <TaskMessagesView taskId={task?.id} />
        </div>
      </> : null}

    <Form
      form={form}
      // layout="vertical"
      labelWrap
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 32 }}
      style={{ flex: 1 }}
    >


      <Form.Item label="Mode" >
        <Flex gap="middle">
          <div>
            <Form.Item name="type" noStyle >
              <Segmented options={[
                { value: 'task', label: 'planification' },
                { value: 'time', label: 'à heure précise' },
              ]} />
            </Form.Item>
          </div>
          {isNew && !isInterval && isTask &&
            <div style={{ marginBottom: -24 }}><Form.Item name="auto">
              <Switch checkedChildren="auto" unCheckedChildren="man." style={{ marginBottom: 0 }} />
            </Form.Item>
            </div>}
        </Flex>
      </Form.Item>
      {isAuto ?
        <Form.Item label="Durée à planifier" >
          <Flex vertical>
            <Space.Compact>
              <Form.Item noStyle name="toplan_duration" rules={[
                { required: true, message: "Veuillez spécifier une durée" },
                {
                  validator: (_, value) =>
                    value >= 0.5 ? Promise.resolve() : Promise.reject(new Error('Veuillez spécifier une durée supérieure à 0')),
                }
              ]}>
                <Input type="number" style={{ maxWidth: 70 }} min={0} variant="borderless" />
              </Form.Item>
              <Form.Item noStyle name="toplan_unit">
                <Select style={{ width: 100 }} variant="borderless" options={taskUnits} />
              </Form.Item>
            </Space.Compact>
            {suggestedDuration > toPlanDuration && <Tooltip title={`Dans certains cas les lignes sont saisie en considérant un forfait, Chronos essaye donc de déterminer de façon arbitraire un nombre de jour en divisant le cout de la ligne par ${COST_BY_DAY}€`}><Typography.Text type="success" >
              Durée suggérée: <Button type="link" onClick={() => {
                form.setFieldsValue({ toplan_duration: suggestedDuration })
              }}><b>{suggestedDuration} <TaskUnitLabel value={toPlanUnit} lowercase /></b></Button>
            </Typography.Text></Tooltip>}
            {Math.abs(toPlanDurationPerformed - toPlanDuration) > 0.01 && <Typography.Text type="secondary">
              <Tooltip title="La durée ajustée est calculée en fonction de la performance de la ressource">
                Durée ajustée à <b>{toPlanDurationPerformed} <TaskUnitLabel value={toPlanUnit} lowercase /></b>
              </Tooltip></Typography.Text>}
          </Flex>
        </Form.Item>
        : null}
      <Form.Item label={task ? "Affecté à" : "Ressource"} name="resource_id" rules={[{ required: true, message: "Veuillez spécifier une ressource affectée" }]}>
        <ResourceForPrestationInput
          prestationId={task?.prestation_id}
          agencyId={task?.agency_id}
          expectedLevel={task?.expected_level}
          showPerformance
          busyResources={busyResources} />
      </Form.Item>
      <Form.Item label="Dates" >
        {!isAuto
          ? (displayAsDateRange ?

            <Form.Item noStyle label="Date" name="dates" rules={[{ required: true, message: "Veuillez spécifier un intervale de date" }]}>
              <DatePicker.RangePicker format={"DD/MM/YYYY" + (displayHours ? " HH:mm" : "")}
                showTime={displayHours ? { format: 'HH:mm', minuteStep: 15 } : false}
                variant="borderless"
                disabled={isAuto} />

            </Form.Item> :
            <Form.Item noStyle label="Date" name={["dates", 0]} rules={[{ required: true, message: "Veuillez spécifier une date" }]}>
              <DatePicker format={"DD/MM/YYYY"} variant="borderless" />
            </Form.Item>
          )
          : <div>
            {(suggestedSlots?.length === 0 || toPlanDuration <= 0) && <Typography.Text type="warning">Il n'y a pas de temps à planifier</Typography.Text>}
            {suggestedSlots?.map(({ start, end, duration, disabled }, i) => {
              return <Flex key={i} align="center" className={disabled ? Style.strike : null}>
                <DatePicker.RangePicker
                  format={"DD/MM/YYYY" + (displayHours ? " HH:mm" : "")}
                  value={[dayjs(start), dayjs(end)]}
                  showTime={displayHours ? { format: 'HH:mm', minuteStep: 15 } : false}
                  variant="borderless"
                  disabled={isAuto} />
                <div>({duration}h)</div>
                <Button size="small" type="text" icon={disabled ? <PlusOutlined /> : <CloseOutlined />} onClick={() => {
                  const slots = suggestedSlots.map((_, j) => {
                    return {
                      ..._,
                      disabled: (i === j) ? !_.disabled : _.disabled
                    }
                  })
                  form.setFieldsValue({ suggested_slots: slots })
                }} />
              </Flex>
            })}
          </div>
        }
      </Form.Item>

      {isTask && !isAuto && <Form.Item label="Durée estimée" name="estimated_duration" rules={[
        { required: true, message: "Veuillez spécifier une durée" },
        {
          validator: (_, value) =>
            value >= 0.5 ? Promise.resolve() : Promise.reject(new Error('Veuillez spécifier une durée supérieure à 0')),
        }]}>
        <Input type="number" min={1} suffix="Heures" style={{ maxWidth: 130 }} />
      </Form.Item>}





    </Form>
  </Flex>
}