import { useMemo } from "react";
import { resourceTypes } from "../../hooks/resources";
import ResourceIcon from "./ResourceIcon";


export default function ResourceTypeLabel({ type }) {
  const value = useMemo(() => {
    return resourceTypes.find(s => s.value === type) || { label: 'Inconnu', color: 'secondary' }
  }, [type])

  if (!value) {
    return <span className="">Inconnu</span>
  }
  return <span className={``}>
    <ResourceIcon type={type} size={0.8} />{' '}
    {value.label}
  </span>
}