import { Badge, Button, Calendar, ConfigProvider, Flex, Modal, Segmented, Table } from "antd";
import { useEffect, useMemo, useState } from "react";
import { slotStatusesIndex, useSlots } from "../../hooks/slots";

import { ReloadOutlined } from "@ant-design/icons";
import SlotView from "../../components/slots/SlotView";
import { formatDateTime } from "../../lib/format";
import { invalidateSlotCache } from "../../services/api/slots";
import { ID } from "../id/id";
import ProjectLabel from "../projects/ProjectLabel";
import ResourceLabel from "../resources/ResourceLabel";
import SlotStatusLabel from "../slots/SlotStatusLabel";
import TaskLabel from "../tasks/TaskLabel";




function Event({ slot, projectId, resourceId, }) {
  const statusInfo = slotStatusesIndex[slot.status];

  const label = useMemo(() => {
    if (slot.task_id === 'unavailable') {
      return 'Indisponible';
    }
    const out = [];
    if (!projectId) {
      out.push(<ProjectLabel key="project" id={slot.project_id} clickable={false} />);
    }
    out.push(<TaskLabel key="task" id={slot.task_id} clickable={false} />);
    if (!resourceId) {
      out.push(<ResourceLabel key="resource" id={slot.resource_id} clickable={false} />);
    }
    return out.reduce((acc, el, i) => {
      if (acc.length > 0) {
        acc.push(<span key={i}> | </span>);
      }
      acc.push(el);
      return acc;
    }, []);
  }, [slot, projectId, resourceId]);
  return <div gap={"small"} style={{ fontSize: '0.9em' }}>
    <Badge color={statusInfo.color} style={{ marginRight: '0.5em' }} />
    {label}
  </div>

}



function Events({ slots, date, resolution, projectId, resourceId, onClick }) {
  const events = useMemo(() => slots.filter(slot => {
    switch (resolution) {
      case 'month':
        return slot.start.toISOString().startsWith(date.format('YYYY-MM'))
      case 'date':
        return slot.start.toISOString().startsWith(date.format('YYYY-MM-DD'))
      default:
        return false;
    }
  }), [slots, date, resolution])
  return (
    <div>
      {events.map(slot => {
        return <div key={slot.id} onClick={() => onClick(slot)}>
          <Event slot={slot} projectId={projectId} resourceId={resourceId} />
        </div>
      })}
    </div>
  );
}


function PlanningCalendarView({ slots, onClick, projectId, resourceId, orderId }) {
  return <ConfigProvider
    theme={{
      components: {
        Calendar: {
          fullBg: 'transparent',
        },
      },
    }}
  >
    <Calendar
      cellRender={(date, info) => {
        return <Events slots={slots}
          date={date}
          resolution={info.type}
          onClick={onClick}
          projectId={projectId} resourceId={resourceId}
          orderId={orderId}
        />
      }}
    />
  </ConfigProvider>
}

function PlanningListView({ slots, onClick, projectId, resourceId, orderId }) {
  const columns = useMemo(() => [
    {
      title: '#',
      dataIndex: 'id',
      render: (id, slot) =>
        <ID onClick={() => onClick(slot)} value={id} />
    },
    ...(projectId ? [] : [
      {
        title: 'Affaire',
        dataIndex: 'project_id',
        render: (project_id) => <div>
          <div><ProjectLabel id={project_id} /></div>

        </div>,
      }]),
    {
      title: 'Tâche',
      dataIndex: 'task_id',
      render: (task_id) => <TaskLabel id={task_id} />,
    },
    ...(resourceId ? [] : [
      {
        title: 'Ressource',
        dataIndex: 'resource_id',
        render: (resource_id) => <ResourceLabel id={resource_id} />,
      }]),
    {
      title: 'Statut',
      dataIndex: 'status',
      render: (status, { deleted }) => {
        return <SlotStatusLabel status={status} deleted={deleted} />
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type) => {
        return type
      },
    },
    {
      title: 'Début',
      dataIndex: 'start',
      render: (start) => formatDateTime(start),
      sorter: (a, b) => a.start - b.start,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],


    },
    {
      title: 'Fin',
      dataIndex: 'end',
      render: (end) => formatDateTime(end),
    },
    {
      title: 'Durée',
      dataIndex: 'duration',
      render: (duration) => `${duration}h`,
    },
    {
      title: 'Durée réelle',
      dataIndex: 'real_duration',
      render: (real_duration, s) => s.status === 'done' ? <b>{real_duration}h</b> : null,
    }
  ], [projectId, resourceId, orderId]);


  return <div>
    <Table dataSource={slots} rowKey="id"
      columns={columns}
    />
  </div>
}


export default function PlanningOverview({ resourceId, projectId, taskId, orderId }) {
  const [view, setView] = useState('list');
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [refresh, setRefresh] = useState(0);


  const [slots, loadSlots] = useSlots();

  const slotsEnriched = useMemo(() => {

    return slots.map(slot => {
      // const project = projectIdx[slot.project_id];

      return {
        ...slot,
        // project,
      }
    })
  }, [slots]);

  useEffect(() => {
    if (!resourceId && !projectId && !taskId && !orderId) return;
    loadSlots({
      resource_id: resourceId,
      project_id: projectId,
      order_id: orderId,
      task_id: taskId,
      // startDate: '2021-01-01',
      // endDate: '2021-12-31',
    });
  }, [refresh, loadSlots, resourceId, projectId, taskId, orderId]);


  const doRefresh = () => {
    invalidateSlotCache();
    setRefresh(refresh + 1);
  };

  return (
    <Flex className="box" gap="small" vertical>
      <Flex justify="space-between" gap="small">
        <Segmented options={[
          { label: 'Liste', value: 'list' },
          { label: 'Calendrier', value: 'planning' },
        ]} value={view} onChange={setView} />
        <Button onClick={() => doRefresh()} icon={<ReloadOutlined />} />
      </Flex>
      {
        view === 'planning' ? <PlanningCalendarView
          slots={slotsEnriched}
          onClick={(slot) => setSelectedSlot(slot)}
          projectId={projectId} resourceId={resourceId} orderId={orderId} taskId={taskId} /> : null
      }
      {
        view === 'list' ? <PlanningListView
          slots={slotsEnriched}
          onClick={(slot) => setSelectedSlot(slot)}
          projectId={projectId} resourceId={resourceId} orderId={orderId} taskId={taskId} /> : null
      }
      <Modal open={selectedSlot} maskClosable
        destroyOnClose
        footer={null}
        title={selectedSlot?.project?.name}
        onCancel={() => setSelectedSlot(null)} >
        <SlotView slot={selectedSlot} />
      </Modal>
    </Flex >
  );
}