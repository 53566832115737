import { useCallback, useEffect, useMemo, useState } from 'react';

import { add } from 'date-fns';
import { indexBy } from '../lib/utils';
import {
  createProject, getProject, invalidateProjectCache, listProjects, removeProject, updateProject,
} from '../services/api/projects';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { useDelayedRenderingState } from './state';

const INVALIDATION_KEY = Symbol('projects');



export const projectStatuses = [
  // {
  //   label: 'À planifier',
  //   value: 'unplanned',
  //   color: 'default'
  // },
  // {
  //   label: "Planification",
  //   value: 'inplanning',
  //   color: '#1677ff'
  // },
  // {
  //   label: "Planifiée",
  //   value: 'planned',
  //   color: '#13C2C2'
  // },
  {
    label: "En cours",
    value: 'open',
    color: '#1677ff'
  },
  // {
  //   label: "Bloquée",
  //   value: 'blocked',
  //   color: 'orange'
  // },
  // {
  //   label: "En",
  //   value: 'canceled',
  //   color: 'red'
  // },
  {
    label: "Terminé",
    value: 'closed',
    color: '#52C41A'
  }
];

export const projectTypes = [
  { "code": "FR1", "label": "A ANNULER", "abbr": "Intervention en g", hidden: true },
  { "code": "FR2", "label": "A ANNULER", "abbr": "Client d'exportat", hidden: true },
  { "code": "F03", "label": "AGRICOLE ARBORICULTURE", "abbr": "AGRICOLE ARBO" },
  { "code": "F04", "label": "AGRICOLE GRANDE CULTURE", "abbr": "AGRICOLE GRANDE C" },
  { "code": "F02", "label": "AGRICOLE MARAICHAGE", "abbr": "AGRICOLE MARAICHA" },
  { "code": "F34", "label": "AGRICOLE TROPICAL", "abbr": "AGRICOLE TROPICAL" },
  { "code": "F01", "label": "AGRICOLE VIGNE", "abbr": "AGRICOLE VIGNE" },
  { "code": "F07", "label": "ASA AEP SYNDICAT", "abbr": "ASA AEP SYNDICAT" },
  { "code": "F12", "label": "CAMPING", "abbr": "CAMPING" },
  { "code": "F38", "label": "CANALISATION", "abbr": "CANALISATION" },
  { "code": "F35", "label": "CESSION/NEGOCE", "abbr": "CESSION/NEGOCE" },
  { "code": "F08", "label": "CONTRAT MAINTENANCE", "abbr": "CONTRAT MAINTENAN" },
  { "code": "F18", "label": "ENVIRONNEMENT", "abbr": "ENVIRONNEMENT" },
  { "code": "F11", "label": "ESPACES VERTS", "abbr": "ESPACES VERTS" },
  { "code": "F37", "label": "GOLF", "abbr": "GOLF" },
  { "code": "F99", "label": "Hors critères", "abbr": "" },
  { "code": "F15", "label": "INTERVENTION GARANTIE", "abbr": "INTERVENTION GARA" },
  { "code": "F14", "label": "MAGASIN AIGUES VIVES", "abbr": "MAGASIN AIGUES" },
  { "code": "F33", "label": "MAGASIN LAVAUR", "abbr": "MAGASIN LAVAUR" },
  { "code": "F13", "label": "MAGASIN SAINT THIERY", "abbr": "MAGASIN ST THIBER" },
  { "code": "F19", "label": "MAGASIN VILLASAVARY", "abbr": "MAGASIN VILLA" },
  { "code": "F21", "label": "MAIRIE/COLLECTIVITES", "abbr": "MAIRE/COLLECTIVI" },
  { "code": "F36", "label": "PEPINIERE/HORTICULTURE", "abbr": "PEPINIERE/HORTICU" },
  { "code": "F06", "label": "PRIVE EAU", "abbr": "PRIVE EAU" },
  { "code": "F05", "label": "PUBLIC EAU", "abbr": "PUBLIC EAU" },
  { "code": "F16", "label": "RECHERCHE DEVELOPPEMENT AGRICOLE", "abbr": "RECHERCHE AGRICOL" },
  { "code": "F17", "label": "RECHERCHE DEVELOPPEMENT EAU", "abbr": "RECHERCHE EAU" },
  { "code": "F20", "label": "REVENDEUR IRRIGATION", "abbr": "REVENDEUR IRRIGAT" },
  { "code": "F39", "label": "REVENDEUR PARC ET JARDINS", "abbr": "REVENDEUR PARC ET" },
  { "code": "F09", "label": "TRAVAUX PUBLICS", "abbr": "TRAVAUX PUBLICS" },
  { "code": "F22", "label": "X", "abbr": "X", hidden: true },
  { "code": "F23", "label": "X", "abbr": "X", hidden: true },
  { "code": "F24", "label": "X", "abbr": "X", hidden: true },
  { "code": "F25", "label": "X", "abbr": "X", hidden: true },
  { "code": "F26", "label": "X", "abbr": "X", hidden: true },
  { "code": "F27", "label": "X", "abbr": "X", hidden: true },
  { "code": "F28", "label": "X", "abbr": "X", hidden: true },
  { "code": "F29", "label": "X", "abbr": "X", hidden: true },
  { "code": "F30", "label": "X", "abbr": "X", hidden: true },
  { "code": "F31", "label": "X", "abbr": "X", hidden: true },
  { "code": "F32", "label": "X", "abbr": "X", hidden: true }
]


const projectTypesIdx = indexBy(projectTypes, 'code');
export function formatProjectType(value) {
  return projectTypesIdx[value]?.label || value;
}


export const projectInvoicingModes = [
  { "code": "GAR", "label": "Garantie" },
  { "code": "SAV", "label": "Dépannage / Intervention" },
  { "code": "CHA", "label": "Chantier AGRICOLE" },
  { "code": "NEG", "label": "NEGOCE / FOURNITURE SANS PRESTATION" },
  { "code": "PRF", "label": "FACTURATION FORFAITAIRE" },
  { "code": "NPF", "label": "NE PAS FACTURER" },
  { "code": "AID", "label": "Facturation sans commentaires de pièces commerciales" },
  { "code": "AST", "label": "Facturation contrat d’assistance ou d’entretien" },
  { "code": "PUB", "label": "Facturation déconnectée des BL" },
]

const projectInvoicingModesIdx = indexBy(projectInvoicingModes, 'code');
export function formatProjectInvoicingMode(value) {
  return projectInvoicingModesIdx[value]?.label || value;
}

function getDefaults() {
  return {
    code: "",
    name: "",
    description: "",
    commercialCode: "",
    customerCode: "",
    address: "",
    phone: "",

    expected_start_date: new Date(),
    expected_end_date: add(new Date(), { months: 6 }),
  };
}

async function getItem(id, { withLogs = false } = {}) {
  if (id === '*' || !id) {
    return { item: getDefaults() };
  }
  try {
    const { item, changes } = await loaderWrap(getProject(id, { withLogs }));
    return {
      item: {
        ...getDefaults(),
        ...enrich(item),
      },
      changes: changes
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function extractCode(code) {
  if (!code) return {};
  // AF0100018169CHA00
  return {
    project_type: code.slice(1, 4), // F01
    project_code: code.substr(4, 8), // 00018169
    project_invoicing_mode: code.substr(-5, 3),
  }
}

function enrich(project) {
  if (!project) return project;
  return {
    ...project,
    ...extractCode(project.code),
  }
}

export function useProjects({ all = false } = {}) {
  const [projects, setProjects] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listProjects()));
        const proj = await loaderWrap(listProjects({ all }));
        setProjects(proj.map(enrich));
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter, all]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [projects, refresh, complete];
}



export function useProjectsLoader() {
  const [activeProjects, refreshActiveProjects, complete] = useProjects();

  const [toLoad, setToLoad] = useDelayedRenderingState([]);
  const [limitExceeded, setLimitExceeded] = useState(false);

  const [extraLoading, setExtraLoading] = useState(false);
  // const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [extraProjects, setExtraProjects] = useState([]);

  const activeProjectsIdx = useMemo(() => indexBy(activeProjects, 'id'), [activeProjects]);

  useEffect(() => {

    if (!complete) return;
    if (extraLoading) return;
    if (!toLoad.length) return;
    if (limitExceeded) return;
    const pendingIds = Array.from(new Set(toLoad)).filter((id) => {
      return !extraProjects.find(p => p.id === id) && !activeProjectsIdx[id];
    }); // Dedup
    if (!pendingIds.length) return;
    if (pendingIds.length > 100) {
      console.warn('project loader limit exceeded', pendingIds);
      setLimitExceeded(true);
      return;
    }


    const ids = pendingIds.splice(0, 15); // Load 10 at a time
    setExtraLoading(true);

    setToLoad(pendingIds);

    // Loading
    (async () => {
      ids.forEach(id => {
        if (!id) return;
        if (extraProjects.find(p => p.id === id)) return;
        setExtraProjects((prev) => [...prev, { id }]); // Add empty project
      })

      const projects = await loaderWrap(Promise.all(ids.map(async id => {
        const { item } = await getItem(id)
        return item;
      })));

      setExtraProjects([...extraProjects, ...projects]);
    })().finally(() => {
      setExtraLoading(false);
    });

  }, [toLoad, complete, extraLoading]);

  const refresh = useCallback(() => {
    invalidateProjectCache();
    refreshActiveProjects();
    setExtraProjects([]); // Clear extra projects
    setToLoad(extraProjects.map(p => p.id)); // Reload extra projects
  }, [refreshActiveProjects, setExtraProjects, setToLoad, extraProjects]);

  const projects = useMemo(() => {
    return [
      ...activeProjects,
      ...extraProjects,
    ];
  }, [activeProjects, extraProjects]);

  const idx = useMemo(() => indexBy(projects, 'id'), [projects]);

  const getProject = useCallback((id, autoLoad = true) => {
    if (!id) return null;
    if (autoLoad && !idx[id]) {
      // Add to load
      setToLoad((prev) => {
        const v = [...prev, id];
        return v
      });
      return null
    }
    return idx[id];
  }, [idx, setToLoad]);

  // console.log(loaderID, 'exit');
  return {
    projects,
    refresh,
    complete,
    getProject,
  };
}

export function useProject(id, { withLogs = false } = {}) {
  const [{ project, changes }, setProject] = useState({});

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      const { item, changes } = await getItem(id, { withLogs });
      setProject({ project: item, changes });
    })();
  }, [id, counter]);

  const saveProject = useCallback(async (item) => {
    if (!item) {
      throw new Error('No project');
    }

    item = {
      ...item,
      expected_start_date: item.expected_start_date ? new Date(item.expected_start_date).toISOString() : null,
      expected_end_date: item.expected_end_date ? new Date(item.expected_end_date).toISOString() : null,
    }

    let out;
    if (item.id) {
      out = await loaderWrap(updateProject(item));
    } else {
      out = await loaderWrap(createProject(item));
    }
    setProject(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteProject = useCallback(async () => {
    await loaderWrap(removeProject(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    project: project,
    changes: changes,
    reloadProject: () => invalidate(id),
    isNewProject: !project?.id,
    setProject,
    saveProject,
    deleteProject,
  };
}
