
import Link from "../link/Link";

import { Tag } from "antd";
import { useTask } from "../../hooks/tasks";
import { ID } from "../id/id";


export default function TaskLabel({ id, clickable = true }) {
  const { task } = useTask(id);
  if (id === 'unavailable') {
    return <span>-</span>
  }

  let label = task?.name;
  if (!label) {
    return <span><ID value={id} /> {id ? <Tag color="red">Supprimé</Tag> : null}</span>
  }

  if (!clickable) {
    return label
  }
  return <Link to={"/tasks/" + id}>{label}</Link>;
}