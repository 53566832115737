import { Form, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { pickingPriorities } from "../../hooks/pickingorders";
import { usePrestations } from "../../hooks/prestations";
import { useResources } from "../../hooks/resources";
import { createPickingOrder } from "../../services/api/pickingorders";
import { loaderWrap } from "../../services/loader";



export default function ShipmentAddPickingModal({ warehouseId, order, onFinished }) {

  const [prestations] = usePrestations();
  const warehouseCode = warehouseId?.split('-')[1]
  const prestationId = prestations.find(p => p.code === 'CMDPREP' + warehouseCode)?.id;
  const [resources, , resourcesComplete] = useResources({ prestationId });

  // Set resource modal
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setSavingSetResource] = useState(false);

  useEffect(() => {
    setOpen(!!order);
    form.resetFields();
  }, [order, form]);

  const onOk = () => {
    setSavingSetResource(true);
    form.validateFields()
      .then(async ({ resource_id, priority }) => {
        if (!resource_id) {
          return;
        }

        if (!order.shipment_id) {
          alert('No shipment found')
          return
        }
        await loaderWrap(createPickingOrder({
          order_id: order.id,
          shipment_id: order.shipment_id,
          resource_id,
          status: 'pending',
          priority,
          warehouse_id: warehouseId,
        }));
        onFinished && onFinished();
      })
      .finally(() => {
        setSavingSetResource(false);
        setOpen(false);
      });
  }

  return <Modal
    title="Affecter un préparateur"
    destroyOnClose={true}
    maskClosable={false}
    centered
    open={open}
    onOk={onOk}
    confirmLoading={loading}
    cancelText="Annuler"
    onCancel={() => {
      setOpen(false)
      form.resetFields();
    }}

  >
    {order && <div>
      Sélectionnez une ressource pour affecter la préparation de la commande n°<b>{order?.order_number}</b>
    </div>}
    <Form form={form} >
      <Form.Item name="resource_id" rules={[{ required: true }]} >
        <Select
          size="large" placeholder="Ressource"
          options={[
            ...resources.map(r => ({ label: r.name, value: r.id }))
          ]}
        />
      </Form.Item>
      <Form.Item label="Priorité" name="priority" initialValue={2}>
        <Select size="large" placeholder="Priorité" options={
          pickingPriorities.map(p => ({ label: p.label, value: p.value }))} />
      </Form.Item>
    </Form>
  </Modal>
}