
import { Button, Form, Input, message } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ControlBar } from '../../components/controlbar/ControlBar';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import { useWarehouse } from '../../hooks/warehouses';

// import { SubjectTimeline } from '../../components/dataviz/SubjectTimeline';
// import { ControlBar } from '../../components/controlbar/ControlBar';

export default function WarehouseForm() {
  const navigate = useNavigate();
  const { warehouseId } = useParams();
  const [form] = Form.useForm();

  const {
    warehouse, saveWarehouse, deleteWarehouse, isNewWarehouse
  } = useWarehouse(warehouseId || '');


  useEffect(() => {
    if (warehouse) {
      form.setFieldsValue({
        ...warehouse,
      });
    }
  }, [form, warehouse]);


  if (!warehouse) {
    return <div>Not found</div>;
  }



  const onFinish = (values) => {
    (async () => {
      try {
        const item = await saveWarehouse({
          ...warehouse,
          ...values,
        });
        message.success('Enregistré');
        if (item) {
          navigate('/warehouses');
        }
      } catch (err) {
        message.error(`Erreur: ${err}`);
      }
    })();
  };

  const onDelete = () => {
    if (warehouseId) {
      (async () => {
        await deleteWarehouse();
        message.success('Supprimé');
        setTimeout(() => {
          navigate('/warehouses');
        }, 1000);
      })();
    }
  };


  return (
    <Form form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ flex: 1 }}
      labelWrap
      onFinish={onFinish}
    >

      <fieldset className="box">
        <legend>Informations</legend>
        <Form.Item name="name" label="Nom" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="code" label="Code" rules={[{ required: true }]}>
          <Input disabled={!isNewWarehouse} />
        </Form.Item>
        {/* <Form.Item name="capacity" label="Capacité"
          help="Capacité maximale de préparation de commandes en lignes/jour"
          rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item> */}
      </fieldset>

      <ControlBar>
        <DeleteButton onDelete={onDelete} />
        <div>
          <Button type="primary" size='large'
            onClick={() => form.submit()}>Enregistrer</Button>
        </div>
      </ControlBar>

    </Form>
  );
}
