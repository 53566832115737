
import { mdiCalendarOutline, mdiDrawing, mdiFormatListBulletedType, mdiPackageVariantClosed, mdiTruckFastOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Result, Skeleton, Space } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useOrder } from '../../hooks/orders';


function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}


export default function OrderItem() {
  const { orderId } = useParams();
  const { order, loading } = useOrder(orderId);
  if (loading) return <Skeleton style={{ padding: '1rem' }} active />;

  if (!order?.id) {
    return <Result
      status="404"
      title="Commande non trouvée"
      subTitle="Ce numéro de commande n'existe pas dans Chronos."
    // extra={<Button type="primary">Back Home</Button>}
    />
  }
  return (<>
    {orderId && orderId !== '*' ? (
      <ul className="nav nav-pills subnav mb-4">
        <li className="nav-item">
          <NavLink to="details" className={navLinkActive("nav-link", "active")}>Détail</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="tasks" className={navLinkActive("nav-link", "active")}>
            <Space align="center"><Icon path={mdiDrawing} size={0.8} /> Tâches</Space>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="shipments" className={navLinkActive("nav-link", "active")}>
            <Space align="center"><Icon path={mdiPackageVariantClosed} size={0.8} /> Expéditions</Space>
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink to="pickings" className={navLinkActive("nav-link", "active")}>
            <Space align="center"><Icon path={mdiPackageVariant} size={0.8} /> Préparations</Space>
          </NavLink>
        </li> */}
        {/* <li className="nav-item">
          <NavLink to="shipments" className={navLinkActive("nav-link", "active")}>Expéditions</NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink to="deliveries" className={navLinkActive("nav-link", "active")}>
            <Space align="center"><Icon path={mdiTruckFastOutline} size={0.8} /> Livraisons</Space>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="planning" className={navLinkActive("nav-link", "active")}>
            <Space align="center"><Icon path={mdiCalendarOutline} size={0.8} /> Planning</Space>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="events" className={navLinkActive("nav-link", "active")}>
            <Space align="center"><Icon path={mdiFormatListBulletedType} size={0.8} /> Historique</Space>
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink to="slotaway" className={navLinkActive("nav-link", "active")}>Indisponibilités</NavLink>
        </li> */}
      </ul>) : null}
    <Outlet />
  </>);
}
