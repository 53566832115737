import { useResource } from "../../hooks/resources";
import Link from "../link/Link";


export default function ResourceLabel({ id, clickable = true }) {
  const { resource } = useResource(id);
  if (!resource) {
    return <span className="badge bg-danger">Inconnu</span>
  }
  if (!clickable) {
    return resource?.name
  }
  return <Link to={"/resources/" + id}>{resource?.name}</Link>;
}