import { mdiAccountBox, mdiAccountHardHatOutline, mdiAlphaT, mdiAnimationOutline, mdiCurrencyEur, mdiDrawing, mdiFilterOutline, mdiProgressWrench, mdiSelectGroup, mdiStoreOutline } from '@mdi/js';
import { useCallback, useMemo, useState } from 'react';
import ProjectShortCode from '../components/projects/ProjectShortCode';
import { projectInvoicingModes, projectTypes } from './projects';
import { usePersistentState } from './state';

export function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

const sanCache = new Map();
export function sanitizeString(str, withCache = false, split = null) {
  if (withCache && sanCache.has(str)) {
    return sanCache.get(str);
  }
  let v = removeAccents((str || '').trim().toLowerCase());
  v = v.replace(/[^a-z0-9]/ig, ' ');

  if (withCache) {
    sanCache.set(str, v);
  }
  return v;
}


export function useSearcher(list, filterFn) {
  const [search, setSearch] = useState('');


  const filtered = useMemo(() => {
    const s = sanitizeString(search);
    if (!s) {
      return list;
    }
    const l = list.filter((item) => filterFn(item, s, sanitizeString));
    return l
  }, [list, search, filterFn]);

  return {
    list: filtered,
    search,
    setSearch,
  };
}


export function useAdvancedSearcher(list, {
  searchStrFn
}) {
  const [search, setSearch] = useState('');

  if (!searchStrFn) {
    searchStrFn = (item) => (item.label ?? '') + ' ' + (item.searchString ?? '');
  }
  const listSearchString = useMemo(() => {
    return list.map((str) => sanitizeString(searchStrFn(str), true).split(' ').filter((s) => s.length));
  }, [list, searchStrFn]);


  const searchIdx = useMemo(() => {
    if (!search) {
      return null;
    }

    const s = sanitizeString(search, true).split(' ').filter((s) => s.length);
    const idx = {}
    for (let i = 0; i < list.length; i++) {
      const opt = listSearchString[i];
      let cov = 0;
      for (let ii = 0; ii < s.length; ii++) {
        const ss = s[ii];
        let lcov = 0, nfounds = 0;
        for (let iii = 0; iii < opt.length; iii++) {
          if (opt[iii].startsWith(ss)) {
            lcov += (ss.length / opt[iii].length) * 1 / (iii + 1);
            nfounds++;
          }
        }
        if (lcov > 0) {
          cov += lcov / nfounds;
        } else {
          cov = 0;
          break;
        }
      }
      idx[i] = cov;
    }
    return idx;
  }, [list, search, listSearchString]);


  const filtered = useMemo(() => {
    if (!searchIdx) {
      return list;
    }
    const out = [];
    for (let i = 0; i < list.length; i++) {
      if (searchIdx[i] > 0) {
        out.push({ ...list[i], $score: searchIdx[i] });
      }
    }
    out.sort((a, b) => {
      return (b.$score || 0) - (a.$score || 0);
    });
    return out;
  }, [list, searchIdx]);



  return {
    list: filtered,
    search,
    setSearch,
  };
}

export function useExtendedPersistentSearcher(key, list, filter) {
  const [filters, setFilters] = usePersistentState(key + ".search", { values: [] });
  const sets = useMemo(() => {
    const out = {}
    for (let k in filters) {
      if (filters[k] instanceof Array) {
        out[k] = new Set(filters[k]);
      }
    }
    return out;

  }, [filters]);


  const check = useCallback((name, value) => {
    const f = sets[name];
    const rf = sets[name + '_excluded'];
    if (rf?.size && rf.has('!' + value)) {
      return false;
    }
    if (f?.size) {
      return f.has(value);
    }
    return true;
  }, [sets]);


  const filtered = useMemo(() => {

    const words = sanitizeString((filters?.text || []).join(' ')).split(/\s+/)

    const fulltextSearch = (str) => {
      const text = sanitizeString(str);
      for (let i = 0; i < words.length; i++)
        if (!text.includes(words[i])) return false;
      return true;
    }
    const out = [];

    const filtersParams = {
      ...filters,
      check,
      // check(name, value) {
      //   const f = filters[name];
      //   const rf = filters[name + '_excluded'];
      //   if (rf?.length && rf.includes(value)) {
      //     return false;
      //   }
      //   if (f?.length) {
      //     return f.includes(value);
      //   }
      //   return true;
      // }
    }
    for (let i in list) {
      const item = list[i];
      if (filter(item, filtersParams, fulltextSearch)) {
        out.push(item);
      }
    }
    // const l = list.filter((item) => filter(item, filters, fulltextSearch));
    return out
  }, [list, filters, check, filter]);

  const outputFilters = useMemo(() => ({
    ...filters,
    check,
  }), [filters, check]);

  return {
    list: filtered,
    filters: outputFilters,
    check,
    setFilters,
    addFilter: useCallback((type, value) => {
      const values = (filters.values || [])
      if (!values.includes(value)) {
        setFilters({
          ...filters,
          [type]: [...filters[type] || [], value],
          values: [...values, value]
        });
      }
    }, [filters, setFilters]),
  };
}

export function createSearchOptions(list, fn, params = {}) {
  if (!list?.length) return []
  const m = new Map()
  fn = fn || ((o) => o)
  for (let i = 0; i < list.length; i++) {
    const item = fn(list[i])
    if (item && (item.id || item.value) && !m.has(item.id)) {
      if (params) {
        item.params = params
      }
      if (params.title) {
        item.searchString = (item.searchString || '') + ' ' + params.title
      }
      m.set((item.id || item.value), item)
    }
  }
  return Array.from(m.values())
}

export function useSearchOptionsMemo(list, fn, deps = []) {
  return useMemo(() => createSearchOptions(list, fn), [list, fn, ...deps])
}

export function useSearchOptionsListMemo({ name, options, fn, color, optionColor, tagColor, icon, title, invertable }, deps = []) {
  return useMemo(() => {
    const params = {
      title,
      name,
      color,
      tagColor,
      optionColor,
      icon,
      invertable
    }

    return createSearchOptions(options, fn, params)
  }, [name, options, fn, color, tagColor, icon, ...deps])
}


export function useSearch({
  name = "",

  items = [],
  filterFn = () => true,


  agencies = [],
  projects = [],
  resources = [],
  salesmen = [],
  tasks = [],
  prestations = [],
  orders = [],
  thirdparties = [],

  enableProjectTypes = false,
  enableProjectInvoicingModes = false,

  options = [],
}) {
  const [customFilters, setCustomFilters] = usePersistentState(name + ".customFilters", []);
  const { list: filtered, filters, setFilters, addFilter } = useExtendedPersistentSearcher(name, items, filterFn);

  const customFiltersOpts = useSearchOptionsListMemo({
    name: 'customFilters',
    options: customFilters,
    fn: useCallback((o) => ({ value: o.id, label: o.label }), []),
    color: 'grey',
    icon: mdiFilterOutline,
  })

  const agenciesOpts = useSearchOptionsListMemo({
    name: 'agencies',
    options: agencies,
    fn: useCallback((o) => ({
      value: o.id,
      label: o.name || o.label
    }), []),
    color: 'blue',
    icon: mdiStoreOutline,
  });



  const projectsOpts = useSearchOptionsListMemo({
    name: 'projects',
    options: projects,
    fn: useCallback((o) => ({
      value: o.id,
      label: (o.code ? `${ProjectShortCode({ value: o.code })} - ${o.name}` : o.name) || o.label,
      searchString: o.code
    }), []),
    color: 'green',
    icon: mdiSelectGroup,
  });




  const resourcesOpts = useSearchOptionsListMemo({
    name: 'resources',
    options: resources,
    fn: useCallback((o) => ({
      value: o.id,
      label: o.name || o.label,
      searchString: o.code
    }), []),
    color: 'purple',
    icon: mdiAccountHardHatOutline,
    invertable: true
  });

  const tasksOpts = useSearchOptionsListMemo({
    name: 'tasks',
    options: tasks,
    fn: useCallback((o) => ({
      value: o.id,
      label: `${o.name || o.label}`
    }), []),
    color: 'orange',
    icon: mdiDrawing,
  });

  const prestationsOpts = useSearchOptionsListMemo({
    name: 'prestations',
    options: prestations,
    fn: useCallback((o) => ({
      value: o.id,
      label: (o.code ? (o.code + ' - ' + o.name) : o.name) || o.label,
      searchString: o.code
    }), []),
    color: '#d48806',
    icon: mdiProgressWrench,
    invertable: true
  });

  const prestationWillcardsOpts = useSearchOptionsListMemo({
    name: 'prestations',
    options: prestations?.length ? [
      { id: "PRESTDIV*", label: "Tous les PRESTDIV", searchString: "PRESTDIV" },
      { id: "MOST*", label: "Tous les MOST", searchString: "MOST" },
      { id: "MOTEC*", label: "Tous les MOTEC", searchString: "MOTEC" },
    ] : [],
    fn: useCallback((o) => ({
      value: o.id,
      label: o.label,
      searchString: o.searchString
    }), []),
    color: '#d48806',
    icon: mdiProgressWrench,
  });

  const ordersOpts = useSearchOptionsListMemo({
    name: 'orders',
    options: orders,
    fn: useCallback((o) => ({ value: o.id, label: o.name || o.label }), []),
    color: 'lime',
    optionColor: "#7CB305",
    icon: mdiAnimationOutline,
  });

  const thirdpartiesOpts = useSearchOptionsListMemo({
    name: 'thirdparties',
    options: thirdparties,
    fn: useCallback((o) => ({ value: o.id, label: o.name }), []),
    color: 'volcano',
    icon: mdiAccountBox,
  });

  const salesmenOpts = useSearchOptionsListMemo({
    name: 'salesmen',
    options: salesmen,
    fn: useCallback((o) => ({
      value: 'salesman-' + o.commercial_code,
      label: `${o.commercial_code} - ${o.name}`,
      searchString: `${o.commercial_code}`,
    }), []),
    color: 'volcano',
    optionColor: "#D4380D",
    icon: mdiAccountBox,
    invertable: true
  });



  const projectTypesOpts = useSearchOptionsListMemo({
    name: 'projectTypes',
    options: enableProjectTypes ? projectTypes : null,
    fn: useCallback((o) => ({
      value: "projectType-" + o.code,
      label: o.code + ' - ' + o.label,
    }), []),
    icon: mdiAlphaT,
    tagColor: "cyan",
    color: "#08979C",
    invertable: true
  });

  const projectInvoicingModesOpts = useSearchOptionsListMemo({
    name: 'projectInvoicingModes',
    options: enableProjectInvoicingModes ? projectInvoicingModes : null,
    fn: useCallback((o) => ({
      value: "projectInvoicingMode-" + o.code,
      label: o.code + ' - ' + o.label,
    }), []),
    icon: mdiCurrencyEur,
    color: "magenta",
    invertable: true
  });

  const computedOptions = useMemo(() => {
    return [
      ...customFiltersOpts,
      ...agenciesOpts,
      ...projectsOpts,
      ...resourcesOpts,
      ...tasksOpts,
      ...prestationsOpts,
      ...prestationWillcardsOpts,
      ...ordersOpts,
      ...thirdpartiesOpts,
      ...salesmenOpts,
      ...projectTypesOpts,
      ...projectInvoicingModesOpts,
      ...options
    ]
  }, [customFiltersOpts, agenciesOpts, projectsOpts, resourcesOpts, tasksOpts, prestationsOpts, prestationWillcardsOpts, ordersOpts, thirdpartiesOpts, salesmenOpts, projectTypesOpts, projectInvoicingModesOpts, options]);



  return {
    options: computedOptions,
    setCustomFilters,
    customFilters,
    filters,
    setFilters,
    addFilter,
    list: filtered,
  }
}