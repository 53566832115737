import { Checkbox, Flex, Form } from "antd";
import { useMemo } from "react";
import { useResources } from "../../hooks/resources";
import { useSearchOptionsMemo } from "../../hooks/search";
import Search from "../search/Search";


export default function ResourceSubscriptionsForm({ form }) {

  const [resources] = useResources();
  const salesmen = useMemo(() => resources.filter(({ commercial_code }) => commercial_code, [resources]), [resources]);
  const salesmanOptions = useSearchOptionsMemo(salesmen, ({ commercial_code, name }) => {
    return {
      value: "fq-" + commercial_code,
      label: commercial_code + " - " + name,
      type: "salesman"
    }
  });



  const options = [
    // { label: "Affaires" },
    // { value: "project:comment", label: "Un commentaire a été ajouté" },
    { label: "Taches" },
    { value: "task:created", label: "Une tâche est créée" },
    { value: "task:completed", label: "Une tâche est terminée" },
    // { value: "task:comment", label: "Un commentaire a été ajouté" },
    { label: "Créneaux" },
    { value: "slot:created", label: "Un créneau est créé" },
    { value: "slot:confirmed", label: "Un créneau est confirmé" },
    { value: "slot:canceled", label: "Un créneau est annulé" },
    { value: "slot:terminated", label: "Un créneau est terminé" },
    // { value: "slot:comment", label: "Un commentaire a été ajouté" },
  ]
  // const subs = Form.useWatch(["subscription", "subjects"], { form });
  // console.log(subs);

  return <>
    <Form.Item label="Sujets" name={["subscription", "subjects"]} layout="vertical">
      <Checkbox.Group>
        <Flex vertical>
          {options.map(({ value, label }) => {
            return value
              ? <Checkbox key={value} value={value}>{label}</Checkbox>
              : <b key={label} style={{ marginTop: 10 }}>{label}</b>
          })}
        </Flex>
      </Checkbox.Group>
    </Form.Item>


    <Form.Item name={["subscription", "filters"]} label="Filtre" layout="vertical" >
      <Search name="resource.subscription.filters"
        resources={resources}
        enableProjectInvoicingModes={true}
        options={[...salesmanOptions]}
      />
    </Form.Item>


  </>
} 
