import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DocumentList from "../../components/documents/DocumentList";
import { getProjectDocuments } from "../../services/api/projects";
import { loaderWrap } from "../../services/loader";


export default function ProjectDocuments() {
  const { projectId } = useParams();

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    loaderWrap(
      getProjectDocuments(projectId)
        .then(setDocuments)
    )
  }, [projectId]);

  return (
    <div>
      <DocumentList documents={documents} />
    </div>
  );
}