
import './scss/app.scss';


import { Authenticator, translations } from '@aws-amplify/ui-react';

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';

import { I18n } from 'aws-amplify/utils';
import AppRouter from './AppRouter';
import { AppLoader } from './components/app/AppLoader';

I18n.putVocabularies(translations);
I18n.setLanguage('fr');


function App() {

  return <div className="App">
    <AppRouter />
    <AppLoader />
  </div>
  return (
    <Authenticator
      hideSignUp={true}
      components={{
        Header: () => <div style={{ marginTop: "10em" }}></div>,
      }}
    >
      {({ signOut, user }) => (
        <div className="App">
          <AppRouter />
          <AppLoader />
        </div>
      )}
    </Authenticator>
  );
}

export default App;
