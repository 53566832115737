import { HistoryOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { useState } from "react";
import { useAuth } from "../../hooks/auth";
import ChangeLog from "./ChangeLog";



export default function ChangeLogDrawer({ changes }) {
  const [open, setOpen] = useState(false);
  const { can } = useAuth();
  if (!can('changelog.view')) return null;
  return <>
    <Button onClick={() => setOpen(true)} icon={<HistoryOutlined />} type="text"></Button>
    <Drawer open={open} onClose={() => setOpen(false)}
      size="large">
      <h4 style={{ marginBottom: '2em', textAlign: 'center' }}>
        <HistoryOutlined />  Historique</h4>
      <ChangeLog changes={changes} />
    </Drawer>
  </>
}