import { Button, Flex, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAdvancedSearcher } from "../../hooks/search";
import { indexBy } from "../../lib/utils";
import { SearchCustomFiltersModal } from "./SearchCustomFiltersModal";
import { SearchOptionRenderer } from "./SearchOptionRenderer";
import { SearchSavedFilters } from "./SearchSavedFilters";
import { SearchTagRenderer } from "./SearchTagRenderer";




export default function Search({

  options = [],

  values: inputValues = {},
  value,


  onChange,
  onFiltersChange,

  dropdownRender,
  iconAndColorProps,

  customFilters = [],
  setCustomFilters,

  allowRevert = false,

  name = ""
}) {
  const [values, setValues] = useState([]);
  const [customFiltersOpen, setCustomFiltersOpen] = useState(false);
  // const [customFilters, setCustomFilters] = usePersistentState(name + ".customFilters", []);
  const { list: filteredOptions, search, setSearch } = useAdvancedSearcher(options, { values: [] });

  useEffect(() => {
    let input = value || inputValues;
    input = (Array.isArray(input) ? input : input?.values) || [];
    if (JSON.stringify(input)
      !== JSON.stringify(values)) {
      // console.log('change', inputValues.values, values);
      setValues(input);
    }
  }, [inputValues, values, value]);




  const defaultsProjectedFilters = useMemo(() => {
    const def = {}
    for (let i = 0; i < options.length; i++) {
      const { name, invertable } = options[i]?.params;
      if (!def[name]) {
        def[name] = [];
      }
      if (invertable && !def[name + '_excluded']) {
        def[name + '_excluded'] = [];
      }
    }
    return def;
  }, [options]);

  const optionsIdx = useMemo(() => indexBy(options, "value"), [options]);

  const onChangeHandler = (values) => {

    const writeToFilter = (filters, value) => {
      const val = value.startsWith('!') ? value.slice(1) : value;
      const { params } = optionsIdx[val] || { params: { name: 'text' } };
      const filterName = params.name;
      if (filterName === 'customFilters') {
        const cf = customFilters?.find((f) => f.id === value);
        if (cf) {
          cf.values.forEach(v => writeToFilter(filters, v));
        }
      } else {

        const inverted = value.startsWith('!');
        const fn = inverted ? filterName + '_excluded' : filterName;
        if (!filters[fn]) {
          filters[fn] = [];
        }

        if (value.endsWith('*')) {
          const prefix = value.slice(0, -1);
          filters[fn].push(...options.filter(o => o.params?.name === fn && o.searchString.startsWith(prefix)).map(o => o.value));
        }
        filters[fn].push(value);
      }
      return filters;
    }
    const filters = values.reduce((filters, value) => {
      return writeToFilter(filters, value);
    }, {
      values,
      ...defaultsProjectedFilters,
      text: []
    });
    setSearch('');
    onChange && onChange(values);
    onFiltersChange && onFiltersChange(filters);
  }

  const onInvert = (value) => {
    if (!allowRevert) {
      return;
    }
    const val = value.startsWith('!') ? value.slice(1) : value;

    const { params } = optionsIdx[val] || { params: {} };
    if (!params.invertable || params?.name === 'customFilters' || value.endsWith('*')) {
      return; // Do nothing for custom filters
    }

    const newVal = value.startsWith('!') ? value.slice(1) : '!' + value;

    const newValues = values.map(v => v === value ? newVal : v);
    onChangeHandler(newValues);
  }

  if (name) {
    dropdownRender = (menu) => {
      return <>
        {menu}
        <div style={{ borderTop: '1px solid #eee', marginTop: '5px' }}>
          <Flex align="center" gap="small">
            <div style={{ flex: 1 }}>
              <SearchSavedFilters name={name} values={values} onSelect={(values) => {
                onChangeHandler(values);
              }} />
            </div>
            {setCustomFilters &&
              <Button size="small" onClick={() => setCustomFiltersOpen(true)}>Filtres personnalisés</Button>}
          </Flex>

        </div>
        {/* {dropdownRender ? dropdownRender(menu) : menu} */}
      </>
    }
  }

  return <>
    <Select
      style={{ width: '100%' }}
      mode="tags"

      optionRender={({ data }) => <SearchOptionRenderer data={data} />}
      tagRender={({ label, value, closable, onClose }) => {
        const inverted = value?.startsWith('!');
        const v = inverted ? value.slice(1) : value;
        const { params, label: displayLabel } = optionsIdx[v] || { label, params: {} };
        return <SearchTagRenderer
          label={displayLabel}
          params={params}
          closable={closable}
          inverted={inverted}
          onClose={onClose}
          onInvert={() => onInvert(value)}
        />
      }}
      // SearchTagRenderer({ label, params: optionsIdx[value]?.params, value, closable, onClose, iconAndColorProps })}
      placeholder="Rechercher..."
      options={filteredOptions}
      allowClear
      value={values}
      filterOption={false}
      searchValue={search}
      // filterSort={filterSort}
      onChange={onChangeHandler}
      onSearch={(search) => setSearch(search)}
      dropdownRender={dropdownRender}
      onDropdownVisibleChange={(open) => {
        if (!open) {
          setSearch('');
        }
      }}
    />
    {setCustomFilters &&
      <SearchCustomFiltersModal name={name}
        open={customFiltersOpen}
        onClose={() => setCustomFiltersOpen(false)}
        options={options}
        customFilters={customFilters}
        onCustomFiltersChange={setCustomFilters}
        allowRevert={allowRevert}
      />}
  </>
}