import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PrestationTypeLabel from '../../components/prestations/PrestationTypeLabel';
import { prestationsTypes, usePrestations } from '../../hooks/prestations';
import { useSearcher } from '../../hooks/search';

export default function PrestationsList() {
  const [prestations] = usePrestations();
  const { list, search, setSearch } = useSearcher(
    prestations,
    useCallback((o, search, norm) => norm(o.name + ' ' + o.code).includes(search), []),
  );
  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };

  return (<>
    <div className="box">
      <Space.Compact gap="small" align='center' style={{ width: '100%' }} >
        <Input size="large" placeholder="Rechercher une prestation..."
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button size="large" type="primary" onClick={() => open("*")}>Créer une prestation</Button>
      </Space.Compact>
    </div>

    <div className="box-paddingless">
      <Table
        dataSource={list}
        rowKey={(record) => record.id}
        pagination={
          {
            position: ['bottomCenter'],
          }
        }
        onRow={(record, rowIndex) => {
          return {
            onClick: () => open(record.id), // click row
          };
        }}
        columns={[
          {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 100,
            sorter: (a, b) => String(a.code).localeCompare(b.code),
            // render: (text, record) => <ResourceIcon type={record.type} />,
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 150,
            render: (text, record) => <PrestationTypeLabel type={record.type} />,
            filters: prestationsTypes.map(({ label, value }) => ({ text: label, value })),
            onFilter: (value, record) => record.type === value,
          },
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => String(a.name).localeCompare(b.name),
          },
        ]} />
    </div>
  </>);
}
