import { Select } from "antd"
import { useMemo } from "react"
import { usePickingPools } from "../../hooks/pickingpools"


export default function PickingPoolInput({ value, name, placeholder, allowClear, variant, valueFilter, onChange }) {
  const [items] = usePickingPools()


  const filteredItems = useMemo(() => {
    if (valueFilter) {
      return items.filter(valueFilter)
    }
    return items || []
  }, [items, valueFilter])

  return <Select
    showSearch
    allowClear={allowClear}
    placeholder={placeholder || 'Sélectionner un pool'}
    optionFilterProp="children"
    filterOption={(input, option) => `${option?.code ?? ''} ${option?.name ?? ''}`.toLowerCase().includes(input.toLowerCase())}
    filterSort={(optionA, optionB) =>
      (optionA?.code ?? '').toLowerCase().localeCompare((optionB?.code ?? '').toLowerCase())
    }
    fieldNames={{
      label: "name",
      value: "id",
    }}
    variant={variant}
    value={value}
    onChange={onChange}
    options={filteredItems}
  />
}