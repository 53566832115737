import clsx from 'clsx';
import { useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';

import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from 'antd';
import Style from './Breadcrumbs.module.scss';

export function Breadcrumbs({ routes }) {
  const navigate = useNavigate();
  const breadcrumbs = useReactRouterBreadcrumbs(routes, { disableDefaults: true });

  const ref = useRef();

  const prev = () => {
    navigate(-1)
  }
  const canGoBack = window.history.state.idx !== 0;

  return (
    <h3 ref={ref} className={clsx(Style.breadcrumbs)}>
      <ul className="breadcrumb">
        {canGoBack && <li className="breadcrumb-item">
          <div style={{ marginTop: "-2px" }}>
            <Button icon={<Icon path={mdiChevronLeft} size={1} />} onClick={() => prev()} />
          </div>
        </li>}
        {breadcrumbs.map(({ match, breadcrumb }, i) => (
          <li className="breadcrumb-item" key={match.pathname}>
            {
              (i < breadcrumbs.length - 1)
                ? (
                  <NavLink to={match.pathname}>
                    {breadcrumb}
                  </NavLink>
                )
                : breadcrumb
            }

          </li>
        ))}
      </ul>
    </h3>
  );
}
