
import { Button, Form, Input, InputNumber, Switch, Typography, message } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ControlBar } from '../../components/controlbar/ControlBar';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import { WarehouseInput } from '../../components/warehouses/WarehouseInput';
import { usePickingPool } from '../../hooks/pickingpools';
import { useWarehouses } from '../../hooks/warehouses';

// import { SubjectTimeline } from '../../components/dataviz/SubjectTimeline';
// import { ControlBar } from '../../components/controlbar/ControlBar';

export default function PickingPoolForm() {
  const navigate = useNavigate();
  const { pickingPoolId } = useParams();
  const [form] = Form.useForm();

  const {
    pickingPool, savePickingPool, deletePickingPool,
  } = usePickingPool(pickingPoolId || '');

  const [warehouses] = useWarehouses();

  const isMain = Form.useWatch('is_main', form);


  useEffect(() => {
    if (pickingPool) {
      const { capacities } = pickingPool;
      const keys = Object.keys(capacities || {});
      form.setFieldsValue({
        ...pickingPool,
        warehouse: keys[0],
        capacity: capacities[keys[0]],
      });
    }
  }, [form, pickingPool]);


  if (!pickingPool) {
    return <div>Not found</div>;
  }



  const onFinish = (values) => {
    (async () => {
      try {
        let capacities = values.capacities;
        if (values.is_main) {
          capacities = {
            [values.warehouse]: values.capacity
          }
        }

        const item = await savePickingPool({
          ...pickingPool,
          ...values,
          capacities,
        });
        message.success('Enregistré');
        if (item) {
          navigate('/pickingPools');
        }
      } catch (err) {
        message.error(`Erreur: ${err}`);
      }
    })();
  };

  const onDelete = () => {
    if (pickingPoolId) {
      (async () => {
        await deletePickingPool();
        message.success('Supprimé');
        setTimeout(() => {
          navigate('/pickingPools');
        }, 1000);
      })();
    }
  };


  return (
    <Form form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ flex: 1 }}
      labelWrap
      onFinish={onFinish}
    >
      <fieldset className="box">
        <legend>Informations</legend>
        <Form.Item name="name" label="Nom" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="is_main" label="Pool d'entrepôt" help="Indique que ce pool est un pool principal pour l'entrepôt">
          <Switch />
        </Form.Item>
        {isMain && (<>
          <Form.Item name="warehouse" label="Dépôt" rules={[{ required: true }]}>
            <WarehouseInput />
          </Form.Item>
          <Form.Item name="capacity" label="Capacité" rules={[{ required: true }]} help="Nombre de ligne de commande préparé par jour">
            <InputNumber />
          </Form.Item>
        </>)}
      </fieldset>
      {!isMain && (
        <fieldset className='box'>
          <legend>Capacités par entrepôts</legend>
          <Typography.Paragraph>
            <blockquote>Indiquez la limite réservé pour ce pool du nombre de lignes de commandes préparées par jour pour chaque entrepôt</blockquote>
          </Typography.Paragraph>
          {(warehouses || []).map((warehouse) => (
            <Form.Item key={warehouse.id}
              name={["capacities", warehouse.id]}
              label={warehouse.name}
            // help={`(max ${warehouse.capacity || 0})`} 
            >
              <InputNumber />
            </Form.Item>
          ))}
        </fieldset>
      )}

      <ControlBar>
        <DeleteButton onDelete={onDelete} />
        <div>
          <Button type="primary" size='large'
            onClick={() => form.submit()}>Enregistrer</Button>
        </div>
      </ControlBar>
    </Form>
  );
}
