import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday)
dayjs.extend(isoWeek)

// Get the fraction of a day, week, month, or year based on current time
function getWorkingPeriodFraction(period) {
  switch (period) {
    case 'day':
      const fromHour = 8
      const toHour = 18
      const duration = toHour - fromHour
      if (dayjs().isoWeekday() > 5) {
        return 0
      }
      return 1 - (((dayjs().hour() - fromHour)) / duration);
    case 'week': {
      const dayOfWeek = dayjs().isoWeekday()
      if (dayOfWeek > 5) {
        return 0
      }
      const f = 1 - ((dayOfWeek - 1) / 5)
      return f
    }
    default:
      throw new Error(`Unsupported period: ${period}`);
  }
}

// Adjust capacity bases on fraction of day, week, month, or year
export function adjustCapacity(dailyCapacity, period) {
  switch (period) {
    case 'day':
      return Math.max(0, Math.round(dailyCapacity * getWorkingPeriodFraction('day')))
    case 'week':
      return Math.max(0, Math.round(dailyCapacity * 5 * getWorkingPeriodFraction('week')) - dailyCapacity + adjustCapacity(dailyCapacity, 'day'))
    default:
      throw new Error(`Unsupported period: ${period}`);
  }
}
