import { useEffect, useState } from "react";
import { addOrderComment, listOrderComments } from "../../services/api/orders";
import MessagesView from "../messages/MessagesView";


export default function OrderMessagesView({ orderId }) {
  const [messages, setMessages] = useState([])
  const [reloadCounter, setReload] = useState(0)

  useEffect(() => {
    if (!orderId) return
    (async () => {
      const messages = await listOrderComments(orderId)
      setMessages(messages)
    })()
  }, [reloadCounter, orderId])

  const onSend = async (content) => {
    if (!orderId) return
    await addOrderComment(orderId, content)
    setReload(reloadCounter + 1)
  }

  return <MessagesView messages={messages} onSend={onSend} />
}