
import { Select } from "antd";
import { useMemo } from "react";
import { usePrestations } from "../../hooks/prestations";
import PrestationIcon from "./PrestationIcon";

export function PrestationInput({ value, name, placeholder, valueFilter, disabled = false, onChange }) {
  const [items] = usePrestations()


  const filteredItems = useMemo(() => {
    if (valueFilter) {
      return items.filter(valueFilter)
    }
    return items || []
  }, [items, valueFilter])

  const options = useMemo(() => {
    return filteredItems.map((p) => ({
      value: p.id,
      name: p.code + ' ' + p.name,
      label: <div style={{ display: 'flex', alignItems: 'center' }}>
        <PrestationIcon prestation={p} />
        <span style={{ marginLeft: '10px' }}>{p.code} - {p.name}</span>
      </div>
    }))
  }, [filteredItems])

  return <Select
    showSearch
    disabled={disabled}
    placeholder={placeholder || 'Sélectionner une prestation'}
    optionFilterProp="children"
    filterOption={(input, option) => ((option?.name ?? '') + ' ' + (option?.code ?? '')).toLowerCase().includes(input.toLowerCase())}
    filterSort={(optionA, optionB) =>
      (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
    }
    // fieldNames={}
    value={value}
    onChange={onChange}
    options={options}
  />
}