import { Descriptions, Flex, Table } from "antd";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import OrderItemStatus from "../../components/orders/OrderItemStatus";
import OrderMessagesView from "../../components/orders/OrderMessagesView";
import OrderShippingModeLabel from "../../components/orders/OrderShippingModeLabel";
import OrderStatus from "../../components/orders/OrderStatus";
import ProjectLabel from "../../components/projects/ProjectLabel";
import SalesmanLabel from "../../components/resources/SalesmanLabel";
import { useOrder } from "../../hooks/orders";
import { useWarehouses } from "../../hooks/warehouses";
import { formatCurrency, formatDate } from "../../lib/format";
import { indexBy } from "../../lib/utils";
import AgencyLabel from "../agencies/AgencyLabel";


export default function OrderView() {
  const { orderId } = useParams();

  const { order } = useOrder(orderId);
  const [warehouses] = useWarehouses();
  const warehousesById = useMemo(() => indexBy(warehouses, 'id'), [warehouses]);

  // console.log(order);
  return (<>
    <Flex gap="large" style={{ padding: "1em" }}>
      <Descriptions bordered column={2} style={{ backgroundColor: 'rgba(255,255,255,.8)' }} items={[
        {
          key: "order_number",
          label: "Numéro de commande",
          children: order?.order_number,
        },
        {
          key: "project_id",
          label: "Affaire",
          children: <ProjectLabel id={order?.project_id} />,
        },
        {
          key: "date",
          label: "Date de création",
          children: formatDate(order?.date),
        },
        {
          key: "shipping_date",
          label: "Date de livraison souhaité",
          children: formatDate(order?.shipping_date),
        },
        {
          key: "customer_code",
          label: "Client",
          children: order?.third_party_name + ' (' + order?.customer_code + ')',
        },
        {
          key: "agency_id",
          label: "Agence",
          children: <AgencyLabel id={order?.agency_id} />,
        },
        {
          key: "salesman_code",
          label: "Commercial",
          children: <SalesmanLabel code={order?.salesman_code} showCode />,
        },
        {
          key: "status",
          label: "Statut",
          children: <OrderStatus value={order?.status} />,
        },
        {
          key: "shipping_mode",
          label: "Mode d'expédition",
          children: <OrderShippingModeLabel value={order?.shipping_mode} />,
        },
      ]} />
      <div style={{ minWidth: 250 }}>
        <OrderMessagesView orderId={orderId} />
      </div>
    </Flex>
    {/* <Flex gap="large">
      <div className="box" style={{ flex: 1 }}>
        <Form layout="horizontal">
          <Form.Item label="Numéro de commande">
            {order?.order_number}
          </Form.Item>
          <Form.Item label="Affaire">
            <ProjectLabel id={order?.project_id} />
          </Form.Item>
          <Form.Item label="Date de création">
            {formatDate(order?.date)}
          </Form.Item>
          <Form.Item label="Date de livraison souhaité">
            {formatDate(order?.shipping_date)}
          </Form.Item>
          <Form.Item label="Client">
            {order?.customer_code}
          </Form.Item>
          <Form.Item label="Agence">
            <AgencyLabel id={order?.agency_id} />
          </Form.Item>
          <Form.Item label="Commercial">
            {order?.salesman_code}
          </Form.Item>
          <Form.Item label="Statut">
            <OrderStatus value={order?.status} />
          </Form.Item>
          <Form.Item label="Mode d'expédition">
            <OrderShippingModeLabel value={order?.shipping_mode} />
          </Form.Item>

        </Form>
      </div>
     
    </Flex> */}
    <div className="box-paddingless">
      <Table dataSource={order?.items} rowKey="id"
        pagination={{ position: ['bottomCenter'] }}
        columns={[
          // {
          //   title: 'ID',
          //   dataIndex: 'id',
          //   key: 'id',
          // },
          {
            title: 'N° ligne',
            dataIndex: 'line_number',
            key: 'line_number',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <OrderItemStatus value={status} />,
          },
          {
            title: 'Type',
            dataIndex: 'item_type',
            key: 'item_type',
            // filters: Object.entries(orderTypes).map(([value, { label, color }]) => ({ text: label, value })),
            onFilter: (value, record) => record.item_type === value,
            filters: [
              { text: 'Marchandise', value: 'MAR' },
              { text: 'Nommenclature', value: 'NOM' },
              { text: 'Prestation', value: 'PRE' },
            ],
          },
          {
            title: 'Code',
            dataIndex: 'item_code',
            key: 'item_code',
          },
          {
            title: 'Désignation',
            dataIndex: 'label',
            key: 'label',
          },
          {
            title: 'Prix unitaire',
            dataIndex: 'unit_price',
            key: 'unit_price',
            render: (price) => formatCurrency(price),
          },
          {
            title: 'Quantité',
            dataIndex: 'quantity',
            key: 'quantity',
          },
          {
            title: 'Quantité Restante',
            dataIndex: 'remain_quantity',
            key: 'remain_quantity',
            sorter: (a, b) => a.remain_quantity - b.remain_quantity,
          },
          {
            title: 'Unité',
            dataIndex: 'quantity_unit',
            key: 'quantity_unit',
          },
          {
            title: 'Prix total',
            dataIndex: 'total_price',
            key: 'total_price',
            render: (price) => formatCurrency(price),
          },
          {
            title: 'Tâche',
            dataIndex: 'task_id',
            key: 'task_id',
          },
          {
            title: 'Dépot',
            dataIndex: 'warehouse',
            key: 'warehouse',
            render: (warehouse) => {
              return warehousesById['wh-' + warehouse]?.name;
            }
          },
        ]} />
    </div>
  </>);
}