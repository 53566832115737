
import { usePrestation } from "../../hooks/prestations";
import Link from "../link/Link";


export default function PrestationLabel({ id, withCode = false }) {
  const { prestation } = usePrestation(id);
  let label = prestation?.name;
  if (withCode) {
    label = `${prestation?.code} - ${label}`;
  }
  return <Link to={"/prestations/" + id}>{label}</Link>;
}