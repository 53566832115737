import { Affix } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import Style from './ControlBar.module.scss';

export function ControlBar({ children }) {
  const [sticked, setSticked] = useState(false);
  return (
    <Affix offsetBottom={sticked ? -5 : -10} onChange={(affixed) => setSticked(affixed)}
      target={() => document.getElementById("app-main")}>
      <div className={clsx(Style.controlbar, 'box', sticked && Style.sticked)}>
        {children}
      </div>
    </Affix>
  );
}
