
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Scheduler, { CellUnit, DATE_FORMAT } from '../../components/scheduler/Scheduler';
import SchedulerData from '../../components/scheduler/SchedulerData';
import ViewTypes from '../../components/scheduler/ViewType';
import { slotCancelReasons, useSlotsValidator } from "../../hooks/slots";
import { indexBy } from "../../lib/utils";

import '../../components/scheduler/css/style.css';
import Styles from './PlanningView.module.scss';

import dayjs from 'dayjs';

import { Badge, Button, DatePicker, Divider, Flex, Form, Modal, Popover, Progress, Radio, Segmented, Skeleton, Space, Tooltip, message } from "antd";


import ResourceSlotEventItem from "../../components/planning/ResourceSlotEventItem";


import { FullscreenExitOutlined, FullscreenOutlined, LeftOutlined, ReloadOutlined, RightOutlined } from "@ant-design/icons";
import { PlanningErrorItem } from "../../components/planning/PlanningErrorItem";
import PlanningSlotHeaderItemRenderer from "../../components/planning/PlanningSlotHeaderItemRenderer";
import PlanningTaskSlotForm from "../../components/planning/PlanningTaskSlotForm";
import ResourceSlotMilestoneItem from "../../components/planning/ResourceSlotMilestoneItem";
import SlotPopover from "../../components/planning/SlotPopover";
import TaskSlotEventItem from "../../components/planning/TaskSlotEventItem";
import { endWorkTime, roundToEndWorkTimeIfNeeded, roundToStartWorkTimeIfNeeded, startWorkTime } from "../../components/planning/utils";
import { useByProjects, useByResources, useFilteredDatastore, useMetrics, usePlanningDatastore } from "./data";

import clsx from "clsx";
import PlanningProjectsOverview from "../../components/planning/PlanningProjectsOverview";
import PlanningResourceSlotForm from "../../components/planning/PlanningResourceSlotForm";
import { PlanningSlotComplete } from "../../components/planning/PlanningSlotComplete";
import { isNonWorkingTime } from "../../components/scheduler/behaviors";
import Search from "../../components/search/Search";
import { useAuth } from "../../hooks/auth";
import { useElementDimensions, useWindowDimensions } from "../../hooks/dom";
import { useSearch } from "../../hooks/search";
import { useSetting } from "../../hooks/settings";
import { getNonWorkingDays } from "../../services/api/slots";
import { createTask } from "../../services/api/tasks";
import { loaderWrap } from "../../services/loader";
import { notifyError } from "../../services/notification";
import PlanningNewTaskModal from "./PlanningNewTaskModal";


const defaultPayload = {
  resources: [],
  events: [],
}


function Option({ label, options, value, onChange }) {

  const optionsWithHelp = useMemo(() => options.map(o => {
    return {
      ...o,
      label: o.help ? <Tooltip title={o.help}>{o.label}</Tooltip> : o.label
    }
  }), [options])

  // return <Space.Compact size="small" >
  //   <Button color="cyan" variant="filled"  >
  //     {label}
  //   </Button>
  //   <Select value={value} onChange={(v) => onChange(v)} options={optionsWithHelp} />
  // </Space.Compact>

  return <Flex vertical>
    <div style={{
      fontSize: 12,
      fontWeight: 500,
      color: '#595959',
    }}>{label}</div>
    <Segmented
      size="small"
      options={optionsWithHelp}
      onChange={(v) => onChange(v)}
      value={value}
    />
  </Flex>
}

const ViewTypeDay = ViewTypes.Custom
const ViewTypeWeek = ViewTypes.Custom1

function getSummaryFunc(schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) {
  if (!headerEvents.length || schedulerData.mode === 'projects') {
    return null
  }
  const duration = headerEvents.reduce((acc, e) => {
    let d = e.slot?.duration || 0
    if (e.slot?.type === 'time') {

    }
    const start = dayjs(e.slot?.start)
    const end = dayjs(e.slot?.end)
    const curr = headerStart.slice(0, 10)
    const startD = start.format('YYYY-MM-DD')
    const endD = end.format('YYYY-MM-DD')
    // XXXX FIXME Review all this rule
    if (startD === endD) {
      d = d
    } else if (startD === curr) { // start of range
      d = start.hour() >= 12 ? 4 : 7
    } else if (endD === curr) { // end of range
      d = end.hour() <= 12 ? 4 : 7
    } else { // In middle range
      d = 7
    }
    return acc + d
  }, 0)
  if (!duration) {
    return null
  }
  const percent = Math.round(100 * duration / 7)
  // const text = `${duration}h`
  let color = '#d9d9d9';

  if (headerEvents.length > 0)
    color = headerEvents.length <= 1 ? 'green' : 'red';
  return {
    text: <Progress percent={percent} format={() => {
      return `${duration}h`
    }} status={percent > 100 && schedulerData.config?.mode === 'resources' ? 'exception' : null}
      size="small" />, color: color, fontSize: '12px'
  };
}


export default function PlanningView() {

  // const [filters, setFilters] = useState({})

  const [date, setDate] = useState(new dayjs())
  const [showProjects, setShowProjects] = useState(false)
  const [fullscreen, setFullscreen] = useState(false)

  const { can } = useAuth()

  const [expandedResources, setExpandedResources] = useState({})


  const initialState = useMemo(() => {
    return {
      data: new SchedulerData(new dayjs().format(DATE_FORMAT), ViewTypeDay, false, false, {
        // startResizable: false,
        // endResizable: false,
        movable: true,
        creatable: true,
        crossResourceMove: false,
        // checkConflict: false,
        // schedulerMaxHeight: 650,
        besidesWidth: 0,
        eventItemHeight: 25,
        eventItemLineHeight: 30,
        headerEnabled: false,


        defaultEventBgColor: '#80C5F6',
        selectedAreaColor: '#7EC2F3',
        nonWorkingTimeHeadColor: '#999999',
        nonWorkingTimeHeadBgColor: '#dfdfdf',
        nonWorkingTimeBodyBgColor: '#f3f3f3',
        summaryColor: '#666',
        groupOnlySlotColor: '#F8F8F8',

        resourceName: "Affaires",
        taskName: "Tâches",
        nonAgendaDayCellHeaderFormat: 'HH',
        addMorePopoverHeaderFormat: 'D MMM, YYYY dddd',
        eventItemPopoverDateFormat: 'D MMM',
        nonAgendaOtherCellHeaderFormat: 'ddd|DD/MM',
        responsiveByParent: true,

        // dayResourceTableWidth: 400,
        // weekResourceTableWidth: 400,
        // monthResourceTableWidth: 400,
        // quarterResourceTableWidth: 400,
        // yearResourceTableWidth: 400,
        // customResourceTableWidth: 400,

        scrollToSpecialDayjsEnabled: true,

        defaultExpanded: false,

      }, {
        getScrollSpecialDayjsFunc: (schedulerData, startDayjs, endDayjs) => {
          if (schedulerData.pendingAlignDate) {
            return schedulerData.pendingAlignDate; //.add(-schedulerData.pendingAlignDate.isoWeekday() + 1, "day")
            return schedulerData.pendingAlignDate.add(-schedulerData.pendingAlignDate.isoWeekday() + 1, "day")
          }
          return null;
        },
        getCustomDateFunc: (schedulerData, num, date = undefined) => {

          const { viewType } = schedulerData;
          let selectDate = schedulerData.startDate;
          if (date != undefined) {
            selectDate = date;
          }

          let startDate = num === 0 ? selectDate :
            schedulerData.localeDayjs(new Date(selectDate)).add(2 * num, 'days'),
            endDate = schedulerData.localeDayjs(new Date(startDate)).add(1, 'days'),
            cellUnit = CellUnit.Hour;
          switch (viewType) {
            case ViewTypeDay:
              startDate = date
              endDate = schedulerData.localeDayjs(startDate).add(30, 'days');
              cellUnit = CellUnit.Day;
              break;
            case ViewTypeWeek:
              startDate = date != undefined ? schedulerData.localeDayjs((date)).startOf('year')
                : schedulerData.localeDayjs(startDate).add(num, 'years');
              endDate = schedulerData.localeDayjs(startDate).endOf('year');
              cellUnit = CellUnit.Week
              break;
            default:
              throw new Error('Should not get here');
          }

          return {
            startDate,
            endDate,
            cellUnit
          };
        },

        getSummaryFunc,
        isNonWorkingTimeFunc: (schedulerData, time) => {
          if (schedulerData.nonWorkingDays && schedulerData.nonWorkingDays[time.slice(0, 10)]) {
            return true
          }
          // console.log(schedulerData.nonWorkingDays)
          return isNonWorkingTime(schedulerData, time) // Default implementation
        }
      })
    }
  }, [])

  const [settingDisplayOptions, setSettingDisplayOptions, settingDisplayOptionsLoaded] = useSetting('PlanningView.filters', {
    mode: 'projects',
    displayMode: 'day',
    deadline: '15',
    displayTasks: 'active',
    projectSort: 'project_code',
  })



  const [state, setState] = useState(initialState)

  const [nonWorkingDays, setNonWorkingDays] = useState({})

  const refBox = useRef()
  const { height: winHeight } = useWindowDimensions()
  const { dimensions: refDim, refresh: refreshRefDim } = useElementDimensions(refBox)
  const [form] = Form.useForm();

  const datastore = usePlanningDatastore();


  const { options, filters, setFilters, addFilter,
    customFilters, setCustomFilters } = useSearch({
      name: "PlanningView",
      list: [],

      projects: datastore.projects,
      agencies: datastore.agencies,
      resources: datastore.users,
      orders: datastore.orders,
      salesmen: datastore.salesmen,
      // tasks:tasks,
      prestations: datastore.prestations,
      enableProjectTypes: true,
      enableProjectInvoicingModes: true,
    })



  const filteredDatastore = useFilteredDatastore(datastore, filters, settingDisplayOptions)
  const {
    refreshTasks,
    refreshDatastore,

    deleteSlot,
    updateSlot,
    addSlot,
    loadSlots

    // saveSlot,
  } = datastore;

  const {
    tasksIdx,
    users,
    usersIdx,
    projects,
    getProject,
    prestations,
    prestationsIdx,
    agencies,
  } = datastore


  const {
    tasks,
    slots,
  } = filteredDatastore




  const [refreshState, setRefresh] = useState();

  const [editedSlot, setEditedSlot] = useState(null)
  const [savingSlot, setSavingSlot] = useState(false);
  const [deletingSlot, setDeletingSlot] = useState(false);
  const [cancelingSlot, setCancelingSlot] = useState(null);


  const byProjects = useByProjects(datastore, filters, settingDisplayOptions)

  const byResources = useByResources(datastore, filters, settingDisplayOptions)

  const { data } = state;
  const { mode, displayMode } = settingDisplayOptions;




  const refreshDisplay = useCallback(() => {
    // console.trace('refreshDisplay', new Error().stack)
    setRefresh({})
  }, []);

  // useEffect(() => {
  //   console.log('settingPlanningFilters', settingPlanningFilters, settingPlanningFiltersLoaded)
  //   if (!settingPlanningFiltersLoaded) return
  //   setFilters(settingPlanningFilters || {})
  // }, [settingPlanningFilters, settingPlanningFiltersLoaded])






  const { resources, events } = useMemo(() => {
    switch (mode || 'projects') {
      case 'projects':
        return byProjects
      case 'resources':
        return byResources
      default:
        return defaultPayload
    }
  }, [mode, byProjects, byResources])

  const metrics = useMetrics(datastore, filters, settingDisplayOptions)


  const slotsIdx = useMemo(() => {
    return indexBy(slots || [], "id");
  }, [slots])

  const errors = useSlotsValidator(slots, { tasks, resources: users, projects }, events)

  const errorsIdx = useMemo(() => {
    const idx = {};
    errors.forEach(error => {
      error.slots.forEach(({ id }) => {
        idx[id] = idx[id] || [];
        idx[id].push(error);
      })
    })
    return idx;
  }, [errors])

  const errorsByDates = useMemo(() => {
    const idx = {};
    errors.forEach(error => {
      error.slots.forEach(({ start, end }) => {
        start = new dayjs(start)
        end = new dayjs(end)
        for (let date = start; date < end; date = date.add(1, 'day')) {
          const key = date.format(DATE_FORMAT);

          idx[key] = idx[key] || [];
          idx[key].push(error);
        }
      }
      )
    })
    return idx;
  }, [errors])






  const editSlot = (slot) => {
    form.resetFields();
    form.setFieldsValue({
      ...slot,
      dates: [slot.start, slot.end]
    })

    setEditedSlot(slot)
  }



  const onDateChange = useCallback((date) => {
    if (!date) return;
    setDate(date)
    data.setDate(date.format(DATE_FORMAT));
    data.pendingAlignDate = date;
    data.setScrollToSpecialDayjs(true);
    // data.disableRenderData();
    // // data.setEvents([events]);
    // data.enableRenderData();
    refreshDisplay()
  }, [data, events, refreshDisplay])

  const updateEvent = useCallback(async (event, start, end) => {
    console.log("updateEvent", event, start, end)

    await updateSlot({
      ...event.slot,
      start: roundToStartWorkTimeIfNeeded(start),
      end: roundToEndWorkTimeIfNeeded(end),
    })

  }, [updateSlot])



  const onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    refreshDisplay()
  }


  const onEventClicked = (schedulerData, event) => {
    const { slot } = event
    if (!slot) return;
    // if (mode === 'resources') return;
    if (slot.ref) return;
    if (!can('slot.update')) return;
    editSlot({
      ...slot,
      // id: slot.id,
      // status: slot.status,
      start: roundToStartWorkTimeIfNeeded(slot.start),
      end: roundToEndWorkTimeIfNeeded(slot.end),
      // task_id: slot.task_id,
      // resource_id: slot.resource_id,
    })

    return false
  }

  const onUpdateEventStart = (schedulerData, event, newStart) => {
    // if (mode === 'resources') return;
    // console.log("onUpdateEventStart", event, newStart)
    if (!can('slot.update')) return;
    updateEvent(event, newStart, event.end)
  }

  const onUpdateEventEnd = (schedulerData, event, newEnd) => {
    // if (mode === 'resources') return;
    // console.log("onUpdateEventEnd", event, newEnd)
    if (!can('slot.update')) return;
    updateEvent(event, event.start, newEnd)
  }

  const onMoveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    // if (mode === 'resources') return;
    // console.log("onMoveEvent", event, slotId, slotName, start, end)
    if (!can('slot.update')) return;
    updateEvent(event, start, end)
  }

  const onNewEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    // if (mode === 'resources') return;
    // console.log("onNewEvent", slotId, slotName, start, end, type, item)
    if (!can('slot.create')) return;
    const newSlot = {
      start: roundToStartWorkTimeIfNeeded(start),
      end: roundToEndWorkTimeIfNeeded(end),
      type: new dayjs(start).isSame(end, 'day') ? 'task' : 'time',
      status: 'planned',
      task_id: null,
      resource_id: null,
    }
    if (mode === 'projects') {
      newSlot.task_id = slotId
    }
    if (mode === 'resources') {
      newSlot.resource_id = slotId
    }

    if (newSlot.type === 'task' && newSlot.task_id) {
      const task = datastore.getTask(newSlot.task_id);
      if (task?.unit === 'd') {
        newSlot.duration = 7
      } else {
        newSlot.duration = Math.min(7, task?.duration || 7)
      }
    }


    editSlot(newSlot)

    // refreshDisplay()
  }

  const onNewSlot = ({ task }) => {
    editSlot({
      // start: new dayjs(),
      // end: new dayjs().add(1, 'hour'),
      type: 'task',
      status: 'planned',
      task_id: task?.id,
      resource_id: null,
    })
  }


  const onViewportChange = (schedulerData, schedulerContent, viewport) => {
    if (schedulerData.viewport?.key !== viewport.key) {
      // console.log(viewport)
      schedulerData.viewport = viewport
    }
  }


  const onNextClick = (vp) => {
    const { end, interval } = data.viewport
    console.log('onNextClick', data.viewport, new dayjs(end).add(1, 'days'))
    onDateChange(new dayjs(end).add(1, 'days'))
  }

  const onPrevClick = () => {
    const { start, interval } = data.viewport
    onDateChange(new dayjs(start).add(-interval, 'days'))
  }

  const onToggleExpand = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    expandedResources[slotId] = !expandedResources[slotId]
    setExpandedResources({ ...expandedResources })
    setState({ data }); // Refresh without reloading events
  }

  const onSetStatus = (slotId, status, cancelReason = undefined) => {
    console.log('onSetStatus', slotId, status);
    (async () => {
      await updateSlot({
        ...slotsIdx[slotId],
        cancel_reason: cancelReason,
        status,
      })
      refreshDisplay()
    })()
  }

  const updateDisplayOptions = (values) => {
    const v = { ...settingDisplayOptions, ...values }
    setSettingDisplayOptions(v)
  }

  const onModeChange = (mode) => {
    updateDisplayOptions({ mode })
  }

  const onDisplayModeChange = (displayMode) => {
    updateDisplayOptions({ displayMode })
  }

  // const onSearchChange = (values) => {
  //   updateFilters(values)
  // }

  const onDeadlineChange = (deadline) => {
    updateDisplayOptions({ deadline })
  }

  // const onProjectStatusChange = (state) => {
  //   updateFilters({ projectState: state })
  // }

  // const onRemainigIndicatorChange = (indicator) => {
  //   updateFilters({ remainingIndicator: indicator })
  // }

  const onDisplayTasksChange = (displayTasks) => {
    updateDisplayOptions({ displayTasks: displayTasks })
  }

  const onProjectSortChange = (projectSort) => {
    updateDisplayOptions({ projectSort: projectSort })
  }


  // const addSearchFilter = (type, value) => {
  //   const values = (filters.values || [])
  //   if (!values.includes(value)) {
  //     updateFilters({
  //       ...filters,
  //       [type]: [...filters[type] || [], value],
  //       values: [...values, value]
  //     });
  //   }
  // }

  const onDeleteSlot = async () => {
    setDeletingSlot(true)
    await deleteSlot(editedSlot.id)
    setDeletingSlot(false)
    setEditedSlot(null)
  }

  const gotoDate = useCallback((date) => {
    onDateChange(new dayjs(date))
  }, [onDateChange])

  const gotoFirstDate = (slotId) => {
    const firstDate = events.reduce((d, event) => {
      if (event.resourceId === slotId) {
        const start = new dayjs(event.start)
        if (d.isBefore(new dayjs(start))) {
          return start
        }
      }
      return d
    }, new dayjs())

    gotoDate(firstDate)
  }



  const onEditModalOk = () => {
    if (editedSlot.status === 'done') {
      setEditedSlot(null)
      return
    }
    form
      .validateFields()
      .then(async (values) => {

        setSavingSlot(true)

        let status = editedSlot.status

        const getSlot = (start, end) => {
          if (values.type === 'task') {
            start = startWorkTime(start)
            end = endWorkTime(end || start)
          } else {
            start = roundToStartWorkTimeIfNeeded(start)
            end = roundToEndWorkTimeIfNeeded(end)
          }

          const slot = {
            ...editedSlot,
            ...values,
            status: status,

            // task_id: editedSlot.task_id || 'unavailable',
            start: start.toISOString(),
            end: end.toISOString()
          }

          if (editedSlot.task_id === "unavailable" || values?.type === 'unavailablity') {
            slot.status = 'unavailable'
            slot.task_id = "unavailable"
            slot.type = 'time'
          }

          return slot;
        }




        if (editedSlot.id) {
          // Update
          const slot = getSlot(values.dates[0], values.dates[1] || values.dates[0])
          if (editedSlot.task_id !== values.task_id) {
            await deleteSlot(slot.id)
            await addSlot(slot)
          } else {
            await updateSlot(slot)
          }
        } else {
          const suggested_slots = form.getFieldValue('suggested_slots');
          const toPlanDuration = form.getFieldValue('toplan_duration');
          const toPlanUnit = form.getFieldValue('toplan_unit');

          // console.log(toPlanDuration, toPlanUnit)

          if (values.auto && suggested_slots.length && values.type === 'task') {
            for (let i = 0; i < suggested_slots.length; i++) {
              const ss = suggested_slots[i]
              const slot = getSlot(ss.start, ss.end)
              slot.duration = ss.duration
              // const start = roundToStartWorkTimeIfNeeded(ss.start)
              // const end = roundToEndWorkTimeIfNeeded(ss.end)
              // const slot = {
              //   ...editedSlot,
              //   ...values,
              //   status: status,
              //   start: start.toISOString(),
              //   end: end.toISOString(),
              //   duration: ss.duration,
              // }
              // if (editedSlot.task_id === "unavailable" || values?.type === 'unavailablity') {
              //   slot.status = 'unavailable'
              //   slot.task_id = "unavailable"
              //   slot.type = 'time'
              // }
              await addSlot(slot)
            }
          } else {
            const slot = getSlot(values.dates[0], values.dates[1] || values.dates[0])
            await addSlot(slot);
          }
        }



        setSavingSlot(false)
        setEditedSlot(null)
        message.success('Enregistré');
        // onCreate(values);
      })
      .catch((info) => {

        if (!info.errorFields) {
          message.error('Erreur: ' + info);
        }

      })
      .finally(() => {
        setSavingSlot(false)
      });
  }

  const [newTaskModalVisible, setNewTaskModalVisible] = useState(false)
  const onNewTask = () => {
    setNewTaskModalVisible(true)
  }
  const onNewTaskSubmit = async (task, slot) => {
    try {
      const t = await loaderWrap(createTask({
        ...task,
        status: 'unplanned',
      }))
      if (slot) {
        await addSlot({
          ...slot,
          task_id: t.id,
          status: 'planned',
        })
      }
    } catch (e) {
      notifyError(e)
    }

    refreshTasks()
  }

  // debugTraceHookChanges({ resources })
  // useEffect(() => {
  //   console.log('ressource changed', resources)
  // }, [resources])


  useEffect(() => {
    if (!data.viewport) {
      return
    }
    loadSlots({
      dates: [data.viewport.start, data.viewport.end],
    });

    (async () => {
      let days = await getNonWorkingDays(data.viewport.start.getFullYear())
      if (data.viewport.start.getFullYear() !== data.viewport.end.getFullYear()) {
        const days2 = await getNonWorkingDays(data.viewport.end.getFullYear())
        days = { ...days, ...days2 }
      }
      // console.log(">DAYS", days);
      setNonWorkingDays(days)
    })()
  }, [data.viewport, loadSlots])



  // useEffect(() => {
  //   const res = resources
  //     .map(r => {
  //       return {
  //         ...r,
  //         expanded: r.id in expandedResources ? expandedResources[r.id] : r.expanded
  //       }
  //     });
  //   data.disableRenderData();
  //   data.setResources(res);
  //   refreshDisplay()
  // }, [data, resources, expandedResources])

  useEffect(() => {
    if (!displayMode) return
    switch (displayMode) {
      case 'week':
        data.setViewType(ViewTypeWeek, false, false);
        break;
      case 'day':
        data.setViewType(ViewTypeDay, false, false);
        break;
      default:
    }
    refreshDisplay()
  }, [data, displayMode])

  useEffect(() => {
    if (datastore.allLoading) return
    if (!mode) return
    if (!displayMode) return
    if (!events) return
    if (!refDim?.height) return
    // Update the scheduler data
    // console.log('event update', mode, displayMode, events)
    data.disableRenderData();
    data.setSchedulerLocale('fr');
    data.setCalendarPopoverLocale('fr_FR');
    // console.log('refDim', refDim)

    data.config.schedulerContentHeight = winHeight - refDim?.top - data.config.tableHeaderHeight - 1

    data.mode = mode;
    switch (mode) {
      case 'projects':
        data.config.resourceName = "Affaires";
        data.config.taskName = "Tâches";
        break;
      case 'resources':
        data.config.resourceName = "Ressources";
        data.config.taskName = "Tâches";
        break;
      default:
    }
    const res = resources
      .map(r => {
        return {
          ...r,
          expanded: r.id in expandedResources ? expandedResources[r.id] : r.expanded
        }
      });
    data.setResources(res);
    data.setEvents(events);
    if (data.nonWorkingDays !== nonWorkingDays) {
      data.nonWorkingDays = nonWorkingDays;
      data._createHeaders();
    }
    data.enableRenderData();

    refreshDisplay()
  }, [mode, displayMode, events, nonWorkingDays, refDim?.height, resources, expandedResources, datastore.allLoading])

  useEffect(() => {
    // console.log('state refresh', refreshState)
    const resourceHeaderWidth = mode === 'resources' ? 250 : 400
    data.config = {
      ...data.config,
      mode: mode,
      dayResourceTableWidth: resourceHeaderWidth,
      weekResourceTableWidth: resourceHeaderWidth,
      monthResourceTableWidth: resourceHeaderWidth,
      quarterResourceTableWidth: resourceHeaderWidth,
      yearResourceTableWidth: resourceHeaderWidth,
      customResourceTableWidth: resourceHeaderWidth,
      customCellWidth: 100,
    }
    data.behaviors.getSummaryFunc = getSummaryFunc
    if (data.config.schedulerContentHeight) {
      // console.log('setSchedulerMaxHeight', data.config.schedulerContentHeight)
      setState({ data });
    }
    // console.log('config', data.config.schedulerContentHeight)
  }, [refreshState])

  const getItem = useCallback((id) => {
    const isProject = id.startsWith('prj');
    const isTask = id.startsWith('task');
    const isResource = id.startsWith('res');

    switch (true) {
      case isProject:
        return getProject(id)
      case isTask:
        return tasksIdx[id]
      case isResource:
        return usersIdx[id]
      default:
        return null
    }
  }, [getProject, tasksIdx, usersIdx])

  const editedSlotTitle = useMemo(() => {
    if (!editedSlot) return null
    if (mode === 'projects') {
      const task = tasksIdx[editedSlot.task_id]
      return editedSlot?.task_id === "unavailable" ? "Indisponibilité" : task?.name

    } else if (mode === 'resources') {
      const resource = usersIdx[editedSlot.resource_id]
      return resource?.name
    }
  }, [mode, editedSlot, tasksIdx, usersIdx])

  useEffect(() => {
    refreshRefDim()
  }, [fullscreen])

  // useEffect(() => {
  //   console.log('refDim', refDim?.height)
  //   data.setSchedulerMaxHeight(refDim?.height)
  //   refreshDisplay()
  // }, [refDim?.height])


  if (!settingDisplayOptionsLoaded) {
    return <Skeleton style={{ padding: '1rem' }} active />
  }

  // const pinProjectOverview = mode === 'resources' && windowWidth > 1800;

  return (<>
    <Flex gap="small" className={fullscreen ? Styles.fullscreen : null} style={{ flex: 1 }}  >
      <Flex vertical className={"box " + (fullscreen ? Styles.fullscreenBox : '')}
        style={{ overflow: "hidden", padding: 0, flex: 1, marginBottom: 0 }} >
        <div style={{ padding: 20 }}>
          <Flex align={"center"} justify={"end"} gap={"small"}>
            <Space.Compact style={{ flex: 1 }}>
              <Search
                name="PlanningView"
                options={options}
                values={filters}
                setCustomFilters={setCustomFilters}
                customFilters={customFilters}
                onFiltersChange={values => setFilters(values)}
                allowRevert

              />
              <Button onClick={() => refreshDatastore()} icon={<ReloadOutlined />} loading={!datastore.complete} />
            </Space.Compact>

            <div>
              <Space.Compact block>
                <Button
                  onClick={() => onPrevClick()}
                  icon={<LeftOutlined />}
                />
                <DatePicker
                  style={{ fontWeight: 'bold' }}
                  onChange={onDateChange}
                  format={'DD/MM/YYYY'}
                  // size="large"
                  allowClear={false}
                  // variant="borderless"
                  value={date}
                />
                <Button
                  onClick={() => onNextClick()}
                  icon={<RightOutlined />}
                />
              </Space.Compact>
            </div>

            <Popover title="Problèmes"
              content={<div style={{ maxWidth: 400, maxHeight: 500, overflow: 'auto' }}>
                <Space direction="vertical" size="small">
                  {errors.map((error, i) => <PlanningErrorItem key={i} error={error} users={users} tasks={tasks} />)}
                </Space>
              </div>} >
              <Badge count={errors?.length} />
            </Popover>
            {can('task.create') &&
              <Button type="primary" onClick={() => onNewTask()}
              >
                Nouvelle tâche
              </Button>}
            {/* {mode === 'resources' && !pinProjectOverview &&
              <Button onClick={() => setShowProjects(true)}>
                Affaires
              </Button>
            } */}
            <Button onClick={() => setFullscreen(!fullscreen)} icon={fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} >
              {fullscreen ? "Réduire" : "Plein écran"} </Button>

          </Flex>

          <Flex style={{ padding: '8px 0' }} align="center" gap="small" justify="stretch">
            <Option
              label="Vue"
              options={[
                { label: 'Affaires', value: 'projects' },
                { label: 'Ressources', value: 'resources' },
              ]}
              value={mode}
              onChange={onModeChange}
            />
            <Divider type="vertical" />
            <Option
              label="Résolution"
              options={[
                { label: 'Jours', value: 'day' },
                { label: 'Semaines', value: 'week' },
              ]}
              value={displayMode}
              onChange={onDisplayModeChange}
            />

            <Divider type="vertical" />
            <Option

              label="Echéance"
              options={[
                { label: '15 jrs', value: '15' },
                { label: '30 jrs', value: '30' },
                { label: 'Tous', value: '' },
              ]}
              value={settingDisplayOptions.deadline}
              onChange={onDeadlineChange}
            />

            <Divider type="vertical" />
            <Option
              label="Tâches"
              value={settingDisplayOptions.displayTasks} onChange={onDisplayTasksChange}
              options={[
                { label: "Non planifiées", value: 'toPlan', help: 'Tâches à planifier (non planifiées ou non confirmées)' },
                { label: "En cours", value: 'active', help: 'Tâches en cours, sont exclus uniquement les tâches terminées' },
                { label: "Tout", value: 'all' },
              ]} />

            <Divider type="vertical" />
            <Option
              label="Tri"
              value={settingDisplayOptions.projectSort} onChange={onProjectSortChange}
              options={[
                { label: "N° d'Affaire", value: 'project_code' },
                { label: "Date de livraison", value: 'shipping_date' },
              ]} />
          </Flex>


        </div>
        <div ref={refBox} style={{ flex: 1 }} onMouseOver={() => showProjects && setShowProjects(false)}>
          {datastore.allLoading ? <Skeleton active style={{ padding: '1rem' }} /> :
            <DndProvider backend={HTML5Backend}>
              <Scheduler
                parentRef={refBox}
                schedulerData={data}
                // prevClick={prevClick}
                // nextClick={nextClick}
                // onSelectDate={onSelectDate}
                onViewChange={onViewChange}
                eventItemClick={onEventClicked}

                moveEvent={onMoveEvent}
                updateEventStart={onUpdateEventStart}
                updateEventEnd={onUpdateEventEnd}
                newEvent={onNewEvent}


                onViewportChange={onViewportChange}
                nonAgendaCellHeaderTemplateResolver={(schedulerData, item, formattedDateItems, style) => {
                  let datetime = schedulerData.localeDayjs(item.time);
                  let isCurrentDate = false;

                  if (schedulerData.viewType === ViewTypes.Day) {
                    isCurrentDate = datetime.isSame(new Date(), 'hour');
                  }
                  else if (schedulerData.cellUnit === CellUnit.Week) {
                    isCurrentDate = datetime.isSame(new Date(), 'week');
                  }
                  else {
                    isCurrentDate = datetime.isSame(new Date(), 'day');
                  }

                  if (isCurrentDate) {
                    style.backgroundColor = '#118dea';
                    style.color = 'white';
                  }

                  const errors = errorsByDates[datetime.format(DATE_FORMAT)] || []
                  if (errors.length > 0) {
                    const hasError = (errors.findIndex(e => e.level === 'error') !== -1)
                    style.backgroundColor = hasError ? '#ff4d4f' : '#faad14';
                    style.color = 'white';
                  }

                  if (schedulerData.cellUnit === CellUnit.Week) {
                    const [week, month] = formattedDateItems
                    return (
                      <th key={item.time} className={`header3-text`} style={style}>
                        <b>{week}</b><br />{month}
                      </th>
                    );
                  }
                  const [day, date] = formattedDateItems
                  return (
                    <th key={item.time} className={`header3-text`} style={style}>
                      {day} <b>{date}</b>
                      {/* {
                    formattedDateItems.map((formattedItem, index) => (
                      <div key={index}
                        dangerouslySetInnerHTML={{ __html: formattedItem.replace(/[0-9]/g, '<b>$&</b>') }} />
                    ))
                  } */}
                    </th>
                  );
                }}

                slotItemTemplateResolver={(schedulerData, item, slotClickedFunc, width, clsName) => {



                  return <PlanningSlotHeaderItemRenderer
                    schedulerData={schedulerData}
                    item={item}
                    toggleExpandFunc={onToggleExpand}
                    width={width}
                    className={clsName}

                    object={getItem(item.slotId)}
                    // resourcesIdx={{
                    //   ...projectsIdx,
                    //   ...tasksIdx,
                    //   ...prestationsIdx,
                    //   ...usersIdx,
                    // }}

                    onClick={() => gotoFirstDate(item.slotId)}
                    onSelectDate={gotoDate}
                    metrics={metrics[item.slotId]}
                    onChange={() => {
                      refreshTasks()
                    }}

                    // remainingIndicator={filters.remainingIndicator}
                    addSearchFilter={addFilter}
                  />

                }}
                eventItemPopoverTemplateResolver={(schedulerData, eventItem, title, start, end, statusColor) => {
                  const slot = slotsIdx[eventItem.id];
                  const task = tasksIdx[slot?.task_id];

                  return <SlotPopover
                    schedulerData={schedulerData}
                    eventItem={eventItem}
                    title={title}
                    start={start}
                    end={end}
                    statusColor={statusColor}
                    errors={errorsIdx[eventItem.id]}

                    slot={slot}
                    project={getProject(slot?.project_id)}
                    user={usersIdx[slot?.resource_id]}
                    task={task}
                    prestation={prestationsIdx[task?.prestation_id]}

                    users={users}
                    tasks={tasks}

                    onCancel={() => setCancelingSlot(eventItem.id)}
                    onConfirm={() => onSetStatus(eventItem.id, 'confirmed')}
                    onDone={() => onSetStatus(eventItem.id, 'done')}

                  />
                }}
                eventItemTemplateResolver={(schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {

                  if (event.milestone) {
                    return <ResourceSlotMilestoneItem
                      schedulerData={schedulerData}
                      event={event}
                      bgColor={bgColor}
                      isStart={isStart}
                      isEnd={isEnd}
                      mustAddCssClass={mustAddCssClass}
                      mustBeHeight={mustBeHeight}
                      agendaMaxEventWidth={agendaMaxEventWidth}
                    />
                  }
                  switch (mode) {
                    case 'projects':
                    case 'archives':
                      return <ResourceSlotEventItem
                        schedulerData={schedulerData}
                        event={event}
                        // bgColor={bgColor}
                        isStart={isStart}
                        isEnd={isEnd}
                        mustAddCssClass={mustAddCssClass}
                        mustBeHeight={mustBeHeight}
                        agendaMaxEventWidth={agendaMaxEventWidth}
                        errors={errorsIdx[event.id]}
                        resourcesIdx={{
                          ...slotsIdx,
                          ...usersIdx,
                        }} />
                    case 'resources':
                      // return null;
                      return <TaskSlotEventItem
                        schedulerData={schedulerData}
                        event={event}
                        // bgColor={bgColor}
                        isStart={isStart}
                        isEnd={isEnd}
                        mustAddCssClass={mustAddCssClass}
                        mustBeHeight={mustBeHeight}
                        agendaMaxEventWidth={agendaMaxEventWidth}
                        errors={errorsIdx[event.id]}
                        resourcesIdx={{
                          ...slotsIdx,
                          ...usersIdx,
                        }} />
                    default:
                      return null;
                  }
                }
                }
              />
            </DndProvider>
          }


        </div>
      </Flex>
      {mode === 'resources' && <div className={clsx(Styles.projectPanel, showProjects && Styles.projectPanelOpen)} onMouseOver={() => !showProjects && setShowProjects(true)}>
        <div className={clsx("box ", Styles.projectPanelInner, showProjects && Styles.projectPanelInnerOpen)} >
          <Flex vertical style={{ flex: 1 }}>
            <h3 style={{ paddingBottom: "1em" }}>Affaires</h3>
            <div style={{ overflow: "auto", maxHeight: 800, flex: 1 }}>
              <PlanningProjectsOverview datastore={datastore}
                filteredDatastore={filteredDatastore}
                metrics={metrics}
                onSelectDate={gotoDate}
                onClickNewSlot={({ task }) => onNewSlot({ task })}
                addSearchFilter={addFilter}
              />
            </div>
          </Flex>
        </div>
      </div>
      }
    </Flex>



    <Modal
      title={editedSlotTitle}
      destroyOnClose={true}
      centered
      width={800}
      open={!!editedSlot}
      onOk={onEditModalOk}
      confirmLoading={savingSlot}
      cancelText="Annuler"
      onCancel={() => {
        setEditedSlot(null)
        form.resetFields();
      }}
      footer={(_, { OkBtn, CancelBtn }) => {
        if (editedSlot.status === 'done') {
          return <OkBtn />
        }
        return <Flex gap="small" justify="flex-end">
          {editedSlot && editedSlot.id && can('slot.delete') && (
            <div style={{ flex: 1, textAlign: 'left' }}>
              <Button danger loading={deletingSlot} onClick={() => onDeleteSlot()}>Supprimer</Button>
            </div>)}
          {!deletingSlot && <>
            <CancelBtn />
            <OkBtn />
          </>}
        </Flex>
      }}
    >
      {(() => {
        if (!editedSlot) return null;
        if (editedSlot.status === 'done') {
          return <PlanningSlotComplete slot={editedSlot}
            task={tasksIdx[editedSlot.task_id]}
            resource={usersIdx[editedSlot.resource_id]}
            project={getProject(editedSlot.project_id)}
          />
        }
        switch (mode) {
          case 'projects':
            return <PlanningTaskSlotForm
              form={form}
              slots={slots}
              slot={editedSlot}
              task={tasksIdx[editedSlot.task_id]}
              agencyId={getProject(editedSlot.project_id)?.agency_id} />
          case 'resources':
            return <PlanningResourceSlotForm
              form={form}
              slots={slots}
              slot={editedSlot}
              resource={usersIdx[editedSlot.resource_id]}
              tasks={tasks}
              projects={projects}
              agencyId={usersIdx[editedSlot.resource_id]?.agency_id} />
          default:
            return null;
        }
      })()}

    </Modal>

    <Modal
      title="Annulation du créneau"
      centered
      open={!!cancelingSlot}
      destroyOnClose={true}
      // width={400}
      // confirmLoading={cancelingSlot}
      onCancel={() => setCancelingSlot(null)}
      footer={(_, { OkButton }) => <></>}
    >
      <p>Indiquez la raison de l'annulation</p>
      <div style={{ paddingLeft: "3em" }}>
        <Radio.Group
          onChange={(e) => {
            onSetStatus(cancelingSlot, 'canceled', e.target.value)
            setCancelingSlot(null)
          }}>
          <Space direction="vertical">
            {slotCancelReasons.map(({ value, label }) =>
              <Radio key={value} value={value}>{label}</Radio>)}
          </Space>
        </Radio.Group>
      </div>
    </Modal>
    {newTaskModalVisible ? <PlanningNewTaskModal onSubmit={onNewTaskSubmit} onClose={() => setNewTaskModalVisible(false)} /> : null}
  </>);
}