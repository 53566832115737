import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/resources');
const { invalidateCache, withCache } = cache();


function mapFromResourcePrestationModel(input) {
  return { ...input };;
}

function mapToResourcePrestationModel(input) {
  return { ...input };;
}



export async function listResourcePrestations() {
  const items = await withCache(async () => {
    const { items } = await api.get('prestations');
    return items;
  })
  return items ? items.map(mapToResourcePrestationModel) : [];
}

export async function listResourcePrestationsForResource(id) {
  const items = await withCache(async () => {
    const { items } = await api.get(`${id}/prestations`);
    return items;
  }, { list: true, resourceId: id })
  return items ? items.map(mapToResourcePrestationModel) : [];
}

export async function listResourcePrestationsForPrestation(id) {
  const items = await withCache(async () => {
    const { items } = await api.get(`prestations/${id}`);
    return items;
  }, { list: true, prestationId: id })
  return items ? items.map(mapToResourcePrestationModel) : [];
}


export async function putResourcePrestations(id, resourcePrestations) {
  const { items } = await api.post(`${id}/prestations`, { items: resourcePrestations.map(item => mapFromResourcePrestationModel(item)) });
  if (items) {
    invalidateCache({ resourceId: id });
  }
  return items ? items.map(mapToResourcePrestationModel) : [];
}

export async function putPrestationResources(id, resourcePrestations) {
  const { items } = await api.post(`${id}/prestations`, { items: resourcePrestations.map(item => mapFromResourcePrestationModel(item)) });
  if (items) {
    invalidateCache({ prestationId: id });
  }
  return items ? items.map(mapToResourcePrestationModel) : [];
}



// export async function createResourcePrestation(id, resourcePrestation) {
//   const { item } = await api.post(`${id}/prestations`, { item: mapFromResourcePrestationModel(resourcePrestation) });
//   return item ? mapToResourcePrestationModel(item) : undefined;
// }

export async function updateResourcePrestation(id, resourcePrestation) {
  const res = await api.put(`${id}/prestations`, { item: mapFromResourcePrestationModel(resourcePrestation) });
  invalidateCache({ resourceId: id, prestationId: resourcePrestation.id });
  return res ? resourcePrestation : undefined;
}

export async function removeResourcePrestation(id, prestationId) {
  const res = await api.delete(`${id}/prestations/${prestationId}`);
  invalidateCache({ resourceId: id, prestationId });
  return !!res;
}

export function invalidateResourcePrestationCache() {
  invalidateCache()
}
