import { useParams } from "react-router-dom";
import ChangeLog from "../../components/changelog/ChangeLog";
import { useTask } from "../../hooks/tasks";


export default function TaskEvents() {
    const { taskId } = useParams();
    const { changes } = useTask(taskId, { withLogs: true });
    return <ChangeLog changes={changes} />
}
