import { Flex, Progress, Tag } from "antd"
import { useMemo } from "react"
import { projectStatuses } from "../../hooks/projects"



export default function ProjectStatusLabel({ value, progress }) {
  const { label, color } = useMemo(() => {
    return projectStatuses.find(s => s.value === value) || { label: value }
  }, [value])
  return <Tag color={color || 'default'}>
    <Flex align="center" gap={3}>
      {label}
      {progress !== undefined ? <Progress
        type="circle"
        strokeColor={"#ffffffee"}
        trailColor="#ffffff55"
        strokeWidth={15}
        percent={progress}
        size={14}
      /> : null}
    </Flex>
  </Tag>
}
