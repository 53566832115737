import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/prestations');
const { invalidateCache, withCache } = cache();

function mapFromPrestationModel(input) {
  return { ...input };;
}

function mapToPrestationModel(input) {
  return { ...input };
}

export async function listPrestations() {
  const items = await withCache(async () => {
    const { items } = await api.get();
    return items;
  })
  return items ? items.map(mapToPrestationModel) : [];
}

export async function getPrestation(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Prestation not found');
    }
    return item;
  }, id)
  const prestation = mapToPrestationModel(item);
  if (!prestation) {
    throw new Error('Prestation not found');
  }
  return prestation;
}

export async function createPrestation(prestation) {
  const { item } = await api.post('', { item: mapFromPrestationModel(prestation) });
  if (item) {
    invalidateCache(item.id)
    return mapToPrestationModel(item)
  }
  return undefined;
}

export async function updatePrestation(prestation) {
  const res = await api.put('', { item: mapFromPrestationModel(prestation) });
  invalidateCache(prestation.id)
  return res ? prestation : undefined;
}

export async function removePrestation(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}


export function invalidatePrestationCache(preId) {
  invalidateCache(preId)
}
