import Icon from '@mdi/react';
import clsx from 'clsx';
import { useState } from 'react';

import { mdiDelete, mdiDeleteAlert } from '@mdi/js';
import Style from './DeleteButton.module.scss';

const positionCls = {
  left: Style['open-left'],
  right: Style['open-right'],
};

export default function DeleteButton({
  onDelete, openDir, confirmMessage, buttonMessage, disabled,
}) {
  const [state, setState] = useState(0);
  const [tm, setTm] = useState(0);
  const willDelete = state === 1;
  const deleting = state === 2;

  const reset = () => {
    setState(0);
    clearTimeout(tm);
  };

  const resetLater = (ms) => {
    clearTimeout(tm);
    setTm(+setTimeout(() => reset(), ms));
  };

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!willDelete) {
      setState(1);
    } else {
      reset();
    }
  };
  const onConfirm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (willDelete) {
      reset();
      setState(2); // deleting
      onDelete();
    }
  };

  const onLeave = () => {
    if (state === 1) {
      resetLater(100);
    }
  };
  const onEnter = () => {
    clearTimeout(tm);
  };

  const open = openDir === undefined ? 'right' : 'left';

  return (
    <div
      className={clsx(Style['delete-button'], positionCls[open], willDelete && Style.confirm)}
      onMouseLeave={onLeave}
      onMouseEnter={onEnter}
    >
      <button
        type="button"
        disabled={deleting || disabled}
        className={clsx(
          'btn btn-danger',
          willDelete ? 'btn-danger' : 'btn-outline-danger',
        )}
        onClick={onClick}
      >

        {deleting
          ? <div className="spinner-grow text-light spinner-grow-sm" role="status" />
          : <Icon className="icon" size={0.9} path={willDelete ? mdiDeleteAlert : mdiDelete} />}

      </button>
      <div className={clsx(Style.message)}>
        {/* <p>{confirmMessage || 'Cliquez sur le bouton ci-desous pour confirmer'}</p> */}
        <button type="button" className="btn btn-danger" onClick={onConfirm}>
          {buttonMessage || 'Confirmer !'}
        </button>
      </div>
    </div>
  );
}
