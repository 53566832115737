import { SearchOutlined } from '@ant-design/icons';
import { Badge, Button, Flex, Input, Space, Table } from 'antd';
import { differenceInCalendarDays } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectCode from '../../components/projects/ProjectCode';
import ProjectStatusLabel from '../../components/projects/ProjectStatusLabel';
import { useAgencies, useSelectedAgencyID } from '../../hooks/agencies';
import { useAuth } from '../../hooks/auth';
import { useProjects } from '../../hooks/projects';
import { useSearcher } from '../../hooks/search';
import { formatDate } from '../../lib/format';
import { indexBy } from '../../lib/utils';

export default function ProjectsList() {
  const [projects] = useProjects({ all: true });
  const [agencies] = useAgencies();
  const { can } = useAuth();

  const [agencyId] = useSelectedAgencyID();

  const { list, search, setSearch } = useSearcher(
    projects,
    useCallback((o, search, sanitizer) => sanitizer(o.code + ' ' + o.name).includes(search), []),
  );



  const navigate = useNavigate();
  const open = (id) => {
    if (!can('project.view')) return;
    navigate(`./${id}`);
  };

  const items = useMemo(() => {
    const agencyIdx = indexBy(agencies, "id")
    return list
      .filter(({ agency_id }) => !agencyId || agencyId === agency_id)
      .map((item) => {
        const agency = agencyIdx[item.agency_id];
        return {
          ...item,
          agency_name: agency?.name,
        };
      });
  }, [list, agencyId, agencies]);

  return (<>
    <div className="box">
      <Flex gap="small" align='center' >
        <Input size="large" placeholder="Rechercher une affaire..."
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        {can('project.create') && <Button type="primary" onClick={() => open("*")}>Créer une affaire</Button>}
      </Flex>
    </div>
    <div className="box-paddingless">

      <Table
        // bordered
        dataSource={items}
        pagination={
          {
            position: ['bottomCenter'],
          }
        }
        rowKey={(record) => record.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => open(record.id), // click row
          };
        }}
        columns={[
          {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 100,
            sorter: (a, b) => String(a.code).localeCompare(b.code),
            render: (code) => <ProjectCode value={code} />
          },
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => String(a.name).localeCompare(b.name),
          },
          {
            title: 'Date de début',
            dataIndex: 'expected_start_date',
            key: 'expected_start_date',
            width: 150,
            sorter: (a, b) => String(a.expected_start_date).localeCompare(b.expected_start_date),
            render: (text, rec) => {
              return text ? formatDate(text) : '';
            }
          },
          {
            title: 'Date de fin',
            dataIndex: 'expected_end_date',
            key: 'expected_end_date',
            width: 150,
            sorter: (a, b) => String(a.expected_end_date).localeCompare(b.expected_end_date),
            render: (text, rec) => {
              if (+new Date(rec.expected_end_date) < 0) {
                return null;
              }
              const nbInLate = rec.status === 'closed' || !rec.expected_end_date
                ? 0
                : differenceInCalendarDays(new Date(), new Date(rec.expected_end_date));
              return <Space gap="large">
                <span
                  style={{
                    color: nbInLate > 0 ? 'red' : 'inherit',
                    fontWeight: nbInLate > 0 ? 'bold' : 'inherit',
                  }}
                >{text ? formatDate(text) : ''}</span>
                {nbInLate > 0 ? <Badge count={
                  '+' + nbInLate + ' jrs'

                } /> : null}
              </Space>
            }
          },

          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (text, rec) => {
              return <Space direction='vertical'>
                <ProjectStatusLabel value={rec.status} />
              </Space>
            },
            // filters: projectStatuses.map(({ label, value }) => ({ text: label, value })),
            // defaultFilteredValue: projectStatuses.map(({ value }) => value).filter(value => value !== 'complete'),
            // onFilter: (value, record) => record.status === value,
          },
          {
            title: 'Agence',
            dataIndex: 'agency_name',
            key: 'agency_name',
            width: 150,
            sorter: (a, b) => String(a.agency_name).localeCompare(b.agency_name),
          }
        ]}
      />
    </div>

  </>);
}
