
import { ID } from "../id/id";
import Link from "../link/Link";


export default function DeliveryLabel({ id, clickable = true }) {
  // const { delivery } = useDelivery(id);

  const label = <>Livraison n° <ID value={id} variant="text" /></>
  if (!clickable) {
    return label;
  }
  return <Link to={"/deliveries/" + id}>{label}</Link>;
}