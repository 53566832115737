import { useCallback, useEffect, useState } from 'react';

import {
  createWarehouse, getWarehouse, listWarehouses, removeWarehouse, updateWarehouse,
} from '../services/api/warehouses';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';


const INVALIDATION_KEY = Symbol('warehouses');


function getDefaults() {
  return {
    code: "",
    name: "",
    capacity: 0,
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getWarehouse(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useWarehouses() {
  const [warehouses, setWarehouses] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listWarehouses()));
        const terms = await loaderWrap(listWarehouses());
        setWarehouses(terms);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [warehouses, refresh, complete];
}

export function useWarehouse(id) {
  const [warehouse, setWarehouse] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setWarehouse(await getItem(id));
    })();
  }, [id, counter]);

  const saveWarehouse = useCallback(async (item) => {
    if (!item) {
      throw new Error('No warehouse');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updateWarehouse(item));
    } else {
      out = await loaderWrap(createWarehouse(item));
    }
    setWarehouse(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteWarehouse = useCallback(async () => {
    await loaderWrap(removeWarehouse(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    warehouse,
    reloadWarehouse: () => invalidate(id),
    isNewWarehouse: !warehouse?.id,
    setWarehouse,
    saveWarehouse,
    deleteWarehouse,
  };
}


// export function warehouseValidator(values, name = undefined) {
//   const rules = {
//     name: [isRequired],
//     code: [],
//     type: [isRequired],
//     capacity: [],
//     description: [],
//   };
//   if (name) {
//     const err = validate(values[name], ...rules[name] || []);
//     return { [name]: err || null };
//   }
//   return validateAll(values, rules);
// }

// export function useWarehouseForm(id) {
//   const { warehouse, saveWarehouse, deleteWarehouse } = useWarehouse(id);

//   const {
//     register,
//     handleSubmit,
//     errors, isValid,
//     setValues,

//     values,
//   } = useForm({
//     values: {
//       ...getDefaults(),
//     },
//     validator: warehouseValidator,
//     // reValidateMode: "onChange"
//   });

//   useEffect(() => {
//     if (warehouse) setValues(warehouse);
//   }, [warehouse]);


//   return {
//     warehouse,
//     register,
//     errors,
//     isValid,
//     // rules,

//     handleSubmit,
//     saveWarehouse: async () => {
//       const data = {
//         ...values,
//       };
//       await saveWarehouse(data);
//     },
//     deleteWarehouse,
//   };
// }


