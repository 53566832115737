import { mdiCheck } from "@mdi/js";
import Icon from "@mdi/react";
import { AutoComplete, Button, Flex, Space, Tag } from "antd";
import { useMemo, useState } from "react";
import { usePersistentState } from "../../hooks/state";


export function SearchSavedFilters({ name, values, onSelect }) {
    const [filters, setFilters] = usePersistentState(name + ".savedFilters", []);
    const [label, setLabel] = useState("");

    const saveFilter = (name) => {

        // Check if already exists and update
        for (const filter of filters) {
            if (filter.label === label) {
                filter.values = values;
                setFilters([...filters]);
                return;
            }
        }

        setFilters([...filters, {
            id: +Date.now(),
            label: label,
            values
        }]);
        setLabel("");

    }

    // Disable options for now
    const options = useMemo(() => {
        return [] // filters.map((filter) => ({ value: filter.label }));
    }, [filters]);


    return <>
        <Flex style={{
            padding: 5,
        }}>
            {(filters || []).map((filter) => {
                return <Tag
                    style={{ cursor: 'pointer', marginRight: 3 }}
                    key={filter.id}
                    color="cyan"
                    closable
                    onClick={() => onSelect(filter.values)}
                    onClose={() => {
                        setFilters(filters.filter((f) => f !== filter));
                    }}
                >
                    {filter.label}
                </Tag>
            })}
            {
                values && values.length > 0 ? <Space.Compact>
                    <AutoComplete size="small" value={label} onChange={(v) => setLabel(v)}
                        onKeyUp={(e) => e.stopPropagation()}
                        onKeyDown={(e) => e.stopPropagation()}
                        options={options}
                        // prefix={<Icon path={mdiPlus} size={0.6} color={"grey"} style={{ marginRight: 3 }} />}
                        placeholder="Sauvegarder..."
                        style={{
                            minWidth: 150,
                        }}
                    />
                    <Button type="primary" size="small" onClick={() => saveFilter()} disabled={!values || !values.length || !label}
                        icon={<Icon path={mdiCheck} size={0.6} />}></Button>
                </Space.Compact>
                    : null
            }

        </Flex >
    </>
}