import { Rate, Space, Table } from "antd";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Link from "../../components/link/Link";
import TaskStatusLabel from "../../components/tasks/TaskStatusLabel";
import TaskTypeLabel from "../../components/tasks/TaskTypeLabel";
import { useAgencies } from "../../hooks/agencies";
import { useAuth } from "../../hooks/auth";
import { usePrestations } from "../../hooks/prestations";
import { useProjectsLoader } from "../../hooks/projects";
import { taskStatuses, taskTypes, useTasks } from "../../hooks/tasks";
import { indexBy } from "../../lib/utils";
import { removeTask } from "../../services/api/tasks";
import { loaderWrap } from "../../services/loader";


export default function ProjectTasks() {
  const { projectId } = useParams();
  const [tasks, refreshTasks] = useTasks({ project_id: projectId });
  const { getProject } = useProjectsLoader();
  const [agencies, refreshAgencies] = useAgencies();
  const [prestations, refreshPrestations] = usePrestations();

  const { can } = useAuth();

  const [selectedTasks, setSelectedTasks] = useState([]);

  const displayedTasks = useMemo(() => {
    return tasks.map(t => {
      const project = getProject(t.project_id);
      return {
        ...t,
        project_name: project ? project.name : 'Inconnu',
        agency_id: project ? project.agency_id : null
      }
    })
  }, [tasks, getProject])

  const filteredList = useMemo(() => {
    return displayedTasks;

  }, [displayedTasks])



  const treeList = useMemo(() => {
    const list = filteredList.map(item => ({ ...item }));
    const idx = indexBy(list, "id")
    const agencyIdx = indexBy(agencies, "id")
    const out = []
    list.forEach((item) => {
      const agency = agencyIdx[item.agency_id];
      item.agency_name = agency?.name;
      if (item.parent_task_id) {
        const parent = idx[item.parent_task_id]
        if (parent) {
          if (!parent.children) {
            parent.children = []
          }
          parent.children.push(item)
          return
        }
      }
      out.push(item)
    });
    return out;
  }, [filteredList]);

  const navigate = useNavigate();
  const open = (id) => {
    navigate(`/tasks/${id}`);
  };


  const onDeleteSelected = async () => {
    for (const id of selectedTasks) {
      await loaderWrap(removeTask(id));
    }
    setSelectedTasks([]);
    refreshTasks();
  }


  return <>
    {/* <div className="box">
      <Flex gap="small" align="flex-end" >

        {can('task.create') && <Button type="primary" onClick={() => open("*")} >Créer une tâche</Button>}
        {can('task.delete') && selectedTasks.length > 0 ?

          <Popconfirm
            title={selectedTasks.length > 1
              ? `Supprimer les ${selectedTasks.length} tâches sélectionnées ?`
              : "Supprimer la tâche sélectionnée ?"}
            description="Cette action ne peut pas être annulée."
            onConfirm={() => onDeleteSelected()}

            okText="Oui"
            cancelText="Non"
          >
            <Button type="primary" danger onClick={() => { }} >Supprimer</Button>
          </Popconfirm> : null}
      </Flex>
    </div > */}
    <div className="box-paddingless">

      <Table
        dataSource={treeList}
        expandable={{ defaultExpandAllRows: true }}
        rowKey={(record) => record.id}
        pagination={
          {
            position: ['bottomCenter'],
          }
        }
        // rowSelection={{
        //   type: 'checkbox',
        //   getCheckboxProps: (record) => ({
        //     disabled: !!record.ref,
        //   }),
        //   onChange: (selectedRowKeys) => {
        //     setSelectedTasks(selectedRowKeys)
        //     // console.log(`selectedRowKeys: ${selectedRowKeys}`);
        //   }
        // }}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: () => open(record.id), // click row
        //   };
        // }}
        columns={[
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => String(a.name).localeCompare(b.name),
            render: (text, record) => {
              return <Space direction='vertical'>
                <a onClick={() => open(record.id)}>{record.name}</a>
                <div style={{ fontStyle: 'italic' }}>{record.description}</div>
              </Space>
            }
          },
          {
            title: 'Commande',
            dataIndex: 'order_number',
            key: 'order_number',
            sorter: (a, b) => String(a.order_number).localeCompare(b.order_number),
            render: (text, record) => {
              return text && <Link to={`/orders/order-${record.order_number}`}>{text} / {record.order_item_number}</Link>
            }
          },
          // {
          //   title: 'Agence',
          //   dataIndex: 'agency_name',
          //   key: 'agency_name',
          //   sorter: (a, b) => String(a.agency_name).localeCompare(b.agency_name),
          // },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
              let progress = 0;
              if (record.status === 'inplanning') {
                progress = Math.round(record.planned_percent)
              } else if (record.status === 'planned') {
                progress = Math.round(record.complete_percent)
              }
              return <Space direction='vertical'>
                <TaskStatusLabel value={record.status} deleted={record.deleted} progress={progress != null && record.status === 'inplanning'
                  ? progress : undefined} />
              </Space>
            },
            filters: taskStatuses.map(({ label, value }) => ({ text: label, value })),
            // defaultFilteredValue: taskStatuses.map(({ value }) => value).filter(value => value !== 'complete'),
            onFilter: (value, record) => record.status === value,
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => <TaskTypeLabel value={record.type} />,
            filters: taskTypes.map(({ label, value }) => ({ text: label, value })),
            onFilter: (value, record) => record.type === value,
          },
          {
            title: 'Compétences',
            dataIndex: 'expected_level',
            key: 'expected_level',
            sorter: (a, b) => a.expected_level - b.expected_level,
            render: (text, record) => {
              return <Rate value={Math.ceil(record.expected_level * 3 / 5)} count={3} disabled />
            },
          }
        ]}
      />

    </div>
  </>
}