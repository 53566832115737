
import { Result, Skeleton } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useResource } from '../../hooks/resources';


function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}


export default function ResourceItem() {
  const { resourceId } = useParams();
  const { resource, complete } = useResource(resourceId);
  if (!complete) return <Skeleton active style={{ padding: "1rem" }} />
  if (!resource) return <Result
    status="404"
    title="Ressource non trouvée"
    subTitle="Cette ressource n'existe pas dans Chronos"
  />
  return (<>
    {resourceId && resourceId !== '*' ? (
      <ul className="nav nav-pills subnav mb-4">
        <li className="nav-item">
          <NavLink to="details" className={navLinkActive("nav-link", "active")}>Détail</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="prestations" className={navLinkActive("nav-link", "active")}>Prestations</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="planning" className={navLinkActive("nav-link", "active")}>Planning</NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink to="notifications" className={navLinkActive("nav-link", "active")}>Notifications</NavLink>
        </li> */}
      </ul>) : null}
    <Outlet />
  </>);
}
