import Icon from "@mdi/react";
import { Tag } from "antd";
import { asIconAndColor } from "./util";

import Styles from './Search.module.scss';

export function SearchTagRenderer({ label, inverted, params, closable, onClose, onInvert }) {
    const { icon, color, tagColor } = asIconAndColor(params);

    return <Tag
        color={tagColor || color}
        // onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        onClick={onInvert}
        className={Styles.tag}
        style={{ textDecoration: inverted ? 'line-through' : 'none' }}
        icon={icon ? <Icon path={icon} size={0.6} style={{ marginRight: 3, marginTop: -2 }} /> : null}
    >
        {params?.title ? <b className={Styles.optionTitle}>{params.title}</b> : null}
        {label}
    </Tag>
}
