import { Badge } from "antd"
import { useMemo } from "react"
import { taskStatuses } from "../../hooks/tasks"



export default function TaskStatusBadge({ value, deleted, progress, children, ...props }) {
  if (deleted) {
    value = 'deleted'
  }
  const { label, color } = useMemo(() => {
    return taskStatuses.find(s => s.value === value) || { label: value }
  }, [value])


  if (color === 'default') {
    return <Badge status="default" title={label} {...props}>{children}</Badge>
  }

  return <Badge color={color || 'default'} title={label} {...props} >{children}</Badge>

}
