import { SearchOutlined } from '@ant-design/icons';
import { Button, Flex, Input, Table } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearcher } from '../../hooks/search';
import { useWarehouses } from '../../hooks/warehouses';

export default function WarehousesList() {
  const [warehouses] = useWarehouses();
  const { list, search, setSearch } = useSearcher(
    warehouses,
    useCallback((o, search) => `${o.code} ${o.name}`.toLowerCase().includes(search.toLowerCase()), []),
  );
  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };

  return (<>
    <div className="box">
      <Flex gap="small" align='center' >
        <Input size="large" placeholder="Rechercher un entrepôt..."
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => open("*")}>Créer un entrepôt</Button>
      </Flex>
    </div>
    <div className="box-paddingless">
      <Table
        // bordered
        dataSource={list}
        rowKey={(record) => record.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => open(record.id), // click row
          };
        }}
        columns={[
          {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 100,
            sorter: (a, b) => String(a.code).localeCompare(b.code),
          },
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => String(a.name).localeCompare(b.name),
          },
        ]}
      />

    </div>
  </>);
}
