import { Badge, Flex, Segmented } from "antd";

import { indexMultipleBy } from "../../lib/utils";
import Styles from "./PlanningProjectsOverview.module.scss";
import SlotProjectItemRenderer from "./PlanningSlotProjectItemRenderer";
import SlotTaskItemRenderer from "./PlanningSlotTaskItemRenderer";

import VirtualList from 'rc-virtual-list';
import { useMemo, useRef, useState } from "react";
import { useElementDimensions } from "../../hooks/dom";

export default function PlanningProjectsOverview({
  datastore,
  filteredDatastore,
  metrics,
  onSelectDate,
  onClickNewSlot,
  addSearchFilter,
}) {
  const [displayMode, setDisplayMode] = useState('toplan');
  const { projects, tasks } = filteredDatastore;
  const tasksByProject = indexMultipleBy(tasks, "project_id");

  const ref = useRef(null);
  const { dimensions } = useElementDimensions(ref);

  const items = useMemo(() => {
    const out = []
    projects?.filter(project => tasksByProject[project.id])
      .forEach((project) => {

        const tasks = []
        tasksByProject[project.id].forEach((task) => {
          if (displayMode === 'todo' && (task.status === 'complete' || task.status === 'canceled')) {
            return
          }
          if (displayMode === 'toplan' && (task.status !== 'unplanned' && task.status !== 'inplanning')) {
            return
          }
          tasks.push({
            type: "task",
            id: task.id,
            item: task
          })
        })

        if (tasks.length > 0) {
          out.push({
            type: "project",
            id: project.id,
            item: project
          })
          out.push(...tasks);
        }

      })
    return out;
  }, [projects, tasksByProject, displayMode]);

  const tasksCount = useMemo(() => {
    return items.reduce((acc, { type }) => {
      return type === "task" ? acc + 1 : acc
    }, 0)
  }, [items])


  if (!projects || !tasks) return null;
  return (
    <Flex vertical gap="small" style={{ height: "100%", paddingTop: "1em", flex: 1 }}>
      <Flex justify="space-between" align="center">

        <Segmented value={displayMode} onChange={setDisplayMode}
          options={[
            { label: "À planifier", value: 'toplan' },
            { label: "À venir", value: 'todo' },
            { label: "Tous", value: 'all' },
          ]} />
        <Badge count={tasksCount}  >
        </Badge>
      </Flex>
      <div ref={ref} style={{ flex: 1, overflow: "hidden" }}>
        <VirtualList
          data={items}
          itemHeight={30}
          height={(dimensions?.height - 100) || 100}
          itemKey={"id"}
        >
          {({ type, item }) => {
            switch (type) {
              case "project":
                return <div key={item.id} className={Styles.project}>
                  <div className={Styles.projectName}>
                    <SlotProjectItemRenderer name={item.name}
                      project={item}
                      metrics={metrics[item.id]}
                    // onSelectDate={onSelectDate}
                    // remainingIndicator={remainingIndicator}
                    />
                  </div>
                </div>
              case "task":
                return <div key={item.id} className={Styles.task}>
                  <SlotTaskItemRenderer name={item.name}
                    task={item}
                    onClickNewSlot={onClickNewSlot}
                    onSelectDate={onSelectDate}
                    metrics={metrics[item.id]}
                    remainingIndicator="duration"
                  />
                </div>
              default:
                return null;
            }
          }}
        </VirtualList>
      </div>
      {/* <Search projects={datastore.projects} agencies={datastore.agencies} prestations={datastore.prestations} /> */}

      {/* <div className={Styles.list}>
        {projects.filter(project => tasksByProject[project.id]).map((project) => {
          return (
            <div key={project.id} className={Styles.project}>
              <div className={Styles.projectName}>
                <SlotProjectItemRenderer name={project.name}
                  project={project}
                  metrics={metrics[project.id]}
                  addSearchFilter={addSearchFilter}
                // onSelectDate={onSelectDate}
                // remainingIndicator={remainingIndicator}
                />
              </div>


              <div className={Styles.tasks}>
                {tasksByProject[project.id].map((task) => {
                  return (

                    <div key={task.id} className={Styles.task}>
                      <SlotTaskItemRenderer name={task.name}
                        task={task}
                        // prestation={prestationIdx[res?.prestation_id]}
                        onSelectDate={onSelectDate}
                        metrics={metrics[task.id]}
                        remainingIndicator="duration"
                        addSearchFilter={addSearchFilter}
                      />

                    </div>
                  );

                })}
              </div>
            </div>
          )
        })}
      </div> */}

    </Flex >
  );
}