
import { useParams } from "react-router-dom";
import ResourceSubscriptionsForm from "../../components/resources/ResourceSubscriptionsForm";







export default function ResourceNotifications() {
  const { resourceId } = useParams();
  return <ResourceSubscriptionsForm resourceId={resourceId} />;
}