

import { initials } from '@dicebear/collection';
import { createAvatar } from '@dicebear/core';
import { useMemo } from 'react';

export default function Avatar({ username, seed, size }) {
  const avatar = useMemo(() => createAvatar(initials, {
    seed: seed || username,


    // // backgroundType: ['gradientLinear'],
    // // backgroundColor: ['b6e3f4', 'c0aede', 'd1d4f9', 'ffd5dc', 'ffdfbf'],
    // backgroundColor: ['FFB5C2', '9FBBCC', 'FFF689', 'CFFFB0', 'ffdfbf', 'ffd5dc', 'f4d58d', '95b8d1'],
    // size: size || 48,
    // radius: 20,
    // // eyes: ['closed', 'closed2', 'cute', 'pissed', 'plain', 'sleepClose', 'wink'],
    // // mouth: ['cute', 'lilSmile', 'plain', 'smileLol', 'smileTeeth', 'wideSmile'],

    // eyebrows: ['default', 'defaultNatural', 'flatNatural', 'upDown', 'upDownNatural'],
    // eyes: ['closed', 'default', 'eyeRoll', 'happy', 'side', 'squint', 'surprised'],
    // mouth: ['default', 'serious', 'twinkle'],


    radius: 50,
    size: size || 48,
    backgroundType: ['gradientLinear'],
    backgroundColor: ['FFB5C2', '9FBBCC', 'FFF689', 'CFFFB0', 'ffdfbf', 'ffd5dc', 'f4d58d', '95b8d1'],

    // ... other options
  }).toDataUriSync(), [seed, username, size]);
  return <img src={avatar} alt={username} />
}