import { Badge, Button, Divider, Flex, Popover, Progress, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { formatDate } from "../../lib/format";
import ProjectMessagesView from "../projects/ProjectMessagesView";

import { useMemo } from "react";
import { taskStatusesIdx } from "../../hooks/tasks";
import { projectPalette } from '../../lib/palette';
import ProjectCode from "../projects/ProjectCode";
import ProjectShortCode from "../projects/ProjectShortCode";



function DateButton({ date, color, onSelectDate }) {
  return <Link type="text"
    onClick={() => onSelectDate(date)} >
    <b style={{ color }}>{formatDate(date)}</b>
  </Link>
}


function TasksSummary({ metrics }) {
  const { tasks_count, tasks_inplanning_count, tasks_planned_count, tasks_complete_count } = metrics || {};

  const { progressColors, percent, totalTasks } = useMemo(() => {
    let colors = [];
    if (tasks_complete_count) {
      colors = colors.concat(new Array(tasks_complete_count).fill(taskStatusesIdx['complete'].color))
    }

    if (tasks_planned_count) {
      colors = colors.concat(new Array(tasks_planned_count).fill(taskStatusesIdx['planned'].color))
    }

    if (tasks_inplanning_count) {
      colors = colors.concat(new Array(tasks_inplanning_count).fill(taskStatusesIdx['inplanning'].color))
    }

    const totalTasks = tasks_inplanning_count + tasks_planned_count + tasks_complete_count;

    return {
      totalTasks,
      progressColors: colors,
      percent: tasks_count ? Math.round((totalTasks / tasks_count) * 100) : 0
    }
  }, [tasks_count, tasks_inplanning_count, tasks_planned_count, tasks_complete_count]);

  if (tasks_count > 10) {
    // console.log({ tasks_count, tasks_inplanning_count, tasks_planned_count, tasks_complete_count, totalTasks })
    return <Space size={3}>
      <Badge color={taskStatusesIdx['complete'].color} showZero count={tasks_complete_count} />
      <Badge color={taskStatusesIdx['planned'].color} showZero count={tasks_planned_count} />
      <Badge color={taskStatusesIdx['inplanning'].color} showZero count={tasks_inplanning_count} />
      <Badge color="lightgray" showZero count={tasks_count - totalTasks} />
    </Space>

  }
  return <Progress percent={percent}
    showInfo={false}
    size={[4, 10]}
    strokeWidth={25}
    steps={tasks_count}
    format={null}
    // trailColor="lightgray"
    strokeColor={progressColors} />
}


export default function SlotProjectItemRenderer({ name, project, onSelectDate, metrics, addSearchFilter, toggleExpandFunc }) {
  const navigate = useNavigate();

  const { can } = useAuth();

  // const { tasks_count, tasks_inplanning_count, tasks_planned_count, tasks_complete_count } = metrics || {};

  // const { progressColors, percent } = useMemo(() => {
  //   let colors = [];
  //   if (tasks_complete_count) {
  //     colors = colors.concat(new Array(tasks_complete_count).fill(taskStatusesIdx['complete'].color))
  //   }

  //   if (tasks_planned_count) {
  //     colors = colors.concat(new Array(tasks_planned_count).fill(taskStatusesIdx['planned'].color))
  //   }

  //   if (tasks_inplanning_count) {
  //     colors = colors.concat(new Array(tasks_inplanning_count).fill(taskStatusesIdx['inplanning'].color))
  //   }

  //   const totalTasks = tasks_inplanning_count + tasks_planned_count + tasks_complete_count;

  //   return {
  //     progressColors: colors,
  //     percent: tasks_count ? Math.round((totalTasks / tasks_count) * 100) : 0
  //   }
  // }, [tasks_count, tasks_inplanning_count, tasks_planned_count, tasks_complete_count]);


  return (project ?
    <div className="slot-cell" >
      <Popover placement="right"
        title={<div style={{ maxWidth: 400 }}>{project?.name}</div>}
        content={<div style={{ maxWidth: 400, maxHeight: 400, overflow: 'auto' }}>

          <div>Code: <ProjectCode value={project.code} /></div>
          {project.expected_start_date || project.expected_end_date ?
            <div>
              Dates :
              <DateButton date={project.expected_start_date} color="green" onSelectDate={onSelectDate} />
              {+new Date(project.expected_end_date) > 0 && <> →
                <DateButton date={project.expected_end_date} color="red" onSelectDate={onSelectDate} />
              </>
              }
            </div>
            : null}
          <div style={{ marginTop: 10 }}>
            <ProjectMessagesView projectId={project.id} />
          </div>
          {can('project.show') && can('project.view') &&
            <>
              <Divider />
              <Flex justify="end" gap="small">
                {addSearchFilter && <Button size="small" type="default" onClick={() => addSearchFilter("projects", project.id)}>Filtrer sur cette affaire</Button>}

                <Button size="small" type="primary" onClick={() => navigate(`/projects/${project.id}`)}>Voir l'affaire</Button>
              </Flex>
            </>}
        </div>}
      >
        <Flex gap="small" align="center" justify="space-between" wrap="nowrap"
          style={{ cursor: 'pointer', color: projectPalette.color, minWidth: 0, flex: 1 }}
          onClick={toggleExpandFunc}>
          {/* <div>
            <Icon path={mdiSelectGroup} size={0.8} />
          </div> */}
          <div className="slot-text">
            <ProjectShortCode value={project?.code} />
          </div>
          <div className="slot-text-truncated">
            {name}
          </div>
          <div >
            {/* 
            <Progress percent={percent}
              showInfo={false}
              size={[tasks_count > 10 ? 1 : 3, 10]}
              strokeWidth={20}
              steps={tasks_count}
              format={null}
              // trailColor="lightgray"
              strokeColor={progressColors} /> */}
            <TasksSummary metrics={metrics} />
          </div>
        </Flex>

      </Popover>

    </div > : <></>
  );
}