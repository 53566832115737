import { FileExcelOutlined, FileImageOutlined, FileOutlined, FilePdfOutlined, FilePptOutlined, FileTextOutlined, FileWordOutlined, FileZipOutlined } from "@ant-design/icons";
import { Flex, Tooltip } from "antd";
import { formatDateTime } from "../../lib/format";

const mimesToIcons = {
  "image/jpeg": FileImageOutlined,
  "image/png": FileImageOutlined,
  "application/pdf": FilePdfOutlined,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": FileExcelOutlined,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": FileWordOutlined,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": FilePptOutlined,
  "application/zip": FileZipOutlined,
  "text/plain": FileTextOutlined,
  "application/vnd.ms-excel": FileExcelOutlined,
  "application/msword": FileWordOutlined,
  "application/vnd.ms-powerpoint": FilePptOutlined,
  "application/vnd.ms-outlook": FilePdfOutlined,
  "application/vnd.ms-access": FilePdfOutlined,
  "application/vnd.ms-project": FilePdfOutlined,
  "application/vnd.ms-publisher": FilePdfOutlined,
  "application/vnd.ms-xpsdocument": FilePdfOutlined,
  "application/vnd.oasis.opendocument.text": FileWordOutlined,
  "application/vnd.oasis.opendocument.spreadsheet": FileExcelOutlined,
  "application/vnd.oasis.opendocument.presentation": FilePptOutlined,
}

function DocumentIcon({ mime }) {
  const Icon = mimesToIcons[mime] || FileOutlined
  return <Icon style={{ fontSize: "2em", opacity: 0.7 }} />;
}

export default function Document({ doc, preview, }) {
  const canPreview = preview && doc.contentType.startsWith("image/")
  console.log(doc)
  const open = () => {
    window.open(doc.url, '_blank')
  }
  return <Flex gap={"small"} align="center" onClick={open} style={{ cursor: "pointer" }}>
    <Tooltip title={<Flex vertical>
      <a href={doc.url} target="_blank" rel="noreferrer">{doc.name}</a>
      <em>{formatDateTime(doc.date)}</em>
    </Flex>}>
      {canPreview
        ? <img src={doc.url} alt={doc.name} style={{
          maxHeight: 80,
          maxWidth: 80,
          boxShadow: "1px 1px 2px rgba(0,0,0,0.1)",
          borderRadius: 5,
        }} />
        : <DocumentIcon mime={doc.contentType} />}
    </Tooltip>

  </Flex>

}
