import { Form } from "antd";
import { ResourceForPrestationInput } from "../../components/resources/ResourceForPrestationInput";
import { usePrestations } from "../../hooks/prestations";



export default function ShipmentSetAuditorForm({ form, warehouseId, required }) {
    const [prestations] = usePrestations();
    const warehouseCode = warehouseId?.split('-')[1]
    const prestationId = prestations.find(p => p.code === 'CMDVERIF' + warehouseCode)?.id;

    return <Form form={form} layout="vertical">
        <Form.Item label="Vérificateur" name="auditor_id"
        // rules={[{ required: required, message: 'Veuillez sélectionner un vérificateur' }]}
        >
            <ResourceForPrestationInput prestationId={prestationId} />
        </Form.Item>
    </Form>
}