import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/resources');
const { invalidateCache, withCache } = cache();

function mapFromResourceModel(input) {
  return { ...input };;
}

function mapToResourceModel(input) {
  return { ...input };;
}


export async function listResources() {
  const items = await withCache(async () => {
    const { items } = await api.get();
    return items;
  }, '')
  return items ? items.map(mapToResourceModel) : [];
}

export async function getResource(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Resource not found');
    }
    return item;
  }, id)

  return mapToResourceModel(item);;
}

export async function createResource(resource) {
  const { item } = await api.post('', { item: mapFromResourceModel(resource) });
  if (item) {
    invalidateCache(item.id)
    return mapToResourceModel(item)
  }
  return undefined;
}

export async function updateResource(resource) {
  const res = await api.put('', { item: mapFromResourceModel(resource) });
  invalidateCache(resource.id)
  return res ? resource : undefined;
}

export async function removeResource(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}


export async function listResourceComments(resId) {
  const { items } = await api.get(`${resId}/comments`);
  return items;
}

export async function addResourceComment(resId, comment) {
  const { success } = await api.post(`${resId}/comments`, { content: comment });
  return success;
}


export function invalidateResourceCache(resId) {
  invalidateCache(resId)
}
